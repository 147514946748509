import { QuestionCircleTwoTone, UploadOutlined } from '@ant-design/icons';
import { Button, Image, Input, Space, Spin, Tabs, Tooltip, Typography, Upload } from "antd";
import { useState } from 'react';

// import { cacheBuster } from '../../utils/Utils';

import conditional from '../../utils/conditional';

export default function ListImageUploader(props){

    const {
        uploadFile = () => {},
        removeFile = () => {},      
        has_image = false,
        actual_id = "",
        image_cache_key = "",
        create = false,
        showImage = false,
        setShowImage = () => {},
        imageFile = '',
        setImageFile = () => {},
        setChangeImage = () => {},
    } = props;

    const [urlText, setURLText] = useState(false);    
    const [uploading, setUploading] = useState(false);
    const [imageErrorText, setImageErrorText] = useState(false);

    const prepUploadFile = (file) => {
        setUploading(true);
        setChangeImage(true)
        setImageFile({ ...imageFile, "file": file.file })
        setShowImage(true);
        setUploading(false);
    }

    const prepRemoveFile = () => {
        setImageFile({ ...imageFile, "file": false })
        if(!create){
            removeFile("cover_image");
        }
        removeFile("cover_image");
        setShowImage(false);
    }

    const prepURLUpload = (url_text) => {
        if(url_text.includes(".png") || url_text.includes(".jpeg") || url_text.includes(".jpg")){
            setChangeImage(true);
            setImageErrorText(false);
        }else{
            setImageErrorText("Your URL does not contain a valid image. Please revise your URL and try again.");
            setShowImage(false)
        }
    }

    function changeURLText(url_text){
        if(url_text === ""){
            setImageErrorText(false);
            setImageFile({ ...imageFile, "url": false })
        }else{
            setImageFile({ ...imageFile, "url": url_text })
            prepURLUpload(url_text);
        }
    }

    const determineImageSrc = () => {
        if(urlText !== false){
            return imageFile.url;
        }
        if(image_cache_key !== "" && !uploading){
            return "https://bookmanager.com/tl_img?def_id=" + actual_id + "&cache_key=" + image_cache_key
        }
    }


    const setStartingImageFile = () => {
        if(has_image){
            return [{
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: "https://bookmanager.com/tl_img?def_id=" + actual_id + "&cache_key=" + image_cache_key,
            }]
        }
    }

    const fileUploadButton = () => {
        return (<>
            <div >
                {(uploading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
            </div>
            <p className="ant-upload-text">
                Click or drag to upload Image <br />(.png, .jpeg, or .jpg).
            </p>
            <p className="ant-upload-hint">
                Upload an Image to be associated with this Catalogue.
            </p>
        </>)
    }
    // potential future change to the layout: 
    // https://stackoverflow.com/questions/58469157/antd-how-to-display-fileuploadlist-to-the-right-of-dragger-upload
    
    return (
        <Tabs className="itemTabs" size='small' style={{"width": "90%"}} type='card'>
            <Tabs.TabPane tab="Upload an Image Directly" key={1} style={{"height": showImage ? "450px" : "210px"}}>
                <div >
                    {/* <conditional.true value={showImage === false}> */}
                        <Typography.Paragraph>
                            Upload Image file, or from a URL <br/> (Image will be resized to 200px wide)
                        </Typography.Paragraph>
                        <div>
                            <Upload.Dragger 
                                // accept="image/*" 
                                accept="image/png, image/jpeg, image/jpg" 
                                listType='picture-card'                        
                                maxCount={1}
                                defaultFileList={setStartingImageFile()}
                                beforeUpload={() => false}
                                onChange={(info) => {
                                    if(info.file.status === "removed"){
                                        prepRemoveFile();
                                    } else if(info.file.status !== "uploading") {
                                        let reader = new FileReader();
                                        if (info && info.file.type.match('image.*')) {
                                            reader.readAsDataURL(info.file);
                                        }
                                        prepUploadFile(info);
                                    }
                                }}
                                showUploadList={{
                                    showPreviewIcon:false
                                }}
                                style={{ "height": "100px" }}>
                                {fileUploadButton()}
                            </Upload.Dragger>
                        </div>
                        <br />
                        <conditional.true value={imageErrorText !== false}>
                            <div className='c' style={{"padding": "5px"}}>
                                {imageErrorText}
                            </div>
                        </conditional.true>
                    {/* </conditional.true> */}
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane  tab="Upload Via URL" key={2}>
                <conditional.true value={showImage === false}>
                    {/* <Space style={{"paddingTop": "10px"}}> */}
                        <Input onChange={(e) => changeURLText(e.target.value)}  placeholder="Paste image URL here"/>
                        {/* <Button disabled={imageFile.url === false ? true : false} onClick={() => prepURLUpload()}>Validate URL</Button>
                        <Tooltip title="The URL needs to include either .png, .jpeg, or .jpg, for the upload to work. For example: https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Bulldog_inglese.jpg/1200px-Bulldog_inglese.jpg">
                            <QuestionCircleTwoTone style={{"paddingLeft": "5px"}}/>
                        </Tooltip> */}
                    {/* </Space> */}
                </conditional.true>
                <conditional.true value={showImage && !uploading && !create}>
                    <Space >
                        <Image 
                            preview={false} 
                            style={{"cursor": "pointer", "marginRight": "10px", "marginTop": "7px"}}
                            src={determineImageSrc()}
                        />
                        <Button style={{"marginRight": "5px"}} type='danger' onClick={() => prepRemoveFile()}>Remove Image</Button>
                    </Space>
                </conditional.true> 
                <br />
                <conditional.true value={imageErrorText !== false}>
                    <div className='c' style={{"padding": "5px"}}>
                        {imageErrorText}
                    </div>
                </conditional.true>
            </Tabs.TabPane>
        </Tabs>
    )
}   