import { Affix, Alert, Button, Divider, Form, Layout, message, Modal, Spin, Tabs, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import VendorETA from "../../components/VendorETA";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import AccountsSection from "../../layouts/Webstore/sections/AccountsSection";
import AdvancedSecion from "../../layouts/Webstore/sections/AdvancedSection";
import GeneralSection from "../../layouts/Webstore/sections/GeneralSection";
import HoursSection from "../../layouts/Webstore/sections/HoursSection";
import MultistoreSection from "../../layouts/Webstore/sections/MultiStoreSection";
import OrderingSection from "../../layouts/Webstore/sections/OrderingSection";
import PaymentsSection from "../../layouts/Webstore/sections/PaymentsSection";
import PricingSection from "../../layouts/Webstore/sections/PricingSection";
import SearchingSection from "../../layouts/Webstore/sections/SearchingSection";
import SeoSection from "../../layouts/Webstore/sections/SeoSection";
import ShippingSection from "../../layouts/Webstore/sections/ShippingSection";
import TitlesSection from "../../layouts/Webstore/sections/TitlesSection";
import WebstoreSearch from "../../layouts/Webstore/WebstoreSearch";
import { apiCall } from "../../utils/Api";

export default function WebstoreSettingsPage() {

      
    const { section = "general" } = useParams();
    const history = useHistory(); 
    const [loading, setLoading] = useState(true);
    const [ws, setWs] = useState(false);
    const [form] = useForm();
    const [regions, setRegions] = useState([]);
    const [autoHeldSale, setAutoHeldSale] = useState(false);
    const [update, setUpdate] = useState(false);
    const [current, setCurrent] = useState(section + "_section");
    const [suppliers, setSuppliers] = useState([]);
    const [changed, setChanged] = useState(false);

    const getSuppliers = (_e) => {

        if (_e) {
            setChanged(false);
            setCurrent(_e);
        }

        apiCall("supplier/getEstDelivery", {}, (_status, _result) => {
            if (_status) {
                setSuppliers(_result)
            }
        });
    }
    useEffect(getSuppliers, []);

    const parsePath = (_str = "") =>{

        _str = _str.replace("_section", "");
        return _str.toLowerCase();
    }

    const reposition = () => {
        window.scrollTo(0, 0);

        history.push("/settings/web-store-settings/" + parsePath(current));
    }

    useEffect(reposition, [current]);

    const fetchSettings = (_e) => {

        if (_e) {
            form.resetFields();
            setChanged(false);
            setCurrent(_e);
            return;
        }

        apiCall("settings/get", {}, (_status, _results) => {
            if (_status) {
                setWs(_results);
                setAutoHeldSale((_results["spool_order_files"]) ? true : false);
                setRegions(_results["shipping_regions"]);
                setChanged(false)
            }
            setLoading(false);
        })
    }

    useEffect(fetchSettings, []);

    const onUpdateSettings = (_status, _results) => {
        if (_status) {
            setWs(_results);
            message.success("Settings saved successfully.")
            setChanged(false);
        } else {
            message.error(_results.error)
        }
        setLoading(false);
    }


    const convertHours = (_hours) => {

        let _obj = {}
        Object.keys(_hours).map(key => {
            // store
            _obj[key] = {}

            if(_hours[key].hasOwnProperty("title")){
                _obj[key].title = _hours[key].title;
            }

            if(_hours[key].hasOwnProperty("hours")){
                _obj[key].hours = Object.keys(_hours[key].hours).map(itm_key => {
                    return _hours[key].hours[itm_key];
                })
            }

            if(_hours[key].hasOwnProperty("exceptions")){
                _obj[key].exceptions = Object.keys(_hours[key].exceptions).map(itm_key => {
                    return _hours[key].exceptions[itm_key];
                })
            }
        })
        return _obj

    }
    const updateSettings = (_f) => {


        if(_f.hasOwnProperty("ws_online_discount")){
        //_f.ws_hours = convertHours(_f.ws_hours); 
           _f.ws_online_discount = parseInt(_f.ws_online_discount) || 0;
        }

        // don't send if not touched
        if(!form.isFieldTouched("ldc_local_delivery_code")){
            delete _f["ldc_local_delivery_code"];
        } 
        

        setLoading(true);
        apiCall("settings/set", { settings: JSON.stringify(_f) }, onUpdateSettings)
    }

    const changePrompt = (_e) => {
        Modal.confirm({
            title: "Save changes?", onOk: () => {
                save(_e);
            }, onCancel: () => {
                if (current === "vendor_eta_section") {
                    getSuppliers(_e);
                } else {
                    fetchSettings(_e);
                }
            }, content: <>You've made changes, would you like to save changes or revert?</>, cancelText: "Revert", okText: "Save"
        })
    }

    const drawElement = () => {

        if (!ws) {
            return (<></>);
        }
        
       
       

        switch (current) {
            case "general_section": return <GeneralSection ws={ws} form={form} />
            case "accounts_section": return <AccountsSection ws={ws} form={form} />
            case "ordering_section": return <OrderingSection setChanged={setChanged} autoHeldSale={autoHeldSale} setAutoHeldSale={setAutoHeldSale} ws={ws} form={form} />
            case "shipping_section": return <ShippingSection setChanged={setChanged} setUpdate={setUpdate} update={update} setRegions={setRegions} regions={regions} ws={ws} form={form} />
            case "searching_section": return <SearchingSection ws={ws} form={form} />
            case "pricing_section": return <PricingSection ws={ws} form={form} />
            case "payments_section": return <PaymentsSection autoHeldSale={autoHeldSale} setAutoHeldSale={setAutoHeldSale} ws={ws} form={form} />
            case "hours_section": return <HoursSection setChanged={setChanged} ws={ws} form={form} />
            case "multistore_section": return <MultistoreSection ws={ws} form={form} />
            case "seo_section": return <SeoSection ws={ws} form={form} />
            case "advanced_section": return <AdvancedSecion ws={ws} form={form} />
            case "titles_section": return <TitlesSection setChanged={setChanged} ws={ws} form={form} />
            case "vendor_eta_section": return <VendorETA suppliers={suppliers} setSuppliers={setSuppliers} changed={changed} setChanged={setChanged} />
            default: return <GeneralSection ws={ws} form={form} />
        }

      

    }

    const save = (_e) => {
        if (current === "vendor_eta_section") {
            apiCall("supplier/setEstDelivery", {
                suppliers: JSON.stringify(suppliers.map(item => {
                    return { acct: item.acct, estimate_delivery: item.estimate_delivery }
                }))
            }, (_status, _result) => {
                if (_status) {
                    setSuppliers(_result);
                    if (_e) {
                        setCurrent(_e);
                        setChanged(false);
                    }
                    setChanged(false);
                    message.success("Vendor ETAs updated successfully.")
                } else {
                    message.error(_result.error)
                }
            })
        } else {
            form.submit();
            if (_e) {
                setCurrent(_e);
            }
        }
    }

    const gotoSection = (_e) => {

        if (changed) {
            changePrompt(_e);
        } else {
            setCurrent(_e)
        }

    }

    const drawProblems = () => {


        if(ws.hasOwnProperty("problems")){
           
            if(!ws.problems.length>0){
                return (<></>);
            }
            return(<Alert type="error" description={ws.problems.map(item => {
                return(<div><small>{item}</small></div>);
            })} />)
        }

    }

    return (
        <>
            <Form form={form} onFieldsChange={() => {
                if (!changed) {
                    setChanged(true);
                }
            }} onFinish={(_f) => updateSettings(_f)}>
                <Layout className="layout">
                    <LayoutHeader
                        title="Webstore Settings"
                        description="Use this page to manage webstore settings and permissions."
                        button={<>
                            <WebstoreSearch setCurrent={setCurrent} />
                        </>}
                    />

                </Layout>
                <div style={{ "height": "20px" }} className="shim shimbg" />
                <Layout className="layout">
                    <Layout.Sider width={200} theme="light">
                        <Affix style={{ "marginTop": "0px" }} offsetTop={100}>
                            <div>
                                <Tabs size="small" onChange={(e) => gotoSection(e)} activeKey={current} style={{ "paddingLeft": "17px" }} tabPosition="left" type="card">
                                    <Tabs.TabPane tab={<div className="wstab">General</div>} key="general_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Accounts</div>} key="accounts_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Ordering</div>} key="ordering_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Shipping</div>} key="shipping_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Searching</div>} key="searching_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Pricing</div>} key="pricing_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Payments</div>} key="payments_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Hours</div>} key="hours_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Multi-Store</div>} key="multistore_section" />
                                    <Tabs.TabPane tab={<div className="wstab">SEO</div>} key="seo_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Advanced</div>} key="advanced_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Title Manager</div>} key="titles_section" />
                                    <Tabs.TabPane tab={<div className="wstab">Vendor ETAs</div>} key="vendor_eta_section" />
                                </Tabs>
                                <div style={{"width" : "165px", "marginLeft" : "25px"}}>
                                    <div className="shim" /> <div className="shim" />
                                    <Button loading={loading} block type="primary" disabled={(!changed)} onClick={() => save()} size="small"><small>Save changes</small></Button>
                                    <div className="shim" /><div className="shim" />
                                    {drawProblems()}
                                  
                                </div>
                            </div>
                        </Affix>
                    </Layout.Sider>
                    <Layout.Content className="borderLeft" style={{ "padding": "0px", "minHeight" : "calc(100vh - 250px)" }} >
                        <Spin style={{ "minHeight": "500px" }} spinning={loading}>
                            {drawElement()}
                        </Spin>
                    </Layout.Content>
                </Layout>
            </Form>
        </>
    )
}