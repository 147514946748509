import React from "react";
import { Typography, Divider, Row, Col, Space, Form, Input } from "antd";
import TitleManager from "../../../pages/settings/TitleManager";
export default function TitlesSection(props) {

    

    const { ws, form, setChanged = () => {} } = props;

   

    return (
        <>
            <div id="titles" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Title Manager</Typography.Title>
                <Divider />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Hours */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <TitleManager setChanged={setChanged} title="Search Blocklist" subtitle="Add ISBN's to exclude them from customer searching and browsing. This would be items that are available to order from a preferred Pubstock source, or items you stock in store, that you do not want customers to find or purchase online." titles={ws.search_blacklist} form_key="search_blacklist" form={form} />
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Hours */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <TitleManager setChanged={setChanged} title="Search Allowlist" subtitle={<>Add ISBN's to include them in customer searching and browsing. This would be items that are not available to order from a preferred Pubstock source, and that you do not stock in store, but that you want customers to find or purchase online regardless.<br /><div className="shim" /> <em>This list has very specific use cases, so please consult Bookmanager support to see if this option is required, or another setting is better suited.</em></>} titles={ws.search_whitelist} form_key="search_whitelist" form={form} />
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Hours */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <TitleManager setChanged={setChanged} title="Zero price list" subtitle={<>Add ISBN's that can be purchased for $0. For example, an ASAN ISBN for a ticket, but the ticket is free and just being used for RSVP/attendance management purposes.<br /><div className="shim" /><em>By default, the webstore will not allow customers to check out for zero dollar items, so they must be added here as exceptions.</em></>} titles={ws.sell_zero_price} form_key="sell_zero_price" form={form} />
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}></Col>
                </Row>
                
            </div>
        </>
    )
}