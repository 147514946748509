import React from "react";
import { Form, Typography, Divider, Input, Button, Row, Col, Space } from "antd";
import WebstoreItem from "../WebstoreItem";
export default function AccountsSection(props) {

    const { ws, form } = props;
    const default_ws_custom_welcome_msg = "Your account has been created using (USER'S EMAIL ADDRESS). The upper right corner of our site will now indicate that you are logged in and ready to go.";

    return (
        <>
            <div id="accounts" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Accounts</Typography.Title>
                <Divider />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        <div id="ws_email_auth" />
                        {/* Require email verification from new users when they create a new account */}
                        <WebstoreItem
                            name="ws_email_auth"
                            value={(ws["ws_email_auth"]) ? true : false}
                            heading="Email verification"
                            description={<div>Require email verification from new users when they create a new account</div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Recieve notification email with the new user's details upon the creation of an account */}
                        <div id="ws_new_acct_notify" />
                        <WebstoreItem
                            name="ws_new_acct_notify"
                            value={(ws["ws_new_acct_notify"]) ? true : false}
                            heading="Email notification"
                            warning={true}
                            description={<div>Recieve notification email with the new user's details upon the creation of an account</div>}
                        />
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Custom required fields in account creation form */}
                        <div id="ws_new_acct_inputs" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Custom required fields in account creation form</Typography.Title>
                                <Typography.Paragraph type="secondary">Every line will be added as an input field when prospective customers create an account on your site.</Typography.Paragraph>
                                <Form.Item initialValue={ws["ws_new_acct_inputs"]} name="ws_new_acct_inputs" noStyle>
                                    <Input.TextArea maxLength={300} showCount style={{ "maxWidth": "500px" }} rows={4} >
                                </Input.TextArea>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Override welcome message */}
                        <div id="ws_custom_welcome_msg" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Override welcome message</Typography.Title>
                                <Typography.Paragraph type="secondary">Customize the welcome message a new user receives upon creating an account, or leave empty to use the default message.</Typography.Paragraph>
                                <Form.Item initialValue={(ws["ws_custom_welcome_msg"]) ? ws["ws_custom_welcome_msg"] : default_ws_custom_welcome_msg} name="ws_custom_welcome_msg" noStyle>
                                    <Input.TextArea maxLength={300} showCount style={{ "maxWidth": "500px" }} rows={4} placeholder=""></Input.TextArea>
                                </Form.Item>
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div><div className="shim"></div>
                                <Button size="small" onClick={() => form.setFieldsValue({ ws_custom_welcome_msg: default_ws_custom_welcome_msg })} type="primary"><small>Use default</small></Button>
                            </div>
                        </Space>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                    </Col>
                </Row>
            </div>
        </>
    )
}