import { CloseOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Space, Spin, Tabs, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import nocover from "../../media/no-cover.png"
import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import Html from "../../utils/Html";
import { parseAuthors, parseRank, parseSubjects, parseSubjectsList } from "../../utils/Utils";
import { cacheBuster } from "../../utils/Utils";
import BisacSelector from "./BisacSelector";
import CoverImage from "./CoverImage";
import TitleEditField from "./TitleEditField";

// Variants
export default function TitleDetails(props) {

    const { isbn, drawComponent = () => { }, close = false } = props;
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(true);
    const [edit, setEdit] = useState({ annotation_data: [] })
    const [cache, setCache] = useState(cacheBuster());
    const
        {
            authors = [],
            price = null,
            rank = {
                bestseller: null,
            },
            binding = "",
            release_date = null,
            is_forthcoming = false,
            available = [],
            canadian = false,
            title = "",
            subtitle = "",
            eisbn = "",
            height = null,
            width = null,
            pages = null,
            publisher = "",
            has_libro_audiobook = true,
            audience = null,
            edition = "",
            edition_type = null,
            has_interiors = null,
            print_status = null,
            imprint = "",
            series = "",
            series_num = "",
            weight = null,
            carton_qty = null,
            description = "",
            bio = [],
            comments = [],
            reviews = [],
            subjects = [],
            catalogues = [],
            illustrations = "",
            interiors = [],
            language = "",

            marketing = {
                "key_points": [],
                "more_info": [],
                "awards": [],
                "promotion": [],
                "printing": [],
                "extras": {
                    "links": [],
                    "publisher_links": [],
                    "author_links": []
                },
                "sales_rights": [
                ]
            }

        } = results;


    const getTitle = () => {
        apiCall("title/getItem", { isbn: isbn, want_keys_csv: "description, bio, marketing, sales" }, (_status, _result) => {
            if (_status) {
                setCache(cacheBuster());
                setResults(_result);
                getEdit();
            }
        })
    }

    const getEdit = (_currentedit) => {

        apiCall("title/getEdit", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                if (!_result.hasOwnProperty("annotation_data")) {
                    _result.annotation_data = [];
                }
                setEdit(_result);
                setLoading(false)
            }
        })
    }

    useEffect(getTitle, [isbn])

    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    const parseBio = (_bios) => {

        if (!_bios || _bios.length === 0) {
            return ("");
        }
        return _bios.map((_bio, index) => {
            return (<Html key={index} html={_bio} />);
        });
    }

    const parseReviews = (_reviews) => {
        if (!_reviews || _reviews.length === 0) {
            return ("");
        }
        return _reviews.map((_review, index) => {
            return (<span key={index}><Html html={_review.content} /></span>);
        });
    }


    const drawLinks = (_arr, _label) => {

        if (_arr.length < 1) {
            return (<></>);
        }
        return (
            <>
                <strong className="c">{_label}</strong><br />
                {_arr.map((item, _index) => {
                    return (<div><a href={item.url}>{item.description}</a> <div className="shim" /></div>);
                })}
                <div className="shim" /> <div className="shim" />
            </>
        )
    }

    const drawArray = (_arr, _label) => {

        return (
            <>
                <strong className="c">{_label}</strong><br />
                <Divider style={{ "margin": "10px 0px" }} dashed />
                {_arr.map((item, _index) => {
                    return (<Html html={item} />);
                })}
            </>
        )

    }

    const drawProductDetails = () => {

        return (<>
            <div className="details-box c">


                {/* <conditional.true value={(!editing)}>
                    <div key="0_price_ca">
                        <strong><small>Price CAD </small></strong><span><TitleEditField isbn={isbn} field="stat" edit={edit} editing={editing}>{edit.stat}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(!editing)}>
                    <div key="0_price_us">
                        <strong><small>Price USD </small></strong><span><TitleEditField isbn={isbn} field="stat" edit={edit} editing={editing}>{edit.stat}</TitleEditField></span></div>
                </conditional.true> */}

                <conditional.true value={(editing)}>
                    <div key="0_status">
                        <strong><small>Status </small></strong><span><TitleEditField isbn={isbn} field="stat" edit={edit} editing={editing}>{edit.stat}</TitleEditField></span></div>
                </conditional.true>


                <conditional.true value={(binding || editing)}>
                    <div key="0">
                        <strong><small>Binding </small></strong><span><TitleEditField isbn={isbn} field="binding" edit={edit} editing={editing}>{binding}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(pages || editing)}>
                    <div key="1"><strong><small>Pages: </small></strong> <span><TitleEditField isbn={isbn} field="pages" edit={edit} editing={editing}>{pages}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(series || editing)}>
                    <div key="2"><strong><small>Series: </small></strong> <span><TitleEditField isbn={isbn} field="series" edit={edit} editing={editing}>{series}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="2_series_number"><strong><small>Series Number: </small></strong> <span><TitleEditField isbn={isbn} field="series_number" edit={edit} editing={editing}>{edit.series_number}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(series || editing)}>
                    <div key="2_edition_type"><strong><small>Edition Type: </small></strong> <span><TitleEditField isbn={isbn} field="edition_type" edit={edit} editing={editing}>{edit.edition_type}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="2_edition_number"><strong><small>Edition Number: </small></strong> <span><TitleEditField isbn={isbn} field="edition_num" edit={edit} editing={editing}>{edit.edition_num}</TitleEditField></span></div>
                </conditional.true>


                <conditional.true value={(publisher || editing)}>
                    <div key="3"><strong><small>Publisher: </small></strong> <span><TitleEditField isbn={isbn} field="publisher" edit={edit} editing={editing}>{publisher}</TitleEditField></span> </div>
                </conditional.true>

                <conditional.true value={(language || editing)}>
                    <div key="12"><strong><small>Language: </small></strong> <span><TitleEditField isbn={isbn} field="language" edit={edit} editing={editing}>{language}</TitleEditField></span> </div>
                </conditional.true>

                <conditional.true value={(release_date || editing)}>
                    <div key="4"><strong><small>{(is_forthcoming) ? "Releases:" : "Published:"} </small></strong> <span><TitleEditField isbn={isbn} field="pubdate" edit={edit} editing={editing}>{(release_date) ? moment(release_date * 1000).format("MMMM D, YYYY") : ""}</TitleEditField></span> </div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="4_us"><strong><small>{(is_forthcoming) ? "Releases US:" : "Published US:"} </small></strong> <span><TitleEditField isbn={isbn} field="pubdate_us" edit={edit} editing={editing}>{(edit.pubdate_us) ? moment(edit.pubdate_us * 1000).format("MMMM D, YYYY") : ""}</TitleEditField></span> </div>
                </conditional.true>

                <conditional.true value={(audience || !editing)}>
                    <div key="5"><strong><small>Audience: </small></strong> {audience} </div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="5_a"><strong><small>Audience code: </small></strong> <span><TitleEditField isbn={isbn} field="audience_code" edit={edit} editing={editing}>{edit.audience_code}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="5_b"><strong><small>Audience type: </small></strong> <span><TitleEditField isbn={isbn} field="audience_type" edit={edit} editing={editing}>{edit.audience_type}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="5_c"><strong><small>Audience min: </small></strong> <span><TitleEditField isbn={isbn} field="audience_min" edit={edit} editing={editing}>{edit.audience_min}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="5_d"><strong><small>Audience max: </small></strong> <span><TitleEditField isbn={isbn} field="audience_max" edit={edit} editing={editing}>{edit.audience_max}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(subjects.length > 0 || editing)}>
                    <div key="6"><strong><small>Subjects: </small></strong><span style={{ "display": "inline", "padding": "10px 0px" }}><TitleEditField isbn={isbn} field="bisac_subjects" edit={edit} editing={editing}>{parseSubjectsList(subjects)}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(isbn && !editing)}>
                    <div key="7"><strong><small>EAN: </small></strong> <span>{isbn}</span> </div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="8_w"><strong><small>Width: </small></strong> <span><TitleEditField isbn={isbn} field="width" edit={edit} editing={editing}>{(width) ? width + '"' : ""}</TitleEditField></span> </div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="8_h"><strong><small>Height: </small></strong> <span><TitleEditField isbn={isbn} field="height" edit={edit} editing={editing}>{(height) ? height + '"' : ""}</TitleEditField></span> </div>
                </conditional.true>

                <conditional.true value={(editing)}>
                    <div key="8_d"><strong><small>Depth: </small></strong> <span><TitleEditField isbn={isbn} field="depth" edit={edit} editing={editing}>{(edit.depth) ? edit.depth + '"' : ""}</TitleEditField></span> </div>
                </conditional.true>

                <conditional.true value={(width && !editing)}>
                    <div key="8"><strong><small>Physical Dimensions: </small></strong> <span>{width}" x {height}"</span> </div>
                </conditional.true>

                <conditional.true value={(weight || editing)}>
                    <div key="9"><strong><small>Shipping Weight: </small></strong> <span><TitleEditField isbn={isbn} field="weight" edit={edit} editing={editing}>{(weight) ? weight.toString() + "lbs." : ""}</TitleEditField></span></div>
                </conditional.true>

                <conditional.true value={(carton_qty && !editing)}>
                    <div key="10"><strong><small>Carton Quantity: </small></strong> <span>{(carton_qty) ? carton_qty.toString() : ""}</span></div>
                </conditional.true>

                <conditional.true value={(rank.bestseller)}>
                    <div key="11"> <strong><small>Sales Rank: </small></strong> {parseRank(rank)} </div>
                </conditional.true>

            </div><div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" /></>
        )
    }


    const strToArr = (_v) => {

        if (Array.isArray(_v)) {
            return _v;
        } else {
            if (_v) {
                return _v.split(",")
            } else {
                return [_v];
            }

        }
    }

    const drawFlags = (_v) => {
        return _v.map((item, index) => {
            return <span style={{ "paddingRight": "5px" }}><img src={"https://cdn1.bookmanager.com/i/" + item + ".png"} /></span>
        })
    }


    const hasAnnotationData = (_num) => {
       
        let exists = edit.annotation_data.find((item) => item.id === _num)
        if (!exists) {
            exists = edit.missing_annotation_data.find((item) => item.id === _num)
        }
        return exists;
    }

    const getAnnotationData = (_num) => {
        let exists = edit.annotation_data.find((item) => item.id === _num);

        if (exists) {
            if (exists.hasOwnProperty("rows")) {
                return exists.rows;
            } else {
                return []
            }
        }
        return [];
    }

    const drawDetails = () => {
        return (<>




            {/* <BisacSelector /> */}
            {/* <div className="shim" style={{ "width": "850px", "backgroundColor": "#eee" }}></div>
            <br /> */}

            {/* <div style={{ "padding": "0 0px" }}>
                <div style={{ "marginTop": "0px" }} className="closebar">
                    <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                    <br clear="both" />
                </div>
            </div> */}


            <div style={{ "padding": "0px" }}>


                <div style={{ "minWidth": "371px" }}>
                    <Space size={15} align="start">
                        <div style={{ "width": "170px", "maxHeight": "400px" }}>
                            <CoverImage cache={cache} editing={editing} eisbn={eisbn} isbn={isbn} />

                            <div className="shim"></div>
                            <Space>
                                <Button className={(editing) ? "ant-btn-primary" : ""} size="small" onClick={() => setEditing(!editing)} ><small>{(editing) ? <EditOutlined /> : <EditOutlined />}</small></Button>

                                <Button loading={loading} size="small" onClick={() => getTitle(!editing)} ><small><ReloadOutlined /></small></Button>

                            </Space>

                        </div>

                        <div>

                            <Typography.Title style={{ "lineHeight": "22px" }} level={4}>
                                <TitleEditField isbn={isbn} field="title" edit={edit} editing={editing}>{title}</TitleEditField>
                                <div className="shim" />
                                <span style={{ "color": "#666", "fontSize": "15px", "lineHeight": "16px", "display": "block" }}>
                                    {subtitle}
                                </span>
                                <div className="shim"></div>

                                <conditional.true value={(!editing)}>
                                    {parseAuthors(authors)}
                                </conditional.true>


                                <conditional.true value={(editing)}>
                                    <span style={{ "fontSize": "14px", "fontWeight": "normal" }}>
                                        <TitleEditField isbn={isbn} field="author" edit={edit} editing={editing}>{(authors[0]) ? <a style={{ "color": "#069", "fontWeight": "600" }}>{authors[0].name}</a> : ""}</TitleEditField> &nbsp;&nbsp;
                                        <TitleEditField isbn={isbn} field="author2" edit={edit} editing={editing}>{(authors[1]) ? <><a style={{ "color": "#069", "fontWeight": "600" }}>{authors[1].name}</a></> : ""}</TitleEditField> &nbsp;&nbsp;
                                        <TitleEditField isbn={isbn} field="author3" edit={edit} editing={editing}>{(authors[2]) ? <><a style={{ "color": "#069", "fontWeight": "600" }}>{authors[2].name}</a></> : ""}</TitleEditField>
                                    </span>
                                </conditional.true>

                            </Typography.Title>

                            <Typography.Paragraph style={{ "fontSize": "14px" }}>

                                {/* Publisher */}
                                <conditional.true value={(publisher || editing)}>
                                    <TitleEditField isbn={isbn} field="publisher" edit={edit} editing={editing}>{publisher}</TitleEditField><br />
                                </conditional.true>

                                {/* Subjects */}
                                <conditional.true value={(subjects || editing)}>
                                    <TitleEditField isbn={isbn} field="bisac_subjects" edit={edit} editing={editing}>{parseSubjects(subjects)}</TitleEditField> <br />
                                </conditional.true>

                                {/* Binding */}
                                <conditional.true value={(binding || editing)}>
                                    <TitleEditField isbn={isbn} field="binding" edit={edit} editing={editing}>{(binding) ? <strong>{binding}</strong> : ""}</TitleEditField><br />
                                </conditional.true>

                                {/* Publish Date */}
                                <conditional.true value={(release_date || editing)}>
                                    <TitleEditField isbn={isbn} field="pubdate" edit={edit} editing={editing}>{(release_date && <><strong>{(is_forthcoming) ? "Releases:" : "Published:"} </strong>{moment(release_date * 1000).format("MMMM D, YYYY")} </>)}</TitleEditField><br />
                                </conditional.true>

                                {/* US Publish Date */}
                                <conditional.true value={(editing)}>
                                    <TitleEditField isbn={isbn} field="pubdate_us" edit={edit} editing={editing}>{(edit.pubdate_us && <><strong>{(is_forthcoming) ? "Releases US:" : "Published US:"} </strong>{moment(edit.pubdate_us * 1000).format("MMMM D, YYYY")} </>)}</TitleEditField><br />
                                </conditional.true>

                                {/* Series */}
                                <conditional.true value={(series || editing)}>
                                    <TitleEditField isbn={isbn} field="series" edit={edit} editing={editing}>{(release_date && <><strong>Series: </strong><a>{series}</a> </>)}</TitleEditField><br />
                                </conditional.true>

                                {/* Series Number */}
                                <conditional.true value={(editing)}>
                                    <TitleEditField isbn={isbn} field="series_number" edit={edit} editing={editing}>{(edit.series_number) ? <><strong>Series Number: </strong>{edit.series_number}</> : ""}</TitleEditField><br />
                                </conditional.true>

                                {/* Rank */}
                                <conditional.true value={(rank)}>
                                    {parseRank(rank)}
                                </conditional.true>

                                <div style={{ "height": "20px" }} className="shim"></div>

                            </Typography.Paragraph>
                        </div>
                    </Space>

                    <div className="shim" /><div className="shim" />

                    <Tabs className="itemTabs" size="small" type="card" defaultActiveKey="description">


                        <Tabs.TabPane tab={<small>Description</small>} key="description">
                            {/* Description */}
                            <TitleEditField isbn={isbn} annotation={hasAnnotationData(1)} field="main_description" edit={edit} editing={editing}>
                                {(description) ? <Html html={description} /> : ""}
                            </TitleEditField>

                            <conditional.true value={(editing)}>
                                <br />
                                <TitleEditField isbn={isbn} annotation={hasAnnotationData(2)} field="short_description" edit={edit} editing={editing}>
                                    {parseBio(getAnnotationData(2))}
                                </TitleEditField>

                                <br />
                                <TitleEditField isbn={isbn} annotation={hasAnnotationData(3)} field="long_description" edit={edit} editing={editing}>
                                    {parseBio(getAnnotationData(3))}
                                </TitleEditField>

                                <br />
                                <TitleEditField isbn={isbn} annotation={hasAnnotationData(12)} field="reader_description" edit={edit} editing={editing}>
                                    {parseBio(getAnnotationData(12))}
                                </TitleEditField>
                                <br />
                                <TitleEditField isbn={isbn} annotation={hasAnnotationData(8)} field="review_quote" edit={edit} editing={editing}>
                                    {parseBio(getAnnotationData(12))}
                                </TitleEditField>

                                <br />


                            </conditional.true>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<small>Author</small>} key="author">
                            <TitleEditField annotation={hasAnnotationData(13)} isbn={isbn} field="bio" edit={edit} editing={editing}>
                                {parseBio(bio)}
                            </TitleEditField>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<small>Details</small>} key="details">
                            {drawProductDetails()}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<small>Reviews</small>} key="reviews">
                            <TitleEditField isbn={isbn} field="reviews" annotation={hasAnnotationData(8)} edit={edit} editing={editing}>{parseReviews(reviews)}</TitleEditField>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={<small>Marketing</small>} key="marketing">
                            {/* // Removed by kellynda's request #1131 */}
                            {/* <conditional.true value={((marketing.sales_rights.length > 0) || editing)}>
                                <TitleEditField isbn={isbn} field="sales_rights_exclude" edit={edit} editing={editing}>{(marketing.sales_rights.length > 0) ? <><strong className="c">Sales Rights</strong><Divider style={{ "margin": "10px 0px" }} dashed />{drawFlags(marketing.sales_rights)}</> : <strong className="c">Sales Rights</strong>}</TitleEditField>
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                            </conditional.true> */}

                            <conditional.true value={(marketing.key_points.length > 0)}>
                                <div className="shim" />
                                {drawArray(marketing.key_points, "Key Points")}
                            </conditional.true>

                            <conditional.true value={(marketing.more_info.length > 0)}>
                                <div className="shim" />
                                {drawArray(marketing.more_info, "More Info")}
                            </conditional.true>

                            <conditional.true value={(marketing.awards.length > 0)}>
                                <div className="shim" />
                                {drawArray(marketing.awards, "Awards")}
                            </conditional.true>

                            <conditional.true value={(marketing.promotion.length > 0)}>
                                <div className="shim" />
                                {drawArray(marketing.promotion, "Promotion")}
                            </conditional.true>

                            <conditional.true value={(marketing.printing.length > 0)}>
                                <div className="shim" />
                                {drawArray(marketing.printing, "Printing")}
                            </conditional.true>



                            <conditional.true value={((marketing.extras.links.length > 0) || (marketing.extras.publisher_links.length > 0) || (marketing.extras.author_links.length > 0))}>
                                <div className="shim" />
                                {drawLinks(marketing.extras.links, "Links")}
                                {drawLinks(marketing.extras.publisher_links, "Publisher Links")}
                                {drawLinks(marketing.extras.author_links, "Author Links")}
                            </conditional.true>




                        </Tabs.TabPane>







                    </Tabs>
                    <div className="shim" /><div className="shim" />
                </div>
            </div>
        </>)
    }






    if (loading) {
        return (<Spin style={{ "width": "1024px", "background": "transparent" }}><div style={{ "height": "200px", "background": "transparent" }}></div></Spin>);
    }

    return drawDetails();



}

