import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, Radio, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall } from "../../../utils/Api";
import WebstoreItem from "../WebstoreItem";

export default function GeneralSection(props) {


    const [pages, setPages] = useState([]);
    const getPages = () => {
        apiCall("settings/getCustomPages", {}, (_status, _results) => {
            if (_status) {
                setPages(_results);
            }
        })
    }

    useEffect(getPages, []);


    const drawPages = () => {
        return pages.map((item, index) => {
            return (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
        })
    }

    const { ws, form } = props;


    const default_ws_login_required_message = "Ordering from our site is available only to preauthorized customers.  Please contact us by phone at @@phone@@ or email at @@email@@ to have us create or authorize your account.";

    return (
        <>

            <div id="general"></div>
            <div style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>General</Typography.Title>
                <Divider />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Private webstore */}
                        <div id="ws_self_login_required"></div>
                        <WebstoreItem
                            name="ws_self_login_required"
                            value={(ws["ws_self_login_required"]) ? true : false}
                            heading="Private webstore"
                            description={<div>When this box is checked, access to this site is only allowed by a site administrator or someone with the appropriate permissions. Some stores may set their site to Private when they are first customizing and configuring their Webstore until they are comfortable and ready to make it visible.</div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>

                        {/* Treat non-approved customers as anonymous users */}
                        <div id="ws_use_whitelist"></div>
                        <WebstoreItem
                            name="ws_use_whitelist"
                            value={(ws["ws_use_whitelist"]) ? true : false}
                            heading="Treat non-approved customers as anonymous users"
                            description={<div>When this box is checked, customers whom you have not approved on the customer accounts page will be given the same level of access to this webtore as those who have not created an account or logged in. This will have no effect unless other options are selected that affect anonymous users, such as hiding the cart or prices.</div>}
                        />

                    </Col>
                </Row>
                <Divider dashed />

                {/* THESE ARE NOT USED ON V3 */}
                {/* <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}> */}
                {/* Forward mobile users to contact page */}
                {/* <WebstoreItem
                            name="ws_enable_mobile_site"
                            value={(ws["ws_enable_mobile_site"]) ? true : false}
                            heading="Forward mobile users to contact page"
                            description={<div>Do not enable the above "Mobile mode" option until you have personally tested it.</div>}
                        /> */}
                {/* </Col>
                    <Col md={24} xl={12}> */}

                {/* Mobile site options */}
                {/* <WebstoreItem
                            name="cta_mobile_enabled"
                            value={(ws["cta_mobile_enabled"]) ? true : false}
                            heading="Mobile mode"
                            warning={true}
                            notice={<div>Do not enable the above "Mobile mode" option until you have personally tested it.</div>}
                            description={<div>To test, keep the above "Mobile mode" option un-selected, then visit your site from a mobile device. Log in with your store account, then scroll to the bottom site footer and tap "Test mobile version of this site." Next, be sure to log out of your store account or switch to your personal account in order to experience the consumer's mobile version. You can only make changes to your site content on the desktop version of the site. Once you are satisfied with your site content, menus, and checkout process, you can come back here and enable "Mobile mode" for your customers' use.</div>}
                        /> */}
                {/* </Col>
                </Row> */}
                {/* <Divider dashed /> */}


                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Visible only when customer is logged in */}
                        <div id="ws_login_required"></div>
                        <WebstoreItem
                            name="ws_login_required"
                            value={(ws["ws_login_required"]) ? true : false}
                            heading="Visible only when customer is logged in"
                            description={<div>When this box is checked, access to this site is only granted to customers with accounts registered with Bookmanager. A "send invitation" option will appear in the webstore's menu bar to those with proper permissions. </div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Visible only when customer is logged in Message */}
                        <div id="ws_login_required_message"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Login Prompt</Typography.Title>
                                <Typography.Paragraph type="secondary">This message will show up when the user is prompted to log in. You can customize it; your store’s email address and phone number will be automatically filled in for the @@phone@@ and @@email@@ placeholders. Leave it empty to use the default message.</Typography.Paragraph>
                                <Form.Item initialValue={(ws["ws_login_required_message"]) ? ws["ws_login_required_message"] : default_ws_login_required_message} name="ws_login_required_message" >
                                    <Input.TextArea showCount maxLength={300} style={{ "maxWidth": "500px" }} rows={4} >
                                    </Input.TextArea>
                                </Form.Item>
                                <div className="shim"></div> <div className="shim"></div>
                                <Button size="small" onClick={() => form.setFieldsValue({ ws_login_required_message: default_ws_login_required_message })} type="primary"><small>Use default</small></Button>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Allow customers to view their lists at any Bookmanager site */}
                        <div id="ws_allow_list_transfer"></div>
                        <WebstoreItem
                            name="ws_allow_list_transfer"
                            value={(ws["ws_allow_list_transfer"]) ? true : false}
                            heading="Allow customers to view their lists at any Bookmanager site"
                            description={<div>When this box is unchecked, customers creating a list at your site will not see their list at another Bookmanager webstore, and likewise, lists they create at other stores will not be visible from your site. Tip: This feature is popular with librarians but booksellers might prefer to discourage shopping at other stores.</div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Enable automatic browse redirection*/}
                        <div id="ws_auto_browse_destination"></div>
                        <WebstoreItem
                            name="ws_auto_browse_destination"
                            value={(ws["ws_auto_browse_destination"]) ? true : false}
                            heading="Enable automatic browse redirection"
                            description={<div>When this box is checked, you (or other staff/users with the Store login) will be forwarded to the TitleBrowser after logging in.</div>}
                        />
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Available to Order wording: */}
                        <div id="ws_order_wording"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Available to Order wording:</Typography.Title>
                                <Typography.Paragraph type="secondary">Choose the wording shown for titles that you do not have in stock and will order for the customer.</Typography.Paragraph>
                                <Form.Item initialValue={(ws["ws_order_wording"]) ? true : false} noStyle name="ws_order_wording">
                                    <Radio.Group>
                                        <Radio value={false}>special order</Radio>
                                        <div className="shim"></div>
                                        <Radio value={true}>available to order</Radio>
                                        <div className="shim"></div>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Available On Hand wording: */}
                        <div id="ws_instore_instock"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Available On Hand wording:</Typography.Title>
                                <Typography.Paragraph type="secondary">Choose the wording shown for titles that you have available On Hand (aOH). "In stock" may be preferable for webstores that don't have storefronts.</Typography.Paragraph>
                                <Form.Item initialValue={(ws["ws_instore_instock"]) ? true : false} noStyle name="ws_instore_instock">
                                    <Radio.Group>
                                        <Radio value={false}>in store</Radio>
                                        <div className="shim"></div>
                                        <Radio value={true}>in stock</Radio>
                                        <div className="shim"></div>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <br />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Cover display wording: */}
                        <div id="ws_wording_hide_cover_nis_so"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Cover display wording:</Typography.Title>
                                <Typography.Paragraph type="secondary">Choose to display "not in stock" when no onhand or onorder and "sold out" when onorder for the cover display.</Typography.Paragraph>
                                <Form.Item initialValue={(ws["ws_wording_hide_cover_nis_so"]) ? true : false} noStyle name="ws_wording_hide_cover_nis_so">
                                    <Radio.Group>
                                        <Radio value={false}>Show</Radio>
                                        <div className="shim"></div>
                                        <Radio value={true}>Hide</Radio>
                                        <div className="shim"></div>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Use custom On Hand conditions */}
                        <div id="ws_use_onhand_conditions"></div>
                        <WebstoreItem
                            name="ws_use_onhand_conditions"
                            value={(ws["ws_use_onhand_conditions"]) ? true : false}
                            heading="Use custom On Hand conditions"
                            description={<div>Current condition codes:<br />
                            
                            {window.sitesettings.condition_codes.map(item => {
                                if(item.code){
                                    return <>{item.code}: {item.name} <br /></>
                                }
                            })}
                           <br />If you have enabled custom On Hand conditions (bargain, used, signed, etc.) in your Bookmanager software, checking this box will allow those books to be visible on your Webstore</div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                        <div id="ws_hide_matching_new_price_condition"></div>
                        {/* Hide cart for regular (new) titles if non-regular stock exists at the same price */}
                        <WebstoreItem
                            name="ws_hide_matching_new_price_condition"
                            value={(ws["ws_hide_matching_new_price_condition"]) ? true : false}
                            heading="Hide cart for regular (new) titles if non-regular stock exists at the same price"
                            description={<div>When this box is unchecked, the consumer can choose from any of multiple stock conditions having the same price to add to their cart. When checked, the regular condition cart will be hidden only if there are non-regular condition books available for the new price. An example use of this option would be if you sell signed or promotional books for the same price as a new book, but don't want customers to have the option to buy regular (new) unsigned books.</div>}
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        <div id="ws_default_featured_sort"></div>
                        {/* Featured Lists will be shown: */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Featured Lists will be shown:</Typography.Title>
                                <Form.Item initialValue={ws["ws_default_featured_sort"]} noStyle name="ws_default_featured_sort">
                                    <Radio.Group>
                                        <Radio value="tldefsequence">in the sequence I have chosen (custom)</Radio>
                                        <div className="shim"></div>
                                        <Radio value="name">in alphabetical order</Radio>
                                        <div className="shim"></div>
                                        <Radio value="lastupdate">in order of date last updated</Radio>
                                        <div className="shim"></div>
                                        <Radio value="dateposted">in order of date posted</Radio>
                                        <div className="shim"></div>
                                    </Radio.Group>
                                </Form.Item>
                                <div className="shim"></div>
                                <Typography.Paragraph type="secondary">When viewing Featured Lists, the default sort option above will be used but can be temporarily changed by the user. To create a custom sequence, you must be logged in as the store, choose "custom" sort, click "Display: Brief", then use the arrows to move lists (your customers cannot do this). The custom sort is the recommended default to ensure that the most important featured items appear first on your lists. When a new Featured list is added, it will appear at the very top.</Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        <div id="ws_homeurl"></div>
                        {/* Alternate home page URL */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Alternate home page URL</Typography.Title>
                                <Typography.Paragraph type="secondary">If you want visitors to be taken to another site when they click Home, please enter in the address in the box. Otherwise, they will simply return to the main page of your Webstore.</Typography.Paragraph>
                                <Form.Item name="ws_homeurl" rules={[{ type: "url", message: "Must be a valid url." }]} initialValue={ws["ws_homeurl"]} >
                                    <Input style={{ "maxWidth": "500px" }} placeholder="https://example.com/" />
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        <div id="ws_landing"></div>
                        {/* Landing page URL*/}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Landing page URL</Typography.Title>
                                <Typography.Paragraph type="secondary">If you want visitors to be taken to another page when they initially navigate to your front page, enter the address in the box. Otherwise they will simply land on the front page of your Webstore.</Typography.Paragraph>
                                <Form.Item name="ws_landing" rules={[{ type: "url", message: "Must be a valid url." }]} initialValue={ws["ws_landing"]} >
                                    <Input style={{ "maxWidth": "500px" }} placeholder="https://example.com/" />
                                </Form.Item>
                                <div className="shim"></div><div className="shim"></div>
                                <Form.Item valuePropName='checked' initialValue={(ws["ws_landing_logged_out"]) ? true : false} name="ws_landing_logged_out" noStyle>
                                    <Checkbox>Only redirect users who are <strong>not</strong> logged in</Checkbox>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        <div id="ws_policy_page"></div>
                        {/* Privacy Policy Page */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Privacy Policy Page</Typography.Title>
                                <Typography.Paragraph type="secondary">If you have a Privacy Policy page, you can link to it in your page footer here.</Typography.Paragraph>
                                <Form.Item name="ws_policy_page" initialValue={ws["ws_policy_page"]}>
                                    <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' dropdownMatchSelectWidth={false} >
                                        <Select.Option key="" value="">- Use none -</Select.Option>
                                        {drawPages()}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        <div id="ws_custom_contact_page"></div>
                        {/* Contact Us Page */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Contact Us Page</Typography.Title>
                                <Typography.Paragraph type="secondary">Choose whether to use the standard Contact Us page, or one of your custom pages.</Typography.Paragraph>
                                <Form.Item name="ws_custom_contact_page" initialValue={ws["ws_custom_contact_page"]}>
                                    <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' dropdownMatchSelectWidth={false}>
                                        <Select.Option key="" value="">- Use standard page -</Select.Option>
                                        {drawPages()}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>

                        {/* Map location override */}

                        <Space style={{ "pointerEvents": "none", "opacity": "0.2" }}>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Map location override</Typography.Title>
                                <Typography.Paragraph type="secondary">If the map location of the marker for your store does not look quite right, you can edit the marker in your <span style={{ "color": "#069" }}>Contact Us</span> page. (Hover over the map and click the Edit button).</Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        <div id="ws_hide_footer_address"></div>
                        {/* Street address In footer: ws_hide_footer_address */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Street address In footer:</Typography.Title>
                                <Form.Item initialValue={(ws["ws_hide_footer_address"]) ? true : false} noStyle name="ws_hide_footer_address">
                                    <Radio.Group>
                                        <Radio value={false}>Show</Radio>
                                        <div className="shim"></div>
                                        <Radio value={true}>Hide</Radio>
                                        <div className="shim"></div>
                                    </Radio.Group>
                                </Form.Item>
                                <Typography.Paragraph type="secondary">You may wish to hide the street address if you have multiple store locations.</Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        <div id="ws_facebookurl"></div>

                        {/* Social media */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                {/* Social media */}
                                <Typography.Title level={5}>Social media</Typography.Title>
                                <Typography.Paragraph type="secondary">If you want visitors to be taken to another site when they click Home, please enter in the address in the box. Otherwise, they will simply return to the main page of your Webstore.</Typography.Paragraph>
                                {/* <Form layout="vertical"> */}
                                <label>Facebook URL</label>
                                <Form.Item rules={[{ type: "url", message: "Must be a valid url." }]} name="ws_facebookurl" initialValue={ws["ws_facebookurl"]} label="">
                                    <Input style={{ "maxWidth": "500px" }} placeholder="https://facebook.com/" />
                                </Form.Item>
                                <label>Twitter Id</label>
                                <Form.Item help={<small className="c">Max 20 characters</small>} name="ws_twitter_username" initialValue={ws["ws_twitter_username"]} label="">
                                    <Input maxLength={20} style={{ "maxWidth": "500px" }} placeholder="Twitter id" />
                                </Form.Item>
                                <label>Instagram Id</label>
                                <Form.Item help={<small className="c">Max 30 characters</small>} name="ws_instagram_username" initialValue={ws["ws_instagram_username"]} label="">
                                    <Input maxLength={30} style={{ "maxWidth": "500px" }} placeholder="Instagram id" />
                                </Form.Item>

                                <label>Tiktok URL</label>
                                <Form.Item rules={[{ type: "url", message: "Must be a valid url." }]} name="ws_tiktok_url" initialValue={ws["ws_tiktok_url"]} label="">
                                    <Input style={{ "maxWidth": "500px" }} placeholder="https://tiktok.com/" />
                                </Form.Item>
                                {/* </Form> */}
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        <div id="ws_libro_bookstore_name"></div>
                        {/* Libro.fm Audiobook Integration */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Libro.fm Audiobook Integration</Typography.Title>
                                <Typography.Paragraph type="secondary">If you have registered as an audiobook retailer with Libro.fm, you can allow customers browsing your webstore the option to purchase Libro.fm audiobooks when they are available for a title. Audiobook purchases will be completed on the Libro.fm website, but your store will earn commision based on those sales.</Typography.Paragraph>
                                <Typography.Paragraph type="secondary">Enter your store's Libro.fm Storefront URL name in the field below to enable the integration.</Typography.Paragraph>
                                <Typography.Paragraph type="secondary"><strong>NOTE:</strong> Do not type your bookstore's full name in this field. Your Libro.fm <strong>Storefront URL</strong> name is what is required. For example, Mosaic Books' Libro.fm Storefront URL is https://libro.fm/mosaicbooks, so the Storefront URL name is the last bit, <strong>mosaicbooks</strong> (all one word). This URL can be found by logging into your Libro.fm account, clicking the Gear icon near the Bookseller Hub menu item at the top, then by clicking the Store Info link from the left menu.</Typography.Paragraph>
                                <Form.Item name="ws_libro_bookstore_name" initialValue={ws["ws_libro_bookstore_name"]} noStyle>
                                    <Input maxLength={30} style={{ "maxWidth": "500px" }} placeholder="Libro.fm store name" />
                                </Form.Item>
                            </div>
                        </Space>

                    </Col>
                </Row>

                <Divider dashed />

                <Row gutter={[30, 30]}>

                    <Col md={24} xl={12}>

                        {/* Treat non-approved customers as anonymous users */}
                        <div id="ws_display_carton_quantity"></div>
                        <WebstoreItem
                            name="ws_display_carton_quantity"
                            value={(ws["ws_display_carton_quantity"]) ? true : false}
                            heading="Display carton quantity below Add to Cart"
                            description={<div>By default, if a title has a carton quantity from the publisher, that will be displayed under the title's Details tab. Enable this option if you would like to feature the carton quantity more predominantly, within the Add to Cart area.</div>}
                        />

                    </Col>

                    <Col md={24} xl={12}>

                        {/* Show libro FM link on details screen. */}
                        <div id="ws_prominent_librofm"></div>
                        <WebstoreItem
                            name="ws_prominent_librofm"
                            value={(ws["ws_prominent_librofm"]) ? true : false}
                            heading="Show libro FM link on details screen."
                            description={<div>Enabling will show the libro FM link on the details screen for titles. Otherwise the libro FM link will be displayed with the title's bindings. </div>}
                        />
                    </Col>
                </Row>

                <Divider dashed />

                <Row gutter={[30, 30]}>

                    <Col md={24} xl={12}>

                        {/* Open titles in new Bookmanager */}
                        <div id="bm_use_biz_url"></div>
                        <WebstoreItem
                            name="bm_use_biz_url"
                            value={(ws["bm_use_biz_url"]) ? true : false}
                            heading="View on Webstore sends to Biz"
                            description={<div>Enabling will display titles on the new Biz website when pressing <code>W</code> in Bookmanager. Disabled, pressing <code>W</code> will load the old admin website.</div>}
                        />

                    </Col>


                    <Col md={24} xl={12}>
                        <div style={{"opacity" : (window.sitesettings.is_canadian) ? "1" : "0.2", "pointerEvents" : (window.sitesettings.is_canadian) ? "inherit" : "none"}}>
                            {/* Display Government of Canada funding acknowledgement */}
                            <div id="ws_government_of_canada_logo_enabled"></div>
                            <WebstoreItem
                                name="ws_government_of_canada_logo_enabled"
                                value={(ws["ws_government_of_canada_logo_enabled"]) ? true : false}
                                heading="Display Government of Canada funding acknowledgement"
                                description={<div>Displays a small acknowledgement with the Government of Canada logo in your consumer site's footer.
                                    <br /><div className="shim" />
                                    <Typography.Text>
                                    <Form.Item valuePropName='checked' initialValue={(ws["ws_government_of_canada_bilingual"]) ? true : false} name="ws_government_of_canada_bilingual" noStyle>
                                        <Checkbox />
                                    </Form.Item> Display Bilingual?</Typography.Text>

                                   
                                    <br /><br />
                                </div>}
                            />
                        </div>
                    </Col>

                </Row>







                <div className="shim"></div><div className="shim"></div>
            </div>
        </>
    )
}