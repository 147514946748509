import { DownOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Modal, Select, Spin, Typography } from "antd";
import React, { useState } from "react";

export default function Provider(props) {

    const { presets = [], group = {}, data = {}, region = {}, removeProvider = () => { }, updateProvider = () => { } } = props;

    const {
        label = "",
        cost_min = "0.00",
        cost_inc = "0.00",
        cost_max = "0.00",
        min_free = "",
        min_days = "0",
        max_days = "0",
        filter_preset_id = "",
        isbn = ""
    } = data;

    const [min, setMin] = useState(cost_min)
    const [max, setMax] = useState(cost_max)


    let m = isbn.replace("0S-", "");

    const [isbnVal, setIsbnVal] = useState(m);

    const validateField = (_reg, _label, _key, _value) => {

        if (_key === "min_free" && (_value === "0.00" || _value === "0.0" || _value === "0")) {
            Modal.warn({ title: "Warning", content: <p style={{ "fontSize": "14px" }}>Setting <em className="c">Free shipping if total exceeds</em> to <em className="c">$0.00</em> will result in free shipping on all orders for this provider.</p> })
        }

        if (_key === "cost_max" && parseFloat(_value) < parseFloat(cost_min)) {
            Modal.warn({ title: "Warning", content: <p style={{ "fontSize": "14px" }}>The <em className="c">Maximum shipping cost</em> must be greater than the  <em className="c">Minimum shipping cost</em>.</p> })
        }

        if (_key === "cost_min" && parseFloat(_value) > parseFloat(cost_max)) {
            Modal.warn({ title: "Warning", content: <p style={{ "fontSize": "14px" }}>The <em className="c">Minimum shipping cost</em> must be less than the  <em className="c">Maximum shipping cost</em>.</p> })
        }

        if (_key === "isbn") {
            _value = _value.replace("0S-", "");
            _value = "0S-" + _value.toUpperCase();
        }



        updateProvider(group, _label, _key, _value);

    }




    const regex = /[A-Za-z0-9]{3}/g;

    return (


        <div style={{ "textAlign": "left", "paddingLeft": "23px" }}>


            <div className="shim"></div><div className="shim"></div>

            <Spin spinning={(group.disallowed || group.free)} indicator={<></>}>
                <Typography.Text type="secondary">Minimum shipping cost $ <Form.Item noStyle ><InputNumber precision={2} className="hidecontrols" onChange={(e) => setMin(e)} onBlur={(e) => validateField(region.region, label, "cost_min", e.target.value)} value={min} size="small" prefix="$" style={{ "maxWidth": "70px" }}></InputNumber></Form.Item></Typography.Text><br /><div className="shim"></div>
                <Typography.Text type="secondary">Each additional item $ <Form.Item noStyle ><InputNumber precision={2} className="hidecontrols" onBlur={(e) => updateProvider(group, label, "cost_inc", e.target.value)} defaultValue={cost_inc} size="small" prefix="$" style={{ "maxWidth": "70px" }}></InputNumber></Form.Item></Typography.Text><br /><div className="shim"></div>
                <Typography.Text type="secondary">Maximum shipping cost $ <Form.Item noStyle ><InputNumber precision={2} className="hidecontrols" onChange={(e) => setMax(e)} onBlur={(e) => validateField(region.region, label, "cost_max", e.target.value)} value={max} size="small" prefix="$" style={{ "maxWidth": "70px" }}></InputNumber></Form.Item></Typography.Text><br /><div className="shim"></div>
                <Typography.Text type="secondary">Free shipping if total exceeds $ <Form.Item noStyle ><InputNumber precision={2} className="hidecontrols" onBlur={(e) => validateField(region.region, label, "min_free", e.target.value)} defaultValue={min_free} size="small" prefix="$" style={{ "maxWidth": "70px" }}></InputNumber></Form.Item></Typography.Text><br /><div className="shim"></div>
            </Spin>

            <Typography.Text type="secondary">Orders arrive between <Form.Item noStyle ><InputNumber className="hidecontrols" onBlur={(e) => updateProvider(group, label, "min_days", e.target.value)} defaultValue={min_days} size="small" style={{ "maxWidth": "45px" }}></InputNumber></Form.Item> - <Form.Item noStyle ><InputNumber className="hidecontrols" onBlur={(e) => updateProvider(group, label, "max_days", e.target.value)} defaultValue={max_days} size="small" style={{ "maxWidth": "45px" }}></InputNumber></Form.Item> Day(s)</Typography.Text> <br /><div className="shim"></div>
            <Typography.Text type="secondary">ISBN <Form.Item noStyle ><Input maxLength={3} className="hidecontrols" onBlur={(e) => validateField(region.region, label, "isbn", e.target.value)} onChange={(e) => {

                setIsbnVal(e.target.value);

            }} defaultValue={isbnVal} size="small" prefix="0S-" style={{ "maxWidth": "75px" }}></Input></Form.Item></Typography.Text><br /><div className="shim"></div>
            {(!regex.test(isbnVal) && <div><small style={{ "color": "red" }}>ISBN must include 3 additional alphanumeric characters.</small></div>)}
            <br />
            <Typography.Text type="secondary">This provider will only be offered for carts containing products that match the following <em>Browse Filter Preset:</em>&nbsp;<Form.Item noStyle >
                <Select
                    defaultValue={parseInt(filter_preset_id) || ""}
                    suffixIcon={<DownOutlined className='ant-select-custom-arrow'/>}
                    getPopupContainer={trigger => trigger.parentElement}
                    onChange={(e) => updateProvider(group, label, "filter_preset_id", e.toString())}
                    placeholder="Select..."
                    options={[{ label: "All browsable titles", value: "" }].concat([...presets])}
                    filterOption={(input, option) => {
                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    showSearch size="small" style={{ "width": "350px" }} >
                </Select>
            </Form.Item>
            </Typography.Text>
            
            <br /><div className="shim"></div>


            <div className="shim"></div><div className="shim"></div>
        </div>


    )


}