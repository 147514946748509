import React from "react";
import { InputNumber, Typography, Divider, Table, Card } from "antd";

export default function VendorETA(props) {

    const { changed = false, setChanged = () => {}, suppliers = [], setSuppliers = () => { } } = props;

    
    const updateETA = (_days, _obj) => {
        let res = [...suppliers];
        let find = res.findIndex(item => item.acct === _obj.acct);
        if (!Number.isInteger(_days)) { _days = 0; }
        if (find > -1) {
            _obj.estimate_delivery = _days;
            res[find] = _obj;
            setSuppliers(res);
            setChanged(true);
        }
    }

    const columns = [
        {},
        { title: <small>Pubstock Code</small>, dataIndex: 'acct', key: 'acct', render : (e) => {
            return (e) ? e.toUpperCase() : "";
        } },
        { title: <small>Supplier Name</small>, dataIndex: 'name', key: 'name' },
        {
            title: <small>Order Time-frame <small> (business days)</small></small>, dataIndex: 'estimate_delivery', key: 'estimate_delivery', render: (e, f) => {
                return <InputNumber onChange={(e) => updateETA(e, f)} type="number" style={{ "width": "60px" }} defaultValue={(Number.isInteger(e)) ? e : 0} size="small" />
            }
        },
        { title: <small>Exchange</small>, dataIndex: 'exchange', key: 'exchange' },
        { title: <small>Markup</small>, dataIndex: 'markup', key: 'markup' },
        { title: <small>SD</small>, dataIndex: 'min_margin', key: 'min_margin' },
    ]

    return (
        <div id="pubstocksetup" style={{ "padding": "10px 30px" }}>
            <Typography.Title level={4}>Vendor ETAs</Typography.Title>
            <Divider />
            <Typography.Paragraph type="secondary" >
                These Pubstock suppliers are possible sources for consumer special orders. Configuration for these suppliers is done within the Bookmanager software (<strong>3 Suppliers</strong> and the <strong>Format</strong> option when viewing Pubstock). Only suppliers marked with <strong>Favourite</strong> or <strong>Yes</strong> in the Bookmanager Software will be listed here and considered as sources of supply for customer special orders. <br /><br />Use the <strong>Order Time-frame</strong> field to specify how long it takes in business days to receive product from each supplier, from the time a customer places their order until it is ready for in-store pick up. We recommended erring on the side of longer, as customers will always be happy to receive their order earlier than quoted.
            </Typography.Paragraph>
            <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                <Table
                    size="small"
                    header={<>Header</>}
                    rowKey={"acct"}
                    pagination={false}
                    columns={columns}
                    dataSource={suppliers}
                />
            </Card>
            <div className="shim" /><div className="shim" /><div className="shim" />
        </div>
    )
}