import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { webstore_search } from "../Webstore/webstore.js";

export default function WebstoreSearch(props) {

    const {setCurrent = () => {}} = props; 

    const history = useHistory(); 
    const [term, setTerm] = useState("");
    const [key, setKey] = useState("");
    const [results, setResults] = useState([]);

    const onSearch = (value) => {
        setKey(value);
        let _results = webstore_search.filter(item => item.heading.toLowerCase().includes(value.toLowerCase())).map(item => {
            return { value: item.key, label: <>{item.heading} <div><small style={{ "opacity": "0.5" }}>({item.section})</small></div></> }
        });
        setResults(_results);
    };


    const onSelect = (e) => {

        setKey("");
       let _find = webstore_search.find(item => item.key === e);
       if(_find){
        setCurrent(_find.section + "_section");
       }

        setTimeout(() => {
            let element = document.getElementById(e);
            if(element){
                let m = element.getBoundingClientRect();
                window.scrollTo({
                    top: m.y - 75,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }, 10)
       
    }



    return (
        <>
            {/* <div style={{"pointerEvents" : "none", "opacity" : "0.4"}}> */}
            <AutoComplete
                suffixIcon={<><SearchOutlined /></>}
                getPopupContainer={trigger => trigger.parentElement}
                onSelect={(e) => onSelect(e)}
                options={results}
                value={key}
                style={{ "width": "400px" }}
                size="small"
                onChange={(e) => onSearch(e)} 
                >

                <Input.Search size="small" placeholder="Search settings..." enterButton />

            </AutoComplete>
            {/* </div> */}


            {/* <AutoComplete
                dropdownClassName="tinyDropdown"
                placeholder="Search settings..."
                style={{ "width": "200px" }}
                size="small"
                options={[{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }]}
            /> */}
        </>
    )
}