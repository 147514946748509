import React from "react";
import { Typography, Divider, Row, Col, Space, Form, Input, Checkbox } from "antd";
export default function AdvancedSecion(props) {


    const { ws } = props;

    return (
        <>
            <div id="advanced" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Advanced</Typography.Title>
                <Divider />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Custom Header text */}
                        <div id="ws_head_custom_text"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5} >Custom head and body additions</Typography.Title>
                                <Typography.Paragraph type="secondary">These fields allow you to add JavaScript, CSS, etc. before the closing HTML "head" and "body" tags on your Webstore.<br /> Note: These settings apply to both mobile and desktop versions of the site.</Typography.Paragraph>
                                <Typography.Text><strong>Head:</strong></Typography.Text>
                                <div className="shim"></div>
                                <Form.Item name="ws_head_custom_text" initialValue={ws["ws_head_custom_text"]} noStyle >
                                    <Input.TextArea maxLength={1000} showCount style={{ "maxWidth": "500px" }} rows={3} ></Input.TextArea>
                                </Form.Item>
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                                <Typography.Text><strong>Body:</strong></Typography.Text>
                                <div className="shim"></div>
                                <Form.Item name="ws_body_custom_text" initialValue={ws["ws_body_custom_text"]} noStyle >
                                    <Input.TextArea maxLength={1000} showCount style={{ "maxWidth": "500px" }} rows={3} ></Input.TextArea>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>

                        {/* Custom Header text */}
                        <div id="ws_head_custom_text"></div>

                        <div style={{ "width": "35px" }}></div>
                        <div>
                            <Typography.Title level={5} >Custom footer fields</Typography.Title>
                            <Typography.Paragraph type="secondary">The website footer is generated from other webstore settings and the contact information we have attached to your main Bookmanager account. You can overide any specific field below.</Typography.Paragraph>


                            {/* Address */}
                            <Typography.Text><strong>Footer Message:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "message"]} initialValue={ws["ws_footer_data"]["message"]} noStyle >
                                        <Input.TextArea placeholder="Footer message..." style={{ "maxWidth": "500px" }} ></Input.TextArea>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "message"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["message"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div><div className="shim"></div>


                            {/* Address */}
                            <Typography.Text><strong>Address Line 1:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "address_1"]} initialValue={ws["ws_footer_data"]["address_1"]} noStyle >
                                        <Input placeholder="Address Line 1..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "address"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["address"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>
                            <Typography.Text><strong>Address Line 2:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "address_2"]} initialValue={ws["ws_footer_data"]["address_2"]} noStyle >
                                        <Input placeholder="Address Line 2..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                </Col>
                            </Row>

                            {/* Postal code */}
                            <div className="shim"></div>
                            <Typography.Text><strong>Postal code:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "postal"]} initialValue={ws["ws_footer_data"]["postal"]} noStyle >
                                        <Input placeholder="Postal code..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "postal_code"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["postal_code"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Province / State */}
                            <Typography.Text><strong>Province / State:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "province"]} initialValue={ws["ws_footer_data"]["province"]} noStyle >
                                        <Input placeholder="Province / State..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "province"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["province"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Province / State */}
                            <Typography.Text><strong>Country:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "country"]} initialValue={ws["ws_footer_data"]["country"]} noStyle >
                                        <Input placeholder="Country..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "country"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["country"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* City */}
                            <Typography.Text><strong>City:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "city"]} initialValue={ws["ws_footer_data"]["city"]} noStyle >
                                        <Input placeholder="City..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "city"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["city"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Email */}
                            <Typography.Text><strong>Email:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "email"]} initialValue={ws["ws_footer_data"]["email"]} noStyle >
                                        <Input placeholder="Email..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "email"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["email"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Phone */}
                            <Typography.Text><strong>Phone:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "phone"]} initialValue={ws["ws_footer_data"]["phone"]} noStyle >
                                        <Input placeholder="Phone..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "phone"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["phone"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Phone */}
                            <Typography.Text><strong>Phone 2:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "phone_2"]} initialValue={ws["ws_footer_data"]["phone_2"]} noStyle >
                                        <Input placeholder="Phone 2..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "phone_2"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["phone_2"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Toll Free */}
                            <Typography.Text><strong>Toll Free:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "phone_toll_free"]} initialValue={ws["ws_footer_data"]["phone_toll_free"]} noStyle >
                                        <Input placeholder="Toll Free..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "phone_toll_free"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["phone_toll_free"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Fax */}
                            <Typography.Text><strong>Fax:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "fax"]} initialValue={ws["ws_footer_data"]["fax"]} noStyle >
                                        <Input placeholder="Fax..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "fax"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["fax"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Fax */}
                            <Typography.Text><strong>Twitter URL:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "twitter_url"]} initialValue={ws["ws_footer_data"]["twitter_url"]} noStyle >
                                        <Input placeholder="Twitter URL..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "twitter"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["twitter"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Facebook */}
                            <Typography.Text><strong>Facebook URL:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "facebook_url"]} initialValue={ws["ws_footer_data"]["facebook_url"]} noStyle >
                                        <Input placeholder="Facebook URL..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "facebook"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["facebook"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>


                            {/* Facebook */}
                            <Typography.Text><strong>Instagram URL:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "instagram_url"]} initialValue={ws["ws_footer_data"]["instagram_url"]} noStyle >
                                        <Input placeholder="Instagram URL..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "instagram"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["instagram"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="shim"></div>

                            {/* Tiktok */}
                            <Typography.Text><strong>Tiktok URL:</strong></Typography.Text>
                            <div className="shim"></div>
                            <Row gutter={20}>
                                <Col flex={"auto"}>
                                    <Form.Item name={["ws_footer_data", "tiktok_url"]} initialValue={ws["ws_footer_data"]["tiktok_url"]} noStyle >
                                        <Input placeholder="Tiktok URL..." showCount style={{ "maxWidth": "500px" }} ></Input>
                                    </Form.Item>
                                </Col>
                                <Col flex={"120px"}>
                                    <Form.Item name={["footer_display_rules", "tiktok"]} valuePropName="checked" initialValue={ws["footer_display_rules"]["tiktok"]} noStyle >
                                        <Checkbox>Enabled?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>





                        </div>


                    </Col>
                </Row>

            </div>
        </>
    )
}