import { Input, Typography, Divider, Button, Space, Form, message } from "antd";
import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";
import { useState, React } from "react";

export default function ListNotes(props) {

    const { 
        close = () => {}, 
        owner_name = "",
        list_id = "", 
        note = "", 
        results=[], 
        setResults = () =>{}
    } = props;
    const [initNote, setInitNote] = useState();
    const [session, setSession] = useSession(); 

    const onSubmit = (_f) => {
        apiCall("titlelist/setNote", { list_id: list_id, note: _f["note"] }, (_status, _result) => {
            if(_status){
                let newArr = { ...results };
                let update = results.rows.find(item => item.list_id === _result.list_id);
                update.note = _result.note;
                newArr.rows[results.rows.findIndex(item => item.list_id === _result.list_id)] = update;
                setResults({ ...results, "rows": newArr.rows })
                if(_f["note"]){
                    message.success('Note added.');
                } else {
                    message.success('Note removed.');
                }
                close();
            }
        })
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Notes <span style={{ "display": "block", "fontWeight": "normal" }}><small>Notes are private to {owner_name}</small></span></Typography.Title>
                <Divider style={{ "margin": "15px 0px" }} />
                <Form onFinish={(_f) => onSubmit(_f)}>
                    <Form.Item initialValue={note} name="note" noStyle>
                        <Input.TextArea placeholder="Write note..." rows={6} />
                    </Form.Item>
                    <Divider style={{ "margin": "15px 0px" }} />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button onClick={() => onSubmit({note : ""})} danger type="primary">Delete</Button>
                            <Button type="primary" htmlType="submit">Save</Button>
                        </Space>
                    </div>
                </Form>
                <br clear="all" />
            </div>
        </>
    );
}