import { CloseOutlined, CommentOutlined, DeleteOutlined, ImportOutlined } from '@ant-design/icons';
import { Button, Checkbox, Layout, message, Popconfirm, Popover, Table, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import CommentMultiSelect from '../../components/admin/comments/CommentMultiSelect';
import Comment from "../../components/Comment";
import ImportComments from "../../components/ImportComments";
import ItemLayouts from "../../components/ItemLayouts";
import Overlay from '../../components/Overlay';
import Paginate from "../../components/Paginate";
import Renderer from "../../components/Renderer";
import Title from "../../components/title/Title";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import Html from "../../utils/Html";
import { parceAuthors, ucfirst } from "../../utils/Utils";
import { adjustColor, toggleMultiSelect } from "../../utils/Utils";

export default function ManageTitleComments() {

    const [results, setResults] = useState({ rows: [], total: 0 });
    const [expanded, setExpanded] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 20, offset: 0 });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [listIDs, setListIDs] = useState([]);

    const getComments = () => {
        setLoading(true);
        apiCall("comment/getAll", { limit: paginate.pagesize, offset: paginate.offset }, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
                setTableData(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getComments, [paginate]);

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const resetSelection = () => {
        if(selectedRowKeys.length > 0){
            setSelectedRowKeys([]);
            message.info("Successfully reset all selections!")
        }else{
            message.error("No selections were cleared, as no selections existed")
        }
    }
    
    const colors = {
        "personal": "#d1b3e5",
        "webstore": "#f47ddc",
        "clients": "#fa9050",
        "booksellers": "#90EE90",
        "staff": "#ffef00",
        "visitors": "#70e2ff",
        "everyone": "#def1f6",
        "unknown": "#bad4e1",
    }

    const drawIcon = (e) => {
        let tag_text = "";
        if(e.shared_with === "booksellers"){
            tag_text = <small>All Booksellers</small>
        }else if(e.shared_with === "personal") {
            tag_text = <small>Only Me</small>
        }else if(e.shared_with === "staff") {
            tag_text = <small style={{'color': 'black'}}>Staff</small>
        }else{
            tag_text = <small>{ucfirst(e.shared_with)}</small>
        }
        return (
            <>
                <Comment callback={getComments} data={e} isbn={e.isbn}>
                    <Popover overlayStyle={{ "maxWidth": "500px" }} placement="right" title={
                        <small>
                            <div style={{ "float": "right" }}>
                                &nbsp; &nbsp; &nbsp;
                                <Tag style={{ "lineHeight": "15px", "marginTop": "2px", "marginRight": "-8px" }} color={adjustColor(colors[e.shared_with], -20)}>
                                    {tag_text}
                                </Tag>
                            </div>{e.author}
                        </small>} content={<><Html html={e.text} />
                            <div className="shim"></div>
                            <small>{moment(e.date * 1000).format("MMMM Do, YYYY")}</small>
                        </>} trigger="hover">
                        <Button style={{ "background": adjustColor(colors[e.shared_with], -20), border: adjustColor(colors[e.shared_with], -60) }} icon={<CommentOutlined />} type="primary" size="small"></Button>
                    </Popover>
                </Comment>
            </>
        );
    }

    const onClickActions = (e, f) => {
        let temp_keys = toggleMultiSelect(f.id, e.target.checked, selectedRowKeys);
        setSelectedRowKeys(temp_keys);
        if(f.titlelist_id){
            let temp_ids = [...listIDs];
            if(e.target.checked){
                if(temp_ids.some((id) => id !== f.titlelist_id)){
                    temp_ids.push(f.titlelist_id)
                }
                setListIDs(temp_ids);
            }else{
                setListIDs(temp_ids.filter((id) => id !== f.titlelist_id))
            }
        }
    }

    const columns = [
        { 
            title: <small>Select</small>, align: "center", className: "cellBorder", width: "25px", render : (e, f) => {
                return (<Checkbox onClick={(e) => onClickActions(e, f)}/>)
            }
        },
        {
            title: <small></small>, className: "cellBorder", dataIndex: 'comment_action', width: "40px", key: 'comment_action', render: (e, f) => {
                return drawIcon(f);
            }
        },
        {
            title: <small>Posted by</small>, 
            dataIndex: 'author', 
            sorter: (a,b) => a.author.length - b.author.length, 
            key: 'author', 
            render: (e) => {
                return <div style={{ "maxWidth": "120px" }} className="ellipsis">{e}</div>
            }
        },
        {
            title: <small>List</small>, 
            dataIndex: 'list_name',
            sorter: (a,b) => a.list_name.length - b.list_name.length, 
            key: 'list_name', render: (e, f) => {
                return <div style={{ "maxWidth": "100px" }} className="ellipsis">{f.list_name}</div>
            }
        },
        {
            title: <small style={{ "minWidth": "100px" }}>Modified</small>, 
            dataIndex: 'modified', 
            key: 'modified', 
            sorter: (a,b) => a.modified - b.modified, 
            render: (e) => {
                return (e) ? moment(e * 1000).format("MMM D, YYYY") : "";
            }
        },
        {
            title: <small>Expiry</small>, 
            dataIndex: 'expiry', 
            key: 'expiry', 
            width: "73px", 
            sorter: (a,b) => a.expiry - b.expiry, 
            render: (e, f) => {
                if (e) {
                    return <div style={{ "maxWidth": "100px" }} className="ellipsis">{moment(e * 1000).format("MMM D, YYYY")}</div>
                } else {
                    return (f.list_name) ? "List" : "Never";
                }

            }
        },
        {
            title: <small>Title</small>, 
            dataIndex: 'title', 
            key: 'title', 
            sorter: (a,b) => a.title_info.title.length - b.title_info.title.length, 
            render: (e, f) => {
                return <a onClick={() => expandCell(f)}>{f.title_info.title}</a>
            }
        },
        {
            title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn'
        },
        // { title: <small>Author</small>, dataIndex: 'author', key: 'author', ellipsis: true, render: (e, f) => {
        //     return <a>{parceAuthors(f.title_info.authors)}</a>;
        // } },
        {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e, f) => {
                return (f.title_info.price) ? "$" + f.title_info.price : "";
            }
        },
        {
            title: <small>Bind</small>, dataIndex: 'binding', key: 'binding', render: (e, f) => {
                return (f.title_info.binding) ? f.title_info.binding : "";
            }
        },
        {
            title: <small>Pubdate</small>, 
            dataIndex: 'pubdate', 
            key: 'pubdate', 
            sorter: (a,b) => a.title_info.release_date - b.title_info.release_date, 
            render: (e, f) => {
                return <div style={{ "maxWidth": "120px" }} className="ellipsis">{moment(f.title_info.release_date * 1000).format("MMM D, YYYY")}</div>
            }
        },
        {
            title: <small>Publisher</small>, 
            className: "cellBorder", 
            dataIndex: 'publisher', 
            key: 'publisher', 
            sorter: (a,b) => a.title_info.publisher.length - b.title_info.publisher.length, 
            render: (e, f) => {
                return <div style={{ "maxWidth": "140px" }} className="ellipsis">{f.title_info.publisher}</div>
            }
        },
        // {
        //     title: <small></small>, dataIndex: 'import', className: "cellBorder", width: "40px", key: 'import', render: (e, f) => {
        //         return drawImport(f)
        //     }
        // },
        {
            title: <small></small>, dataIndex: 'actions', width: "40px", key: 'actions', render: (e, f) => {
                return (
                    <Tooltip title="Delete comment">
                        {drawRemove(f.id)}
                    </Tooltip>
                )
            }
        }
    ]


    const deleteComment = (_id) => {
        apiCall("comment/delete", { comment_id: _id }, (_status, _results) => {
            if (_status) {
                message.success("Comment removed successfully.")
                getComments();
            }
        })
    }

    const drawImport = () => {
        return (
            <ImportComments  getComments={getComments} >
                <Tooltip title="Import Comments">
                    <Button size="small" type="primary"><small><ImportOutlined /> Import</small></Button>
                </Tooltip>
            </ImportComments>

        )

    }

    const drawRemove = (_id) => {
        return (
            <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteComment(_id)}
                okText="Remove"
                okType="danger"
                cancelText="Cancel"
            >
                <Button danger type="link" size="small"><DeleteOutlined /></Button>
            </Popconfirm>
        )

    }


    const expandCell = (_data) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    const drawChildTable = (_record) => {
        return (
            <div className="hideHover" style={{ "margin": "-7px" }}>
                <div style={{ "padding": "0 20px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none", "marginBottom": "0px" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                </div>
                <div style={{ "padding": "20px 20px" }}>
                    <Renderer close={false}><ItemLayouts isbn={_record.isbn} data={_record.title_info} display="details2" /></Renderer>
                </div>
            </div>
        )
    }
    
    function chosenCommentsForMultiSelect(){
        let return_list = results.rows.filter((item)=>{
            if(selectedRowKeys.some((row) => row === item.id)){
                return item;
            }
        })
        return return_list;
    }

    function chosenNonListCommentsForMultiSelect(){
        let return_list = results.rows.filter((item)=>{
            if(selectedRowKeys.some((row) => row === item.id && item.titlelist_id === null)){
                return item;
            }
        })
        return return_list;
    }

    function chosenListCommentsForMultiSelect(){
        let return_list = results.rows.filter((item)=>{
            if(selectedRowKeys.some((row) => row === item.id && item.titlelist_id !== null)){
                return item;
            }
        })
        return return_list;
    }

    return (
        <>
            <Layout className="layout" >
                <LayoutHeader
                    title="Manage Comments"
                    description="Use this page to import and manage comments."
                    button={drawImport()}
                />
                <Layout.Content style={{"padding": "0px" }} >
                    <div style={{"padding": "10px"}}>
                        <Button disabled={selectedRowKeys.length === 0} type='primary'>
                            <Overlay
                                maskClosable={false}
                                width={900} 
                                component={
                                    <CommentMultiSelect
                                        drawIcon={drawIcon} 
                                        in_list
                                        all_comments={chosenCommentsForMultiSelect()}
                                        non_list_comments={chosenNonListCommentsForMultiSelect()}
                                        list_comments={chosenListCommentsForMultiSelect()}
                                        selectedRowKeys={selectedRowKeys}
                                        setSelectedRowKeys={setSelectedRowKeys}
                                        getComments={getComments}
                                    />
                                }
                            >Select Tools ({selectedRowKeys.length} selected)
                            </Overlay>
                        </Button>
                    </div>
                    <Paginate paginate={paginate} setPaginate={setPaginate} count={results.total} />
                    <Table 
                        loading={loading} 
                        size="small" 
                        pagination={false}
                        columns={columns}
                        dataSource={tableData.rows}
                        className='commentsTable'
                        expandable
                        expandedRowKeys={expanded}
                        expandIconColumnIndex={-1}
                        onExpand={(e) => { return false; }}
                        expandIcon={() => { return (<></>) }}
                        expandedRowRender={((record) => drawChildTable(record))}
                    />
                    <Paginate paginate={paginate} setPaginate={setPaginate} count={results.total} />
                </Layout.Content>
            </Layout>
        </>
    )
}