import { CloseOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";

import { apiCall } from "../../utils/Api";
import ItemLayouts from "../ItemLayouts";
import Renderer from "../Renderer";

export default function TitleRelated(props) {

    const { isbn, related_count = {
        previous_edition: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        next_edition: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        comparable_titles: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        included_items: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        included_in: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        other_languages: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        other_titles: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        }


    }, drawComponent = () => { }, isbns = false, header = "", title = "" } = props;

    const [results, setResults] = useState(false)
    const getRelatedEditions = (_key) => {
        if (!isbns) {
            if (results) {
                drawComponent(<TitleRelated drawComponent={drawComponent} header={<>{results[_key].short_name} for: <em>{title}</em></>} isbns={results[_key].isbns} />)
            }
            apiCall("title/getRelatedEditions", { isbn: isbn }, (_status, _result) => {
                if (_status) {
                    setResults(_result)
                    drawComponent(<TitleRelated drawComponent={drawComponent} header={<>{_result[_key].short_name} for: <em>{title}</em></>} isbns={_result[_key].isbns} />)
                }
            })
        }
    }

    const drawButtons = () => {
        return Object.keys(related_count).map((key, index) => {
            if (related_count[key].count > 0) {
                return <Button 
                            key={index} 
                            size="small" 
                            className="mini-btn" 
                            onClick={() => getRelatedEditions(key)} >
                            <small>
                                {related_count[key].short_name} ({related_count[key].count})
                            </small>
                        </Button>
            }
        })
    }


    const drawTitles = () => {
        return (
            <>
                <div className="closebar">
                    <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                    <div style={{ "padding": "8px 20px", "paddingBottom": "0px" }}>
                        <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                            <div>{header}</div>
                        </Typography.Title>
                    </div>
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                <div>
                    {isbns.map((item, index) => {
                        return (
                            <LazyLoad once={false}>
                                <div>
                                    <Renderer><ItemLayouts display="details2" isbn={item} index={index} /></Renderer>
                                    {(index !== (isbns.length - 1) && <div className="shim bc" style={{ "borderBottom": "2px solid", "margin": "25px -20px" }} />)}
                                </div>
                            </LazyLoad>
                        )
                    })}
                </div>
            </>
        )
    }
    if (isbns) {
        return (
            <>
                {drawTitles()}
            </>
        )
    } else {
        return (
            <>
                <Space size={3}>
                    {drawButtons()}
                </Space>
            </>
        );
    }
}