import { Button, Checkbox, Divider, Form, Input, message, Select, Space, Table, Typography } from "antd";
import React, { act, useState } from "react";

import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";

export default function SANListCreateEdit(props) {

    const { 
        results = [],
        create = false, 
        close = () => { }, 
        group_list_row_data = false,
        loading = false,
        getAllListsAndContacts = () => {},
    } = props;

    const [nonChosenStores, setNonChosenStores] = useState([]);
    const [chosenStores, setChosenStores] = useState([]);
    const [saving, setSaving] = useState(false);
    const [canSave, setCanSave] = useState(true);
    const [form] = Form.useForm()
    const [listName, setListName] = useState(!create ? group_list_row_data.name : "");
    const [selectedRowKeys, setSelectedRowKeys] = useState(group_list_row_data !== false  ? group_list_row_data.rows.map(store => store.id) : []);
    const [rowKeyCount, setRowKeyCount] = useState(group_list_row_data !== false  ? group_list_row_data.rows.length : 0);

    const onFinish = (form_values) => {
        setSaving(true);
        let args = {
            name: listName,
            contact_ids: JSON.stringify(selectedRowKeys)
        }
        if(!create){
            args.id = group_list_row_data.id
        }
        apiCall((create) ? "contact_list/create" : "contact_list/update", args, (_status, _result) => {
            if (_status) {
                getAllListsAndContacts()
                close();
            } else {
                message.error(_result.error)
            }
        })
        setSaving(false);
    }

    function toggleMultiSelect(id, checked){
        let tempSelectedKeys = [...selectedRowKeys]
        if(checked){
            tempSelectedKeys.push(id);
        }else{
            tempSelectedKeys.splice(tempSelectedKeys.indexOf(id), 1);
        }
        setSelectedRowKeys(tempSelectedKeys);
        setRowKeyCount(tempSelectedKeys.length)
    }

    function checkIfSelected(row_data){
        let is_selected = false;
        if(selectedRowKeys.some((row) => row === row_data.id) ){
            is_selected = true;
        }
        return is_selected;
    }

    const columns = [
        { 
            title: <small>Multi-Select</small>, width: "25px", render : (e, f) => {
                return <Checkbox checked={checkIfSelected(f)} onClick={(e) => toggleMultiSelect(f.id, e.target.checked)}/>
            }
        },
        {
            title: <small>SAN</small>, width: '80px', dataIndex: 'san', key: 'san', sorter: (a,b) => a.san.localeCompare(b.san),
        },
        {
            title: <small>Store Name</small>, dataIndex: 'name', key: 'name', sorter: (a,b) => a.name.localeCompare(b.name),
        },
        {
            title: <small>City</small>, width: '130px', dataIndex: 'city', key: 'city', sorter: (a,b) => a.city.localeCompare(b.city),
        },
        {
            title: <small>Province/State</small>, width: '40px', dataIndex: 'province', key: 'province', sorter: (a,b) => a.province.localeCompare(b.province),
        },
    ];

    const isSelected = (_id) =>{
        return (group_list_row_data.rows.some(store => store.id === _id)) ? 1 : 0;       
    }

    function closeModal(){
        getAllListsAndContacts();
        close()
    }

    function determineDataSourceOrder(){
        return !create ? results.active_stores.sort((a, b) => isSelected(b.id) - isSelected(a.id)): results.active_stores
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>{(create) ? "Create new" : "Edit"} Group List ({rowKeyCount} selected)</Typography.Title>
                <Divider />
                <Form form={form} onFinish={(e) => onFinish(e)} layout="vertical">
                    <Space size={20} align="start">
                        <Form.Item name="name" label="List name">
                            <Input defaultValue={!create ? group_list_row_data.name : ""} onChange={(e) => setListName(e.target.value)} placeholder="List name" style={{ "width": "300px" }} />
                        </Form.Item>
                    </Space>
                    <Table
                        rowKey="san"
                        size="small"
                        loading={loading}
                        pagination={true} 
                        columns={columns}
                        dataSource={determineDataSourceOrder()}
                    />
                    <Divider />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => closeModal()}>Cancel</Button>
                            <Button loading={saving} disabled={(!canSave)} htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </div>
        </>
    );
}