import React from "react";
import { Form, Typography, Divider, Input, Row, Col, Space, Radio, Checkbox, InputNumber } from "antd";
import WebstoreItem from "../WebstoreItem";
export default function PricingSection(props) {

    const { ws } = props;

    return (
        <>
            <div id="pricing" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Pricing</Typography.Title>
                <Divider />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Add strikethrough to list prices */}
                        <div id="ws_strikethrough"></div>
                        <WebstoreItem
                            name="ws_strikethrough"
                            value={(ws["ws_strikethrough"]) ? true : false}
                            heading="Add strikethrough to list prices"
                            description={<div>When checked, a strike-through will be displayed over the list price when the available sell price is lower than the list price. This will act as a visual cue to customers to help show they are not paying the higher list price.</div>}
                        />
                    </Col>

                    <Col md={24} xl={12}>
                        {/* Add strikethrough to list prices */}
                        <div id="ws_strikethrough"></div>
                        <WebstoreItem
                            name="using_promo_codes"
                            value={(ws["using_promo_codes"]) ? true : false}
                            heading="Enable sales promotions &amp; promo codes"
                            description={<div>When checked, the website will automatically add any sales and promotions as well as let visitors enter promo codes.</div>}
                        />
                    </Col>

                </Row>

                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Stock */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Stock</Typography.Title>
                                <Form.Item initialValue={ws["ws_show_qty"]} noStyle name="ws_show_qty">
                                    <Radio.Group>
                                        <Radio value={2}>Show stock quantities to customers</Radio>
                                        <div className="shim"></div>
                                        <Radio value={1}>Show 25+ if the quantity is over 25</Radio>
                                        <div className="shim"></div>
                                        <Radio value={0}>Don't show stock quantities <div style={{ "lineHeight": "12px", "color": "#f00" }}><small>If you enable this setting and hide stock quantities from your site, customers <strong><em>will</em></strong> be able to order more than what you have on hand or on order.</small></div></Radio>
                                        <div className="shim"></div>
                                        <Radio value={3}>Don't show stock quantities, In Store/Stock Now, or On Order indicators. Instead, all titles on hand, on order, and available to order will display 'Available to order' or 'Special Order'.</Radio>
                                        <div className="shim"></div>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                </Row>

                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Display list prices */}
                        <div id="ws_show_ca_us_list_prices"></div>
                        <WebstoreItem
                            name="ws_show_ca_us_list_prices"
                            value={(ws["ws_show_ca_us_list_prices"]) ? true : false}
                            heading="Display list prices"
                            description={<div>Enable if you would like to display either the US or Canadian list prices (or both) to your customers alongside your sell prices. List prices will be displayed while browsing and on the product details page. This option is usually for wholesalers or those selling below list price for the majority of their stock. <em>Not all titles have list prices provided to us from publishers.</em></div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Private webstore */}
                        <div id="ws_hide_sale_pricing"></div>
                        <WebstoreItem
                            name="ws_hide_sale_pricing"
                            value={(ws["ws_hide_sale_pricing"]) ? true : false}
                            heading="Hide sale pricing from product details page"
                            description={<div>Enable this to hide the automatic "You save" sale price comparison text that is displayed when viewing the product details page for items that have a sell price below your original / list price. Most stores should not enable this option, as letting customers know their savings for items that are selling below the original / list price is desired and draws attention to items on sale.</div>}
                        />
                    </Col>
                </Row>

                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={24}>
                        {/* Online price calculation for orders */}
                        <div id="ws_price_estimate_strategy"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Online price calculation for orders</Typography.Title>
                                <Typography.Paragraph type="secondary">Choose how pricing will be determined for product that is not On Hand but can be ordered. These rules are used to choose which vendor pricing and availability to use when there are multiple sources to choose from. For example, if a product is available from both a US and a Canadian source, both of which use different currency exchanges and markups, these rules determine which source will be quoted.</Typography.Paragraph>
                                <Form.Item initialValue={ws["ws_price_estimate_strategy"]} noStyle name="ws_price_estimate_strategy">
                                    <Radio.Group>
                                        <Radio value={0}>
                                            Least price aggressive
                                            <Typography.Paragraph type="secondary">Show the customer the lowest CAD list price, else lowest “after markup” price, and then the expected delivery time associated with the supplier having the lowest cost. Even if the product is available cheaper from a foreign source, your Canadian sources will be given priority.</Typography.Paragraph>
                                        </Radio>
                                        <div className="shim"></div>
                                        <Radio value={2}>
                                            More Price Aggressive
                                            <Typography.Paragraph type="secondary">Show the customer the lowest “after markup” price and the expected delivery time associated with the supplier having the lowest cost. Regardless of currency or source, your customer will be quoted the lowest price that conforms to your markup and profit margin rules set for that supplier.</Typography.Paragraph>
                                        </Radio>
                                        <div className="shim"></div>
                                        <Radio value={3}>
                                            Prioritize delivery time
                                            <Typography.Paragraph type="secondary">Show the customer the fastest delivery time and price associated with the fastest delivery time, which may result in a lower profit margin or a higher price. In most cases the fastest source will also be able to quote the lowest (or same) price, so this option may seem redundant. However, if a book is available from multiple sources and at different prices, costs and delivery times, you need to decide what to offer customers. Price is important but so is time. For example, a wholesaler may be able to deliver a book faster but at a short discount, which will result in a low margin and/or a higher price if you also markup prices to reflect a minimum margin. Use the fastest delivery time option if you consider speed to be the most important factor.</Typography.Paragraph>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/*Customer discounts */}
                        <div id="ws_online_discount"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Customer discounts</Typography.Title>
                                <Typography.Text><strong>Online discount </strong>
                                    <Form.Item initialValue={ws["ws_online_discount"]} noStyle name="ws_online_discount">
                                        <InputNumber formatter={(value) => parseInt(value) || 0} min={0} max={100} precision={0} />
                                    </Form.Item>
                                </Typography.Text>
                                <Typography.Paragraph type="secondary">The online price quoted for customers will use your selection from the Online Pricing option, less this discount, but only if the discounted price yields at least the minimum profit margin value. This setting is particularily useful if you have a special "Web-only" type of promotion.</Typography.Paragraph>
                                <Form.Item valuePropName='checked' initialValue={(ws["ws_cust_disc_flags"].discount_new) ? true : false} name={["ws_cust_disc_flags", "discount_new"]} noStyle>
                                    <Checkbox>Use customer's discount stored in Bookmanager for new books...
                                        <br />
                                        <Form.Item valuePropName='checked' initialValue={(ws["ws_cust_disc_flags"].discount_new_except_nt) ? true : false} name={["ws_cust_disc_flags", "discount_new_except_nt"]} noStyle>
                                            <Checkbox>except if the the title's discount is NT</Checkbox>
                                        </Form.Item>
                                    </Checkbox>
                                </Form.Item>
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                                <Form.Item valuePropName='checked' initialValue={(ws["ws_cust_disc_flags"].discount_non_new) ? true : false} name={["ws_cust_disc_flags", "discount_non_new"]} noStyle>
                                    <Checkbox>Use customer's discount stored in Bookmanager for non-new books with condition codes...
                                        <br />
                                        <Form.Item valuePropName='checked' initialValue={(ws["ws_cust_disc_flags"].discount_non_new_except_nt) ? true : false} name={["ws_cust_disc_flags", "discount_non_new_except_nt"]} noStyle>
                                            <Checkbox>except if the the title's discount is NT</Checkbox>
                                        </Form.Item>
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Minimum profit margin */}
                        <div id="ws_minimum_margin"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Minimum profit margin:&nbsp;
                                    <Form.Item initialValue={ws["ws_minimum_margin"]} noStyle name="ws_minimum_margin">
                                        <InputNumber max={100} precision={0} />
                                    </Form.Item>
                                </Typography.Title>
                                <Typography.Paragraph type="secondary">Each item's cost will be calculated to make sure the quoted price does not fall below this threshold.</Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Minimum Order */}
                        <div id="order_min"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Minimum order subtotal:&nbsp;
                                    <Form.Item initialValue={ws["order_min"]} noStyle name="order_min">
                                        <InputNumber precision={2} />
                                    </Form.Item>
                                </Typography.Title>
                                <Typography.Paragraph type="secondary">Checkout will be disabled if order subtotal does not meet or exceed this threshold.</Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>

                    </Col>
                </Row>



            </div>
        </>
    )
}