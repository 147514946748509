import { Button, Input, message, Modal, Radio, Space, Spin, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { settings } from "../../data";
import { apiCall, apiDownload } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { useSession } from "../../utils/Session";
import ListReportCardDetailedView from "./ListReportCardDetailedView";
import ListReportCardSimplifiedView from "./ListReportCardSimplifiedView";

export default function ListReportCard(props) {

    const { 
        close = () => { }, 
        actual_id = "",
        list_id = "",
        list_name = "",
        extra_step = false,
        setShowShareAccounts = () => {}
    } = props;
    const [reportCardData, setReportCardData] = useState({});
    const [detailedReportCardData, setDetailedReportCardData] = useState({});
    const [displayValue, setDisplayValue] = useState("simple");
    const [initLoaded, setInitLoaded] = useState(false);

    const getStartingValues = () => {
        apiCall("titlelist/missing_data/get", {titlelist_def_id: actual_id}, (_status, _result) => {
            if(_status){
                setReportCardData(_result)
                parseReportCardData(_result)
            }
        })
    }
    
    useEffect(getStartingValues, [])

    function parseReportCardData(api_results){
        let temp_data = [];
        let isbns_array = [];
        Object.values(api_results.missing_isbns).map((data_array_values) => {
            Object.keys(data_array_values).forEach((isbn) => {
                if(!isbns_array.includes(isbn)){
                    isbns_array.push(isbn);
                }
            })
        })
        isbns_array.forEach((isbn) => {
            let temp_object = {
                isbn: isbn,
                author_bios: false,
                covers: false,
                description: false,
                interiors: false,
                marketing: false,
                pubstock: false,
                related: false,
                reviews: false,
                titles: false
            }
            Object.entries(api_results.missing_isbns).forEach((data_array_values) => {
                Object.keys(data_array_values[1]).forEach((individual_value) => {
                    if(individual_value === isbn){
                        temp_object[data_array_values[0]] = true;
                    }
                })
                
            })
            temp_data.push(temp_object)
        })
        setDetailedReportCardData(temp_data);
        setInitLoaded(true);
    }

    function openBrowse(){
        window.open("/browse/filter/x/" + actual_id + "/v/sequence", "_blank");
    }
    
    const getExcelForReportCard = () => {
        apiDownload({"titlelist_def_id": list_id}, "titlelist/missing_data/getExcel")
    }

    return (
        <>
            {(!initLoaded && 
                <Spin spinning={!initLoaded}>
                    <div style={{ "width": "1310px", "margin": "0 auto", "height": "500px" }}></div>
                </Spin>
            )}
            {(initLoaded && <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Report Card for: {list_name}</Typography.Title>
                <span>
                    <Tooltip>
                        <Radio.Group defaultValue={"simple"} buttonStyle="solid" onChange={(e) => setDisplayValue(e.target.value)}>
                            <Radio.Button value="simple">Simplified View</Radio.Button>
                            <Radio.Button value="detail">Detailed View</Radio.Button>
                        </Radio.Group>
                    </Tooltip>
                </span>
                <div className="shim"/>
                <conditional.true value={displayValue === "simple"}>
                    <ListReportCardSimplifiedView
                        reportCardData={reportCardData}
                        />
                </conditional.true>
                <conditional.true value={displayValue === "detail"}>
                    <ListReportCardDetailedView
                        reportCardData={detailedReportCardData}
                        />
                </conditional.true>
                <div className="shim"/>
                <span>
                    <div style={{ "float": "left" }}>
                        <Button type="danger" onClick={() => close()}>Cancel</Button>
                    </div>
                    <div style={{"float": "right"}}>
                        <Space>
                            <Button onClick={() => getExcelForReportCard()}>Get Report Card Excel</Button>
                            <Button onClick={() => openBrowse()} type="primary">Open in Browse</Button>
                        </Space>
                    </div>
                </span>
                <br clear="all" />
            </div>)}
        </>
    )
}