import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Typography, Row, Collapse, Form, Input, Button, Popconfirm, message, Space, Empty, Spin, Menu, Dropdown, Tooltip } from "antd";
import { CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import Checkbox from "antd/lib/checkbox/Checkbox";
import Provider from "./Provider";
import { apiCall } from "../../utils/Api";
import { flattenObject, serialize } from "../../utils/Utils";
import AddRegion from "./AddRegion";

export default function Multiship(props) {

    const { form, setChanged = () => { } } = props;
    const [regions, setRegions] = useState(props.regions);
    const [visible, setVisible] = useState(false);
    const [presets, setPresets] = useState([])


    const getPresets = () => {
        apiCall("toolbox/get", { header: "status" }, (_status, _result) => {
            if (_status) {

                let arr = _result.store_specific.concat(_result.bookmanager)
                setPresets(arr.map(item => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                }))
            }
        })
    }

    useEffect(getPresets, [])


    const activeObj = (_arr) => {
        let _ret = {}
        _arr.forEach(item => {
            _ret[item.region] = 1;
        })
        return _ret;
    }

    const [active, setActive] = useState(activeObj(props.regions))

    const reduceArrayByKey = (arr) => {
        let uniqueValues = new Set();
        return arr.reduce((acc, curr) => {
            // create a temp object that excludes name, region for comparison
            let _compare = {
                disallowed: curr.disallowed,
                free: curr.free,
                providers: curr.providers,
                // add a synthetic key for poping out later
                inc: (curr.hasOwnProperty("inc")) ? curr.inc : 0

            }
            // compare objects using strings because JS isn't very good            
            if (!uniqueValues.has(serialize(_compare))) {
                uniqueValues.add(serialize(_compare));
                acc.push({
                    // Put all matched objects in a group
                    group: arr.filter(item => serialize({
                        // match temp object by removing name, region
                        disallowed: item.disallowed,
                        free: item.free,
                        providers: item.providers,
                        inc: (item.hasOwnProperty("inc")) ? item.inc : 0
                    }) === serialize(_compare)),
                    // since the data matches, any instance will work - add to the top level for convenience 
                    providers: curr.providers,
                    disallowed: curr.disallowed,
                    free: curr.free,
                    inc: curr.hasOwnProperty("inc") ? curr.inc : 0
                });
            }
            return acc;
        }, []);
    }

    const removeRegion = (_region) => {

        setChanged(true);
        form.setFieldsValue({ "ws_multiship_json": regions.filter(item => item.region !== _region) })
        setRegions(regions.filter(item => item.region !== _region));
    }


    const popRegion = (_region) => {

        // increment 
        _region.inc = (parseInt(_region.inc) + 1) || 1;
        let _all = [...regions];
        let _index = _all.findIndex(item => item.region === _region.region);
        if (_index > -1) {
            _all[_index] = _region;
            setRegions(_all)
        }

    }



    const updateGroup = (_group, _key, _value) => {

        setChanged(true);

        _group[_key] = _value;

        _group.group.forEach(element => {
            element[_key] = _value;
        });


        let _regions = [...regions];
        let _groupregions = _group.group.map(item => item.region)

        // loop through and update providers
        _regions.forEach(item => {
            if (_groupregions.includes(item.region)) {
                item[_key] = _group[_key]
            }
        })

        form.setFieldsValue({ "ws_multiship_json": _regions })
        setRegions(_regions);



    }



    const updateRegion = (_region, _key, _value) => {

        setChanged(true);

        let _arr = [...regions];
        let _ind = _arr.findIndex(item => item.region === _region);
        let _reg = _arr.find(item => item.region === _region);
        if (_reg) {
            _reg[_key] = _value;
            _arr[_ind] = _reg;
            form.setFieldsValue({ "multiship": JSON.stringify(_arr) })
            setRegions(_arr);
        }
    }


    const updateProviderGroup = (_group, _label, _key, _value) => {



        setChanged(true);
        // find the provider
        let ind = _group.providers.findIndex(item => item.label === _label);

        if (ind > -1) {
            // update provider
            _group.providers[ind][_key] = _value;
        } else {
            return false;
        }

        // copy regions, sample group's regions
        let _regions = [...regions];
        let _groupregions = _group.group.map(item => item.region)

        // loop through and update providers
        _regions.forEach(item => {
            if (_groupregions.includes(item.region)) {
                item.providers = _group.providers
            }
        })

        form.setFieldsValue({ "ws_multiship_json": _regions })
        
        setRegions(_regions);

    }

    const updateProvider = (_region, _provider, _key, _value) => {

        setChanged(true);

        if (_key !== "isbn" && isNaN(_value)) {
            _value = "0.00";
        }

        let _arr = [...regions];
        let _ind = _arr.findIndex(item => item.region === _region);
        let _reg = _arr.find(item => item.region === _region);
        if (_reg) {
            let _provInd = _reg.providers.find(item => item.label === _provider);
            let _prov = _reg.providers.find(item => item.label === _provider);
            if (_prov) {
                _prov[_key] = _value;
                _reg.providers[_provInd] = _prov;
                _arr[_ind] = _reg;
                form.setFieldsValue({ "multiship": JSON.stringify(_arr) })
                setRegions(_arr);
            }
        }
    }


    const removeProviderGroup = (e, _group, _provider) => {

        e.preventDefault();
        e.stopPropagation();
        setChanged(true);

        // remove from the group
        _group.providers = _group.providers.filter(item => item.label !== _provider);

        // copy regions, sample group's regions
        let _regions = [...regions];
        let _groupregions = _group.group.map(item => item.region)

        // loop through and update providers
        _regions.forEach(item => {
            if (_groupregions.includes(item.region)) {
                item.providers = _group.providers
            }
        })

        form.setFieldsValue({ "ws_multiship_json": _regions })
        setRegions(_regions);



    }

    const removeProvider = (e, _region, _providerLabel) => {
        e.preventDefault();
        e.stopPropagation();

        setChanged(true);
        let _arr = [...regions];
        let _ind = _arr.findIndex(item => item.region === _region);
        let _reg = _arr.find(item => item.region === _region);
        if (_reg) {
            _reg.providers = _reg.providers.filter(item => item.label !== _providerLabel);
            _arr[_ind] = _reg;
            form.setFieldsValue({ "multiship": JSON.stringify(_arr) })

            setActive({ ...active, [_region]: _reg.providers.length });

            setRegions(_arr);
        }
    }

    const drawRegions = () => {
        // Group if providers match and not the default
        let arr = reduceArrayByKey(regions.filter(item => item.region !== "--"))
        // add the default back in
        let _default = regions.find(item => item.region === "--");
        if (_default) {
            arr.unshift({
                group: [_default],
                disallowed: _default.disallowed,
                providers: _default.providers,
                free: _default.free,
                inc: _default.hasOwnProperty("inc") ? _default.inc : 0
            })
        }
        return arr.map((item, index) => drawGroup(item, index));
    }




    const addProviderToGroup = (_group, _provider) => {

        setChanged(true);

        // set up default object
        let obj = {
            label: _provider,
            cost_min: "0.00",
            cost_inc: "0.00",
            cost_max: "",
            min_free: "",
            min_days: "0",
            max_days: "0",
            filter_preset_id: "",
        }

        // add to providers
        _group.providers.push(obj);

        // copy regions, sample group's regions
        let _regions = [...regions];
        let _groupregions = _group.group.map(item => item.region)

        // loop through and update providers / set active key for new provider so it opens automatically
        let _pindex = 0;
        let _pcount = 0;
        _regions.forEach((item, index) => {
            if (_groupregions.includes(item.region)) {
                _pindex = index;
                _pcount = _group.providers.length; 
                item.providers = _group.providers
            }
        })


        setSel({...sel, ["p" + _pindex.toString()] : _pcount})

        form.setFieldsValue({ "ws_multiship_json": _regions })
        setRegions(_regions);






    }



    const addProvider = (_region, _provider) => {
        if (!_provider) {
            return;
        }

        setChanged(true);

        let _regions = [...regions];
        let _regIndex = _regions.findIndex(item => item.region === _region);
        let _reg = _regions.find(item => item.region === _region);

        if (_reg) {

            let _exists = _reg.providers.find(item => item.label === _provider);
            if (_exists) {
                message.error("Provider already exists.")
                return;
            } else {

                let obj = {
                    label: _provider,
                    cost_min: "0.00",
                    cost_inc: "0.00",
                    cost_max: "",
                    min_free: "",
                    min_days: "0",
                    max_days: "0",
                    filter_preset_id: "",
                }

                _reg.providers.push(obj);

                setActive({ ...active, [_region]: _reg.providers.length });
                _regions[_regIndex] = _reg;
                form.setFieldsValue({ "ws_multiship_json": _regions })
                setRegions(_regions);
                message.success("Provider added.");
                return;
            }
        }

    }


    const getUniqueProviderNames = () => {
        let _ret = [];
        regions.forEach(element => {
            element.providers.forEach(provider => {
                if (!_ret.includes(provider.label)) {
                    _ret.push(provider.label);
                }
            })
        });
        return _ret;
    }



    const [provider, setProvider] = useState({});

    const [sel, setSel] = useState([]);

    const drawGroup = (_group, _index) => {


        return (
            <Col key={_index} md={24} xl={12}>
                <Card style={{ "width": "455px", "minHeight": "335px" }}>
                    <div style={{ "minHeight": "372px" }}>
                        <Typography.Text>
                            <div style={{ "float": "left" }}>

                                {((_group.group.length > 1) &&

                                    <div style={{ "lineHeight": "12px" }}>
                                        <strong>
                                            <Space size={1} split={<>, &nbsp;</>} wrap>
                                                {_group.group.map(item => {
                                                    return (<a style={{ "cursor": "pointer" }} onClick={() => popRegion(item)}>{item.name}</a>)
                                                })}
                                            </Space>
                                        </strong>

                                        <div>
                                            <div className="shim" /><div className="shim" /><div className="shim" />
                                            <small style={{ "color": "#d13232" }}><em>Regions with idential settings are grouped - to separate, click on the region's name</em></small>
                                        </div>
                                    </div>
                                )}

                                {((_group.group.length === 1) && <><strong>
                                    {(_group.group[0].region === "--") ? "All other regions" : _group.group[0].name}

                                </strong><Popconfirm placement="right" title={"Delete region?"} onConfirm={() => removeRegion(_group.group[0].region)} okText="Yes" cancelText="No"><Button type="text" danger size="small"><small><DeleteOutlined /></small></Button></Popconfirm></>)}

                                {/* {(_region.region === "--") ? "All other regions" : _region.name} */}



                                {/* <Popconfirm placement="right" title={"Delete region?"} onConfirm={() => removeRegion(_region.region)} okText="Yes" cancelText="No"><Button type="text" danger size="small"><small><DeleteOutlined /></small></Button></Popconfirm> */}

                            </div>

                            <br clear="all" />
                            <div className="shim" /><div className="shim" /><div className="shim" />
                            <Space>
                                <Checkbox onChange={() => updateGroup(_group, "disallowed", !(_group.disallowed))} checked={(_group.disallowed) ? "checked" : ""}><small>Disallowed</small></Checkbox>
                                <Tooltip title={(_group.providers.length > 0) ? null : "Add a Provider first to enable this option"} >
                                    <Checkbox onChange={() => updateGroup(_group, "free", !(_group.free))} disabled={!(_group.providers.length > 0)} checked={(_group.free) ? "checked" : ""} ><small>Free shipping on all orders</small></Checkbox>
                                </Tooltip>
                            </Space>
                            <Divider dashed style={{ "marginTop": "10px", "marginBottom": "0px" }} />
                        </Typography.Text>
                        <Spin spinning={(_group.disallowed)} indicator={<></>}>
                            {/* {drawProviders(_group.providers, _group)} */}
                            <Providers index={_index} sel={sel} setSel={setSel} presets={presets} group={_group} providers={_group.providers} updateProviderGroup={updateProviderGroup} />
                        </Spin>
                    </div>
                    <Divider dashed style={{ "margin": "15px 0px" }} />
                    <div className="shim" />
                    <Dropdown overlay={<Menu>{getUniqueProviderNames().map(item => {
                        return <Menu.Item onClick={() => addProviderToGroup(_group, item)} key={item}><small>{item}</small></Menu.Item>
                    })}</Menu>}>
                        <Input.Search placeholder="Example: Media Mail, UPS Standard, FedEx Overnight, Standard Shipping" size="small" disabled={(_group.disallowed || _group.free)} allowClear onSearch={(e) => {
                            if (e) {
                                addProviderToGroup(_group, e);
                            }

                        }} enterButton={<small>Add Provider</small>} />
                    </Dropdown>

                </Card>
            </Col>
        )

    }


    


    const Providers = (props) => {
        const { providers = [], group = {}, updateProviderGroup = () => { }, sel = "", setSel=() => {}, index = "" } = props;

        // State for selected accordian item
        const [selected, setSelected] = useState((sel.hasOwnProperty("p"+index.toString())) ? sel["p"+index.toString()]: providers.length);

        // Automatically change selection if provider is added or deleted
        const check = () => { 
        
            if(provider.length>0 && providers.length !== selected){
                alert(providers.length)
              //  setSelected(providers.length); 
            }
            
            
        
        }
        useEffect(check, [providers])

        // Show empty message if there are no providers
        if (providers.length === 0) {
            return (<><br /><br /><Empty description="No providers" image={Empty.PRESENTED_IMAGE_SIMPLE} /><br /><br /></>)
        }

        return (
            <>
          
                <Collapse expandIcon={({ isActive }) => <CaretRightOutlined  className="c" rotate={isActive ? 90 : 0} />}  activeKey={selected}  onChange={(e) => setSel({...sel, ["p" + index.toString()] : e})} expandIconPosition="left" ghost accordion>
                    {providers.map((item, index) => {
                        return (
                            <Collapse.Panel key={(index + 1)} header={<div style={{ "paddingLeft": "5px", "marginTop": "-1px" }}>
                                <span className="c">{item.label}</span>&nbsp;
                                <Popconfirm onConfirm={(e) => removeProviderGroup(e, group, item.label)} placement="right" title={"Delete provider?"} okText="Yes" cancelText="No">
                                    <Button onClick={(e) => { e.stopPropagation(); e.preventDefault() }} type="link" danger size="small">
                                        <small><DeleteOutlined /></small>
                                    </Button>
                                </Popconfirm></div>}>
                                <Provider presets={presets} group={group} updateProvider={updateProviderGroup} data={item} />
                            </Collapse.Panel>
                        )
                    })}
                </Collapse>
            </>
        )


    }

    

    const updateRegions = (_regions = []) => {

        setChanged(true);

        let _arr = [...regions];
        let _count = 0;
        _regions.forEach(item => {
            let _code = item.region_code;
            let _name = item.name;
            if (!(_arr.find(i => i.region === _code))) {
                _arr.push({ region: _code, name: _name, disallowed: false, free: false, providers: [] });
                _count++;
            } else {

            }

        })

        if (_count > 0) {
            form.setFieldsValue({ "ws_multiship_json": _arr });
            setRegions(_arr);
            message.success("Region successfully added.")
        } else {
            message.error("Region(s) already exists.");
        }
    }

    const drawProvider = (_provider, _region) => {

        let {
            label = "",
            cost_min = "0.00",
            cost_inc = "0.00",
            cost_max = "0.00",
            min_free = "0.00",
            min_days = "0",
            max_days = "0",
            filter_preset_id = "",

        } = _provider;



        return (
            <Collapse.Panel header={<div style={{ "paddingLeft": "5px", "marginTop": "-1px" }}><span className="c">{label}</span>&nbsp;<Popconfirm placement="right" title={"Delete provider?"} onConfirm={(e) => removeProvider(e, _region.region, label)} okText="Yes" cancelText="No"><Button onClick={(e) => { e.stopPropagation(); e.preventDefault() }} type="link" danger size="small"><small><DeleteOutlined /></small></Button></Popconfirm></div>} key={label}>
                <div style={{ "textAlign": "left", "paddingLeft": "23px" }}>
                    <div className="shim"></div><div className="shim"></div>
                    <Typography.Text type="secondary">Minimum shipping cost <Form.Item noStyle ><Input onBlur={(e) => updateProvider(_region.region, label, "cost_min", e.target.value)} defaultValue={cost_min} size="small" prefix="$" style={{ "maxWidth": "70px" }}></Input></Form.Item></Typography.Text><br /><div className="shim"></div>
                    <Typography.Text type="secondary">Each additional item <Form.Item noStyle ><Input onBlur={(e) => updateProvider(_region.region, label, "cost_inc", e.target.value)} defaultValue={cost_inc} size="small" prefix="$" style={{ "maxWidth": "70px" }}></Input></Form.Item></Typography.Text><br /><div className="shim"></div>
                    <Typography.Text type="secondary">Maximum shipping cost <Form.Item noStyle ><Input onBlur={(e) => updateProvider(_region.region, label, "cost_max", e.target.value)} defaultValue={cost_max} size="small" prefix="$" style={{ "maxWidth": "70px" }}></Input></Form.Item></Typography.Text><br /><div className="shim"></div>
                    <Typography.Text type="secondary">Free shipping if total exceeds <Form.Item noStyle ><Input onBlur={(e) => updateProvider(_region.region, label, "min_free", e.target.value)} defaultValue={min_free} size="small" prefix="$" style={{ "maxWidth": "70px" }}></Input></Form.Item></Typography.Text><br /><div className="shim"></div>
                    <Typography.Text type="secondary">Orders arrive between <Form.Item noStyle ><Input onBlur={(e) => updateProvider(_region.region, label, "min_days", e.target.value)} defaultValue={min_days} size="small" style={{ "maxWidth": "35px" }}></Input></Form.Item> - <Form.Item noStyle ><Input onBlur={(e) => updateProvider(_region.region, label, "max_days", e.target.value)} defaultValue={max_days} size="small" style={{ "maxWidth": "35px" }}></Input></Form.Item> Day(s)</Typography.Text> <br /><div className="shim"></div>
                    <div className="shim"></div><div className="shim"></div>
                </div>
            </Collapse.Panel>
        )
    }

    return (
        <>

        
            <Row gutter={[30, 30]}>
                {drawRegions()}
            </Row>
            <br />
            
            <Typography.Text><strong>Add new region</strong> </Typography.Text>
            <div className="shim"></div>
            <Typography.Paragraph type="secondary">Add a new region by selecting a country. Provinces, states, and territories can only be selected for Canada and the US. Regions with the same Provider settings will be automatically grouped together.</Typography.Paragraph>
            <AddRegion show_message={false} setRegions={updateRegions} />
            <br /><br />
            
            <Form.Item hidden initialValue={regions} name="ws_multiship_json">
                <Input />
            </Form.Item>




    
        </>
    )
}