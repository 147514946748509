import React, { useState } from "react";
import { Row, Col, AutoComplete, message, Spin, Tooltip, Modal, Checkbox } from "antd";
import CategorySelector from "../CategorySelector";
import { getMemory, saveMemory } from "../../utils/Utils";
import { apiCall } from "../../utils/Api";
import Html from "../../utils/Html";
import conditional from "../../tools/conditional";
import Cookies from "js-cookie";
export default function BMNoteCats(props) {

    const { total_onhand = 0, isbn = "", condition = "", series = "", condition_code = "", data = {}, suggested_bm_data = { class_1: "", class_2: "" } } = props;
    const { notes = "", store = "" } = props.data;

    const [note, setNote] = useState(notes);
    const [category, setCategory] = useState((props.data.category) ? props.data.category : "");
    const [subCategory, setSubCategory] = useState((props.data.subcategory) ? props.data.subcategory : "");


    const saveCategoriesNotes = (_notes = note, _category = category, _subcategory = subCategory, skip_note = false) => {

        if (skip_note) {
            _notes = note;
        }

        let obj = {};
        obj.isbn = isbn;

        if (_category) {

            if (obj.notes !== null) {
                obj.notes = _notes;
            }

            obj.class_1 = _category;

            if (obj.class_2 !== null) {
                obj.class_2 = _subcategory;
            }

        }

        if (!_category) {
            obj.notes = "";
            obj.class_1 = ""
            obj.class_2 = ""
        }

        obj.condition_code = condition_code;

        let _san = "";
        if (store) {
            let _find = window.sitesettings.stores.find(item => item.store_number.toString() === store.toString());
            if (_find) {
                _san = _find.san;
            }
        } else {
            let _find = window.sitesettings.stores.find(item => item.is_primary);
            if (_find) {
                _san = _find.san;
            }
        }

        if (_san) {
            obj.multistore_san = _san;
        }



        apiCall("title/saveCategoriesNotes", obj, (_status, _result) => {
            if (_status) {

            } else {

                setNote(props.data.notes);
                setCategory((props.data.category) ? props.data.category : "")
                setSubCategory((props.data.subcategory) ? props.data.subcategory : "")

                let suppress = (_result.error === "Store needs real-time updates to be turned on. Please send a backup to confirm setting has changed.")

                // trap for specific error above (don't suppress others)
                if (Cookies.get("suppressNoteCatError") && suppress) {
                    return;
                }

                Modal.warn({
                    title: "Warning!", content:
                        <>
                            {(suppress && <>
                                <strong>Your Bookmanager software has real-time inventory updates turned off.</strong> Certain helpful functions on this site (like updating/adding inventory Notes and Class and having those instantly reflect in the software) require real-time inventory updates to be enabled. If your store network prevents you from enabling that feature, please contact Bookmanager support to troubleshoot the issue.
                            </>)}

                            {(!suppress && <>
                               {_result.error}
                            </>)}


                            <div className="shim" /><div className="shim" /><div className="shim" />

                            {/* only allow suppression of specific error */}
                            {(suppress && <>
                                <Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        Cookies.set("suppressNoteCatError", "true");
                                    } else {
                                        Cookies.set("suppressNoteCatError", "");
                                    }
                                }}>Don't show me this warning again.</Checkbox>

                            </>)}

                        </>
                })

            }
            setShowInput(false);
        })

    }


    const drawNotes = (_notes = "") => {


        if (!_notes) {
            return "";
        }

        let flags = ["^^", "//%", "//$", "//@", "//?", "//O"];

        let _split = _notes.split(" ");


        for (let i = 0; i < _split.length; i++) {
            flags.forEach(flag => {
                if (_split[i].substring(0, flag.length) == flag) {
                    _split[i] = "<span style='color:#f12a46;'>" + _split[i] + "</span>"
                }
            })
        }

        _notes = _split.join(" ");
        _notes = "<span class='c'>" + _notes + "</span>";

        return <div style={{ "lineHeight": "19px", "paddingLeft": "5px" }}><Html html={_notes} /></div>;

    }


    const [showInput, setShowInput] = useState(false);


    const getOptions = () => {

        let _arr = [];
        if (series) {
            _arr.push({ label: "Series", options: [{ value: series, label: <>{series}</> }] })
        }

        if (getMemory("inventory_note").length > 0) {
            _arr.push({
                label: "Recent",
                options: getMemory("inventory_note").map(val => {
                    return { value: val }
                })

            })
        }

        return _arr;
    }


    if ((total_onhand > 0)) {


        return (
            <Tooltip trigger="click" title={(total_onhand > 0) ? "This title's class cannot be changed online because there is stock on hand. Please make changes in the Bookmanager software." : ""}>
                <Spin wrapperClassName="spinwrapper" indicator={<></>} spinning={(total_onhand > 0)}>
                    <Row gutter={2}>
                        <Col flex={"auto"}>
                            <div>
                                <div className="c" style={{ "maxWidth": "240px", "fontSize": "11px", "marginTop": "4px", "overflow": "hidden", "paddingRight": "6px", "marginBottom": "-15px" }}><nobr>{drawNotes(note)} &nbsp;</nobr></div>
                                {/* <div className="bc bcg2" style={{ "border": "1px solid", "textAlign": "left", "height": "24px", "borderRadius": "3px", "padding": "2px", "fontSize": "11px" }}><nobr>{drawNotes(note)}</nobr></div> */}
                            </div>
                        </Col>
                        <Col style={{ "borderLeft": "1px solid" }} className="bc" flex={"175px"}>
                            <div className="c" style={{ "fontSize": "11px", "marginTop": "5px", "overflow": "hidden", "paddingLeft": "6px" }}><nobr>{category}{(subCategory) ? <>-{subCategory}</> : ""} &nbsp;</nobr></div>
                        </Col>
                    </Row>
                </Spin>
            </Tooltip>
        )


    }




    return (
        <>

            <div style={{ "opacity": ((total_onhand > 0)) ? "0.5" : "1", pointerEvents: ((total_onhand > 0)) ? "none" : "inherit" }}></div>
            <Row gutter={2}>
                <Col span={14}>
                    <div onMouseEnter={() => setShowInput(true)}>

                        <conditional.true value={(!showInput && note)}>
                            <div className="bc bcg2" style={{ "border": "1px solid", fontWeight : "bold", "textAlign": "left", "height": "24px", "borderRadius": "3px", "padding": "2px", "fontSize": "11px" }}><nobr>{drawNotes(note)}</nobr></div>
                        </conditional.true>

                        <conditional.true value={(showInput || !note)}>
                            <AutoComplete
                                value={(category && note) ? note : ""}
                                disabled={(!(category))}
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => setNote(e)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        saveCategoriesNotes(e.target.value);
                                    }
                                }}
                                onBlur={(e) => saveCategoriesNotes(e.target.value)}
                                placeholder={(category) ? "Inventory note..." : "Select class before adding a note..."}
                                style={{ "width": "100%", fontWeight : "bold", "fontSize": "11px", "height": "22px", "textAlign": "left", "marginTop": "0px", "border": "none" }}
                                dropdownClassName="tinyDropdown"
                                // className="noteSelector"
                                allowClear={true}
                                size="small"
                                options={getOptions()}
                            />
                        </conditional.true>
                    </div>
                </Col>

                <Col className="bc" span={10}>
                    <CategorySelector suggested_bm_data={suggested_bm_data} callback={saveCategoriesNotes} category={category} subCategory={subCategory} setCategory={setCategory} setSubCategory={setSubCategory} />
                </Col>

            </Row>

        </>
    )
}