import { DeleteOutlined } from '@ant-design/icons';
import { CaretDownOutlined, CaretUpOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Divider, Input, Layout, message, Modal, Row, Tabs, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";

export default function PermissionPage4() {

    const [session, setSession] = useSession();
    const [selectedUser, setSelectedUser] = useState({ id: "" });
    const [selectedPermission, setSelectedPermission] = useState("")
    const [results, setResults] = useState({ rows: [], permission_definitions: [],  role_definitions: []  });
    const [email, setEmail] = useState("");
    const history = useHistory();

    const [sort, setSort] = useState({ user: true, asc: true });


    const fetchPermissions = (_set = true, _user_email) => {
        apiCall("permissions/listAll", {}, (_status, _result) => {
            if (_status) {

                if (_set) {
                    if (_result.rows.length > 0) {
                        setSelectedUser(_result.rows[0]);
                    }
                }

                if(_user_email){
                    setSelectedUser(_result.find(item => item.email === _user_email));
                }

                setResults(_result);
            }
        });
    }

    useEffect(fetchPermissions, []);



    const drawHelp = () => {

        Modal.info({
            title: <>Help <p style={{ "display": "inline" }}><QuestionCircleFilled /></p></>,
            content: <>

                <p>Employees must first create a regular consumer account on your consumer webstore in order to be added here. On your consumer webstore, use the <strong className="c">Login</strong> box’s <strong className="c">Create an account</strong> button to create a customer account by email. Once that is done, log back into this area with admin access so you can add that email address.</p>

                <p>Select an employee from the left-hand menu, then enable or disable access to specific areas or data from the right-hand menu. Changes are saved automatically. You can also switch to a <strong className="c">Permissions View</strong> using the tab, where you can assign permissions en masse to multiple staff.</p>

                <p><em>Note: At least one employee must have the <strong className="c">Edit permissions</strong> attribute enabled. Only employees with that attribute are able to access and make changes to this area.</em></p>
            </>,
            icon: <></>
        })
    }


    const updateRole = (_field, _user = selectedUser) => {


        if (!_user.hasOwnProperty("id")) {
            return;
        }

        let user_roles = { ..._user.roles, [_field]: !_user.roles[_field] }
        setSelectedUser({ ..._user, "roles": user_roles });

        apiCall("permissions/set", { id: _user.id, permissions: JSON.stringify(_user.permissions), roles: JSON.stringify(user_roles) }, (_status, _result) => {
            if (_status) {
                fetchPermissions(false);
            } else {
                message.error(_result.error)
            }
        })

    }

    const updatePermission = (_field, _user = selectedUser) => {

        // can't remove your own edit capability
        if (_field === "relationships" && session.contact.email_address === selectedUser.email) {
            Modal.error({ title: "Error", content: "You can't remove your own edit permission." })
        }

        if (!_user.hasOwnProperty("id")) {
            return;
        }

        let user_perms = { ..._user.permissions, [_field]: !_user.permissions[_field] }
        setSelectedUser({ ..._user, "permissions": user_perms });

        apiCall("permissions/set", { id: _user.id, permissions: JSON.stringify(user_perms), roles: JSON.stringify(_user.roles) }, (_status, _result) => {
            if (_status) {
                fetchPermissions(false);
            } else {
                message.error(_result.error)
            }
        })

    }

    const PermissionBox = (props) => {

        let _checked = false;
        if (selectedUser.hasOwnProperty("permissions")) {
            if (selectedUser.permissions[props.data.name]) {
                _checked = true;
            }
        }

        return (
            <div>
                <Checkbox onChange={(e) => updatePermission(e.target.value)} value={props.data.name} checked={_checked}>
                    <Tooltip title={props.data.tooltip}>{props.data.alt_display}</Tooltip>
                </Checkbox>
                <div className="shim" />
            </div>
        )
    }

    const RolesBox = (props) => {

        let _checked = false;
        if (selectedUser.hasOwnProperty("roles")) {
            if (selectedUser.roles[props.data.name]) {
                _checked = true;
            }
        }

        return (
            <div>
                <Checkbox onChange={(e) => updateRole(e.target.value)} value={props.data.name} checked={_checked}>
                    <Tooltip title={props.data.tooltip}>{props.data.alt_display}</Tooltip>
                </Checkbox>
                <div className="shim" />
            </div>
        )
    }


    const UserPermission = (props) => {

        let _alt = props.index % 2;
        return (
            <>
                <div style={{ "cursor": "pointer" }} onClick={() => setSelectedUser(props.user)} key={props.user.id} className={(_alt) ? "odd" : "even"} >
                    <Row>
                        <Col flex={"240px"}>
                            <div style={{ "backgroundColor": (selectedUser.id === props.user.id) ? "#1e90ff" : "inherit", "padding": "3px 5px", "pointerEvents": "none" }}><span className={(selectedUser.id === props.user.id) ? "bw" : "c"} >{props.user.name}
                            &nbsp;{(((session.contact.email_address !== props.user.email) && !props.user.can_delete) && <div className="bc bcg2" style={{"display" : "inline", "borderRadius" : "2px", "border" : "1px solid", "padding" : "0px 5px", "marginLeft" : "5px", "fontSize" : "10px", "lineHeight" : "10px"}}>admin</div>)}
                            </span></div>
                        </Col>
                        <Col className="bc" style={{ "borderLeft": "1px solid" }} flex={"auto"}>
                            {((selectedUser.id === props.user.id && props.user.can_delete) &&
                                <div style={{ "float": "right", "marginRight": "2px", "marginTop": "2px" }}>
                                    <Button onClick={() => removeUser(props.user.id, props.user.name)} type="primary" danger size="small"><small><DeleteOutlined /> Remove</small></Button>
                                </div>
                            )}
                            <div style={{ "backgroundColor": (selectedUser.id === props.user.id) ? "#1e90ff" : "inherit", "padding": "3px 5px", "pointerEvents": "none" }}><span className={(selectedUser.id === props.user.id) ? "bw" : "c"} >&nbsp;{props.user.email}</span></div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };


    const removeUser = (_id, _name) => {


        let confirm = Modal.confirm({
            title: "Are you sure?", onOk: () => {
                apiCall("permissions/removeUser", { user_id: _id }, (_status, _result) => {
                    if (_status) {
                        setSelectedUser("");
                        setResults(_result);

                    } else {
                        message.error(_result.error)
                    }
                })

            }, content: 'This will remove the employee "' + _name + '" from "' + session.store_name
        })



    }

    const addUser = (_email) => {

        if (validateEmail(_email)) {

            apiCall("permissions/addUser", { user: _email }, (_status, _result) => {
                if (_status) {

                    setEmail("");
                    fetchPermissions(false, _result.email); 
                    Modal.success({ title: "Success", content: "Login permission successfully granted to: " + _result.name  + "." })
                    
                } else {
                    message.error(_result.error)
                }
            })

        } else {
            Modal.error({ title: "Invalid email", content: "Invalid email address provided." })
        }

    }

    const drawNoPermission = () => {

        return (
            <div className="bcg2" style={{ "width": "1000px", "margin": "0 auto", "padding": "20px 40px" }}>

                <p className="bw" style={{ "fontSize": "20px", "lineHeight": "30px" }}>Hello,</p>

                <p className="bw" style={{ "fontSize": "20px", "lineHeight": "30px" }}>You are trying to access <strong className="bw">Permissions.</strong></p>

                <p className="bw" style={{ "fontSize": "20px", "lineHeight": "30px" }}>Sorry, but your login, {session.contact.first_name} {session.contact.last_name} with {session.store_name} does not have access to this information.</p>

                <p className="bw" style={{ "fontSize": "20px", "lineHeight": "30px" }}>To Remedy this:<br />
                    a) you may need to have your access enabled through the Setup → Permissions menu<br />
                    b) this page may require a subscription (contact Bookmanager)<br />
                </p>

                <div onClick={() => history.push("/")} style={{ "width": "100%", "fontWeight": "600", "textAlign": "center", "fontSize": "20px", "color": "#fff", "backgroundColor": "#0842a0", "padding": "10px 20px" }}>
                    <span style={{ "cursor": "pointer" }}>Back to Main Page</span>
                </div>

            </div>
        );

    }


    const drawUsers = () => {

        if (!selectedPermission) {
            return (<></>);
        }

        return results.rows.sort((a, b) => {

            let _x = a.permissions[selectedPermission];
            let _y = b.permissions[selectedPermission];

            if(_x === _y){
                return a.name.localeCompare(b.name);
            } else {
                return _x? -1 : 1;
            }

          

          

        }).map((item, index) => {

            let _checked; 
            let is_role = false
            if(results.permission_definitions.find(item => item.name === selectedPermission)){
                _checked = item.permissions[selectedPermission];
            }

            if(results.role_definitions.find(item => item.name === selectedPermission)){
                _checked = item.roles[selectedPermission];
                is_role = true; 
            }

          

            return (
                <div>
                    <Checkbox onChange={() => (is_role) ? updateRole(selectedPermission, item) : updatePermission(selectedPermission, item)} checked={(_checked)} >{item.name}</Checkbox>
                    <div className="shim" />
                </div>
            )
        })
    }

    const drawPermissions = () => {
        return results.permission_definitions.map((item, index) => {
            return (
                <>
                    <Tooltip title={item.tooltip}><div style={{ "cursor": "pointer" }} onClick={() => setSelectedPermission(item.name)} className={(index % 2) ? "odd" : "even"} >
                    <div style={{ "backgroundColor": (selectedPermission === item.name) ? "#1e90ff" : "inherit", "padding": "3px 5px", "pointerEvents": "none" }}><span className={(selectedPermission === item.name) ? "bw" : "c"} >{item.alt_display}</span></div>
                    </div>
                    </Tooltip>
                </>
            )
        })
    }

    const drawRoles = () => {
        return results.role_definitions.map((item, index) => {
            return (
                <>
                    <Tooltip title={item.tooltip}><div style={{ "cursor": "pointer" }} onClick={() => setSelectedPermission(item.name)} className={(index % 2) ? "odd" : "even"} >
                    <div style={{ "backgroundColor": (selectedPermission === item.name) ? "#1e90ff" : "inherit", "padding": "3px 5px", "pointerEvents": "none" }}><span className={(selectedPermission === item.name) ? "bw" : "c"} >{item.alt_display}</span></div>
                    </div>
                    </Tooltip>
                </>
            )
        })
    }

    if (!session.permissions.edit) {
        drawNoPermission();
    }


    const drawSort = (_usr = false) => {

        return (
            <span style={{ "position": "relative", "top": "-3px" }} class="ant-table-column-sorter-inner">
                <span role="img" aria-label="caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up">
                    <small><CaretUpOutlined style={{"color" : ((sort.user ===_usr) && sort.asc) ? "#1e90ff" : "#777"}} /></small>
                </span><span role="img" aria-label="caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down">
                    <small><CaretDownOutlined style={{"color" : ((sort.user ===_usr) && !sort.asc) ? "#1e90ff" : "#777"}}  /></small>
                </span>
            </span>
        )
    }


    return (

        <Layout className="layout">

            <LayoutHeader
                title="Permissions"
                description={<>Add and remove employee access to this site as a whole, along with access to specific areas or sensitive information. &nbsp;<QuestionCircleFilled onClick={() => drawHelp()} style={{ "cursor": "pointer" }} />
                    <div className="shim" /><div className="shim" />
                    <Input.Search onSearch={(e) => addUser(e)} onChange={(e) => setEmail(e.target.value)} value={email} style={{ "width": "300px", "marginLeft": "-2px" }} size="small" placeholder="Add employee by email address..." enterButton={<>Add</>}></Input.Search>
                </>}

            />

            <div style={{ "padding": "20px" }}>

                <Tabs size="small" type="card" className="itemTabs shiftUp">
                    <Tabs.TabPane tab={<small>Employees view</small>} key="employees">
                        <Card style={{ "borderRadius": "0px" }} bodyStyle={{ "padding": "0px" }}>
                            <Row>
                                <Col span={12}>
                                    <Row>
                                        <Col flex={"245px"}>  <div onClick={() => setSort({ ...sort, "user": true, "asc": !sort.asc })} style={{ "cursor": "pointer" }} className="folders"><small>Employee name <small>&nbsp;
                                            {drawSort(true)}
                                        </small></small></div></Col>
                                        <Col flex={"auto"} className="bc" style={{ "borderLeft": "1px solid" }}>  <div onClick={() => setSort({ ...sort, "user": false, "asc": !sort.asc })} style={{ "cursor": "pointer" }} className="folders"><small>Email <small>&nbsp;
                                            {drawSort(false)}
                                        </small></small></div></Col>
                                    </Row>
                                </Col>
                                <Col className="bc" style={{ "borderLeft": "1px solid" }} span={12}>
                                    <div style={{"height" : "43px"}} className="folders">
                                        <small>{(selectedUser.hasOwnProperty("name") ? selectedUser.name : "User")}&nbsp; | &nbsp;<em>Permissions & Roles</em></small>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <div style={{ "padding": "0x", "paddingRight": "0px" }}>
                                        <div style={{ "overflowY": "auto", "maxHeight": "500px", "minHeight": "300px", "padding": "5px" }}>
                                            {results.rows.sort((a, b) => {
                                                if (sort.user) {

                                                    if (sort.asc) {
                                                        return a.name.localeCompare(b.name);
                                                    } else {
                                                        return b.name.localeCompare(a.name);
                                                    }

                                                } else {

                                                    if (sort.asc) {
                                                        return a.email.localeCompare(b.email);
                                                    } else {
                                                        return b.email.localeCompare(a.email);
                                                    }

                                                }
                                            }).map((user, index) => {
                                                return (<UserPermission index={index} user={user} />)
                                            })}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="bc" style={{ "borderLeft": "1px solid" }} span={12}>
                                    <div style={{ "padding": "20px" }}>
                                    <Divider orientation="left" style={{"margin" : "5px 0px"}} plain ><small>Permissions</small></Divider>
                                        {results.permission_definitions?.map((item, index) => {
                                            return <PermissionBox data={item} />
                                        })}
                                          <Divider orientation="left" style={{"margin" : "5px 0px"}} plain ><small>Roles</small></Divider>
                                        {results.role_definitions?.map((item, index) => {
                                            return <RolesBox data={item} />
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<small>Permissions view</small>} key="permissions">
                        <Card bodyStyle={{ "padding": "0px" }}>
                            <Row>
                                <Col span={12}>
                                    <div className="folders">
                                        <small>Permissions & Roles</small>
                                    </div>
                                </Col>
                                <Col className="bc" style={{ "borderLeft": "1px solid" }} span={12}>
                                    <div className="folders">
                                        <small>Employees with access</small>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <div style={{ "padding": "0x", "paddingRight": "0px" }}>
                                        <div style={{ "overflowY": "auto", "maxHeight": "500px", "minHeight": "300px", "padding": "5px" }}>
                                        <Divider orientation="left" style={{"margin" : "5px 0px"}} plain ><small>Permissions</small></Divider>
                                            {drawPermissions()}
                                            <Divider orientation="left" style={{"margin" : "5px 0px"}} plain ><small>Roles</small></Divider>
                                            {drawRoles()}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="bc" style={{ "borderLeft": "1px solid" }} span={12}>
                                    <div style={{ "padding": "20px" }}>
                                        {drawUsers()}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Tabs.TabPane>
                </Tabs>

            </div>
        </Layout>
    )

    
}