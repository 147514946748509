import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined, CaretUpOutlined, CloseOutlined, DownOutlined, DownSquareOutlined, UpOutlined, UpSquareOutlined } from '@ant-design/icons';
import { Button, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";

import conditional from '../../tools/conditional';
import { apiCall, apiDownload } from "../../utils/Api";
import Renderer from '../Renderer';
import InnerExpandedTable from "./InnerExpandedTable";

export default function PubdateMissingData(props){
    const {
        loading,
        setLoading = () => {},
        getDrilldown = () => {},
        supplier = "",
        group = "",
        pubdatePageData = {},
        isbnValues = {},
        dataValues = {},      
    } = props

    const [expanded, setExpanded] = useState([]);

    const columns = [
        {
            title: <small>Pubdate</small>,  width: '100px', dataIndex: 'pubdate', key: 'pubdate', render: (e, f) => {
                return <>
                <Space>
                    <span style={{"fontWeight": "800", "fontSize": "14px"}}>{f.pubdate}
                        <Button 
                            onClick={() => expandCell(f.pubdate)} 
                            size="small" 
                            type="text" 
                            icon={
                                <small>
                                    {/* Arrow 1 from GitLab */}
                                    {/* <conditional.true value={!expanded.includes(f.prefix)}>
                                        <UpOutlined style={{ "fontSize": "14px" }} />    
                                    </conditional.true>
                                    <conditional.true value={expanded.includes(f.prefix)}>
                                        <DownOutlined style={{ "fontSize": "14px" }} />   
                                    </conditional.true> */}

                                    {/* Arrow 2 from GitLab */}
                                    <conditional.true value={expanded.includes(f.pubdate)}>
                                        <CaretUpOutlined style={{ "fontSize": "14px" }} />    
                                    </conditional.true>
                                    <conditional.true value={!expanded.includes(f.pubdate)}>
                                        <CaretDownOutlined style={{ "fontSize": "14px" }} />   
                                    </conditional.true>

                                    {/* Arrow 4 from GitLab */}
                                    {/* <conditional.true value={!expanded.includes(f.prefix)}>
                                        <UpSquareOutlined style={{ "fontSize": "14px" }} />    
                                    </conditional.true>
                                    <conditional.true value={expanded.includes(f.prefix)}>
                                        <DownSquareOutlined style={{ "fontSize": "14px" }} />   
                                    </conditional.true> */}
                                </small>
                            } 
                        /> 
                    </span>
                </Space>
                </>
            }
        },
    ]

    //all isbn values
    if(isbnValues.all_isbns){
        columns.push({
            title: <small>All ISBNs</small>, width: "50px", dataIndex: 'all_isbns', key:'all_isbns',  render: (e, f) => {
                return <span className="isbnCount" style={{ "cursor": "pointer"}} onClick={() => getDrilldown(f.pubdate, group, "all_isbns")}>{f.all_isbns}</span>
            }
        })
    }

    //ranked isbn values
    if(isbnValues.rank_isbns){
        columns.push({
            title: <small>Rank ISBNs</small>, width: "50px", dataIndex: 'rank_isbns', key:'rank_isbns',  render: (e, f) => {
                return <span className="isbnCount" style={{ "cursor": "pointer"}} onClick={() => getDrilldown(f.pubdate, group, "rank_isbns")}>{f.rank_isbns}</span>
            }
        })
    }

    //nyr isbn values
    if(isbnValues.nyr_isbns){
        columns.push({
            title: <small>NYR ISBNs</small>, width: "50px", dataIndex: 'nyr_isbns', key:'nyr_isbns',  render: (e, f) => {
                return <span className="isbnCount" style={{ "cursor": "pointer"}} onClick={() => getDrilldown(f.pubdate, group, "nyr_isbns")}>{f.nyr_isbns}</span>
            }
        })
    }

    //oh isbn values
    if(isbnValues.oh_isbns){
        columns.push({
            title: <small>OH ISBNs</small>, width: "50px", dataIndex: 'oh_isbns', key:'oh_isbns',  render: (e, f) => {
                return <span className="isbnCount" style={{ "cursor": "pointer"}} onClick={() => getDrilldown(f.pubdate, group, "oh_isbns")}>{f.oh_isbns}</span>
            }
        })
    }

    //oo isbn values
    if(isbnValues.oo_isbns){
        columns.push({
            title: <small>OO ISBNs</small>, width: "50px", dataIndex: 'oo_isbns', key:'oo_isbns',  render: (e, f) => {
                return <span className="isbnCount" style={{ "cursor": "pointer"}} onClick={() => getDrilldown(f.pubdate, group, "oo_isbns")}>{f.oo_isbns}</span>
            }
        })
    }

    //ito isbn values
    if(isbnValues.ito_isbns){
        columns.push({
            title: <small>ITO ISBNs</small>, width: "50px", dataIndex: 'ito_isbns', key:'ito_isbns',  render: (e, f) => {
                return <span className="isbnCount" style={{ "cursor": "pointer"}} onClick={() => getDrilldown(f.pubdate, group, "ito_isbns")}>{f.ito_isbns}</span>
            }
        })  
    }

    const drawChildTable = (record) => {
        return (
            <>
                <div className="hideHover" style={{ "margin": "-7px" }}>
                    <div style={{ "padding": "0px 20px" }}>
                        <Renderer close={false}><InnerExpandedTable
                            record={record}
                            isbnValues={isbnValues}
                            dataValues={dataValues}
                            setLoading={setLoading}
                            getDrilldown={getDrilldown}
                            supplier={supplier}
                            group={group}
                            loading={loading}
                        ></InnerExpandedTable></Renderer>
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    const expandCell = (_data, _index) => {
        if (!expanded.includes(_data)) {
            setExpanded([_data]);
        } else {
            setExpanded([]);
        }
    }

    const expandable = {
        rowExpandable: (record) => expanded.find((element) => element === record.pubdate),
        expandedRowKeys: expanded,
        expandIconColumnIndex: -1,
        onExpand: (e) => {return false},
        expandIcon: <></>,
        expandedRowRender: (record) => drawChildTable(record)
    };

    const alternateRowColours = (record, index) => {
        if(index%2 === 1){
            return "evenRowColour"
        }
    }

    return(
        <>
            <div style={{ "padding": "0px 0px" }}>
                <div style={{ "margin": "0 0px", "width": "100%" }}>
                    <Table
                        scroll={{x: 'max-content', y: '630px'}}
                        size="small"
                        loading={loading}
                        columns={columns}
                        dataSource={pubdatePageData}
                        rowKey={(record)=> record.pubdate}
                        className="missingDataTable"
                        // rowClassName={(record, index) => alternateRowColours(record, index)}
                        pagination={{
                            defaultPageSize: 50,
                            showSizeChanger: true,
                            position: ['topRight', 'bottomRight'],
                        }}
                        expandable={expandable}
                    />
                </div>
            </div>
        </>
    )
}
