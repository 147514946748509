import { Button, Divider, Form, Input, Space, Table, Typography } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";

import { apiCall } from "../../utils/Api";

export default function IndividualAccountHistoryModal(props) {

    let {
        contact_id = 0,
        close = () => { }, 
    } = props;

    const [contactHistory, setContactHistory] = useState([]);

    const columns = [
        { 
            title: <small>Status</small>, 
            key: 'status', 
            dataIndex: 'status', 
            render: (e,f) => {
                if(e === "approved_inactive"){
                    return "Waiting on approval to connect"
                }else if(e === "deleted"){
                    return "Connection was deleted"
                }else if(e === "declined"){
                    return "Connection was declined"
                }else if(e === "rerequested"){
                    return "Request to see data was resent"
                }else if(e === "created"){
                    return "Connection was created"
                }else if(e === "requested"){
                    return "Request to see data was sent"
                }else if(e === "approved"){
                    return "Request to see data was approved"
                }
            }
        },
        { 
            title: <small>Date Changed</small>, 
            key: 'date_changed', 
            dataIndex: 'date_changed', 
            render: (e,f) => {
                return moment(e).format("MMMDD-YY")
            } 
        },
    ];

    const getHistoryData = () => {
        let contact_history = [];
        apiCall("rep_control/getContactHistory", { contact_id: contact_id }, (_status, _result) => {
            if (_status) {
                contact_history = _result
                setContactHistory(contact_history)
            }
        });
    }

    useEffect(getHistoryData, []);

    function closeModal(){
        close();
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>History of Interactions</Typography.Title>
                <Divider />
                <Table 
                    size='small'
                    dataSource={contactHistory}
                    columns={columns}
                    />
                <Divider />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => closeModal()}>Close History</Button>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        </>
    );
}