import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, message, Modal, Radio, Select, Space, Spin, Table, Tabs, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { settings } from "../../data";
import { apiCall, apiDownload } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { useSession } from "../../utils/Session";
import Overlay from "../Overlay";
import ListReportCard from "./ListReportCard";
import ListReportCardDetailedView from "./ListReportCardDetailedView";
import ListReportCardSimplifiedView from "./ListReportCardSimplifiedView";
import ListShareAccountsTab from "./ListShareAccountsTab";
import ListShareGroupTab from "./ListShareGroupTab";
import ListSharePublicTab from "./ListSharePublicTab";
import ListShareSingleAccount from "./ListShareSingleAccount";

export default function ListAccountShare(props) {

    const { 
        list = {},
        close = () => { }, 
        list_id = "",
        presetSANLists = [], 
        actual_id = "",
        name = "",
        getCatalogues = () => {},
        setShowAccounts = () => {},
        getSharedWithAccounts = () => {},
    } = props;

    const [loading, setLoading] = useState(false);
    const [chosenList, setChosenList] = useState("");
    const [shareableStores, setShareableStores] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showShareAccounts, setShowShareAccounts] = useState(false);
    const [reportCardData, setReportCardData] = useState({});
    const [detailedReportCardData, setDetailedReportCardData] = useState({});
    const [displayValue, setDisplayValue] = useState("simple");
    const [initLoaded, setInitLoaded] = useState(false);
    const [position, setPosition] = useState("account");

    const setShared = (set_public, store_ids = true, keys = false) => {
        let args = {
            list_id: list_id,
        }
        if(set_public){
            args.publish_to_everyone = set_public;
        }
        if(store_ids){
            args.store_ids_json = JSON.stringify(selectedRowKeys);
        }

        if(keys){
            args.store_ids_json = JSON.stringify(keys);
        }

        

        apiCall("titlelist/setPublished", args, (_status, _result) => {
            if(_status){

                if(keys){
                    message.success("You shared prefrences have been updated.");
                } else
                if(!set_public){
                    message.success("You have unshared this catalogue.");
                }else{
                    message.success("Your catalogue has been published!");
                }
                
            }
            setShowAccounts(false);
            getSharedWithAccounts();
            close();
        })
    }

    const getStartingValues = () => {
        setLoading(true)
        apiCall("titlelist/getShareableStores", {list_id: list_id}, (_status, _result) => {
            if(_status){
                setShareableStores(_result.rows)
            }
        })
        apiCall("titlelist/missing_data/get", {titlelist_def_id: actual_id}, (_status, _result) => {
            if(_status){
                setReportCardData(_result)
                parseReportCardData(_result)
            }
        })
        setLoading(false);
    }
    
    useEffect(getStartingValues, [])

    function parseReportCardData(api_results){
        let temp_data = [];
        let isbns_array = [];
        Object.values(api_results.missing_isbns).map((data_array_values) => {
            Object.keys(data_array_values).forEach((isbn) => {
                if(!isbns_array.includes(isbn)){
                    isbns_array.push(isbn);
                }
            })
        })
        isbns_array.forEach((isbn) => {
            let temp_object = {
                isbn: isbn,
                author_bios: false,
                covers: false,
                description: false,
                interiors: false,
                marketing: false,
                pubstock: false,
                related: false,
                reviews: false,
                titles: false
            }
            Object.entries(api_results.missing_isbns).forEach((data_array_values) => {
                Object.keys(data_array_values[1]).forEach((individual_value) => {
                    if(individual_value === isbn){
                        temp_object[data_array_values[0]] = true;
                    }
                })
                
            })
            temp_data.push(temp_object)
        })
        setDetailedReportCardData(temp_data);
        setInitLoaded(true);
    }

    function openBrowse(){
        window.open("/browse/filter/x/" + list_id + "/v/sequence", "_blank");
    }

    function changeTabs(position) {
        setPosition(position);
        setChosenList("");
    }

    const drawReturnToReportCardButton = () => {
        return (
            <Button onClick={() => setShowShareAccounts(false)}><LeftOutlined/> Return to Report Card</Button>
        )
    }
    const getExcelForReportCard = () => {
        apiDownload({"titlelist_def_id": list_id}, "titlelist/missing_data/getExcel")
    }

    const initialReportCardCheck = () => {
        return (
            <>
                {(!initLoaded && 
                    <Spin spinning={!initLoaded}>
                        <div style={{ "width": "1310px", "margin": "0 auto", "height": "500px" }}></div>
                    </Spin>
                )}
                {(initLoaded && <div style={{ "padding": "20px" }}>
                    <Typography.Title level={5}>Report Card for: {name}</Typography.Title>
                    <span>
                        <Tooltip>
                            <Radio.Group defaultValue={"simple"} buttonStyle="solid" onChange={(e) => setDisplayValue(e.target.value)}>
                                <Radio.Button value="simple">Simplified View</Radio.Button>
                                <Radio.Button value="detail">Detailed View</Radio.Button>
                            </Radio.Group>
                        </Tooltip>
                    </span>
                    <div className="shim"/>
                    <conditional.true value={displayValue === "simple"}>
                        <ListReportCardSimplifiedView
                            reportCardData={reportCardData}
                            />
                    </conditional.true>
                    <conditional.true value={displayValue === "detail"}>
                        <ListReportCardDetailedView
                            reportCardData={detailedReportCardData}
                            />
                    </conditional.true>
                    <div className="shim"/>
                    <span>
                        <div style={{ "float": "left" }}>
                            <Button type="danger" onClick={() => close()}>Cancel</Button>
                        </div>
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => getExcelForReportCard()}>Get Report Card Excel</Button>
                                <Button onClick={(e) => openBrowse()}>Open in Browse</Button>
                                <Button onClick={(e) => setShowShareAccounts(true)} type="primary">Continue to Share <RightOutlined/></Button>
                            </Space>
                        </div>
                    </span>
                    <br clear="all" />
                </div>)}
            </>
        )
    }

    const shareModalShowup = () => {
        return (
            <>
                    <Tabs 
                        size="large" 
                        className="itemTabs"  
                        onChange={(e) => changeTabs(e)} 
                        defaultActiveKey="0"
                        activeKey={position} 
                        type="card" 
                        style={{"padding" : "5px"}}
                    >
                        {/* <Tabs.TabPane tab="Share with Single Account" key="single">
                            <ListShareSingleAccount
                                close={close}
                                loading={loading}
                                shareableStores={shareableStores}
                                getStartingValues={getStartingValues}
                                setShared={setShared}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                drawReturnToReportCardButton={drawReturnToReportCardButton}
                            />
                        </Tabs.TabPane> */}
                        <Tabs.TabPane tab="Share with Accounts" key="account">
                            <ListShareAccountsTab
                                list={list}
                                close={close}
                                loading={loading}
                                shareableStores={shareableStores}
                                setShareableStores={setShareableStores}
                                getStartingValues={getStartingValues}
                                setShared={setShared}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                drawReturnToReportCardButton={drawReturnToReportCardButton}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Share with Group" key="group">
                            <ListShareGroupTab 
                                close={close}
                                presetSANLists={presetSANLists}
                                loading={loading}
                                chosenList={chosenList}
                                setChosenList={setChosenList}
                                shareableStores={shareableStores}
                                getStartingValues={getStartingValues}
                                setShared={setShared}
                                selectedRowKeys={selectedRowKeys}
                                setSelectedRowKeys={setSelectedRowKeys}
                                drawReturnToReportCardButton={drawReturnToReportCardButton}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Share Publicly" key="public">
                            <ListSharePublicTab
                                list={list} 
                                close={close}
                                setShared={setShared}
                                drawReturnToReportCardButton={drawReturnToReportCardButton}
                            />
                        </Tabs.TabPane>
                    </Tabs>
            </>
        )
    }

    return (
        <>
            <conditional.true value={!showShareAccounts}>
                {initialReportCardCheck()}
            </conditional.true>
            <conditional.true value={showShareAccounts}>
                {shareModalShowup()}
            </conditional.true>
        </>
    )
}