import { Button, Checkbox, Input, message, Modal, Radio, Select, Space, Table, Tooltip, Typography } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import { settings } from "../../data";
import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";
import { writeSharedReadable } from "../../utils/Utils";
import ListMSEmail from "./ListMultiSelectOptions/ListMSEmail";

export default function ListMultiSelect(props) {

    const { 
        catalogues = [],
        close = () => { }, 
        selectedRowKeys = [], 
    } = props;

    const [loading, setLoading] = useState(false);
    const [displayMenuOption, setDisplayMenuOption] = useState("email");
    const [saveButtonText, setSaveButtonText] = useState("");
    const [apiCallText, setApiCallText] = useState();
    const [apiArgs, setApiArgs] = useState();

    // const onRadioButtonSwitch = () => {
    //     //expandable for later, if we think of other good ideas
    //     switch (displayMenuOption) {
    //         case "email":
    //             return (<>
    //                 <ListMSEmail
    //                     setApiArgs={setApiArgs}
    //                     setApiCallText={setApiCallText}
    //                     selectedRowKeys={selectedRowKeys}
    //                     setSaveButtonText={setSaveButtonText}
    //                     />
    //                 </>);
    //         default:
    //             return (
    //                 <div>
    //                     You shouldn't be here, refresh the page maybe?
    //                 </div>
    //             )
    //     } 
    // }

    const columns = [
        {
            title: "Catalogue Name", dataIndex: 'name', key: 'name'
        },
        {
            title: "Total Titles", dataIndex: 'isbns_count', key: 'isbns_count'
        },
        {
            title: "Shared Status", dataIndex: 'shared_status', key: 'shared_status', render: (e, f) => {
                return (
                    <div>
                        {writeSharedReadable(f.shared_status)}
                    </div>
                )
            }
        },
        {
            title: "Last Updated", dataIndex: 'date_updated', key: 'date_updated', render: (e, f) => {
                return (
                    <>
                        <div><nobr>{moment(e * 1000).format("MMMDD-YY")}</nobr></div>
                    </>
                )
            }
        }
    ];

    //commented code below is temporarily hidden until more options get added here
    return (
        <>
            <div style={{ "padding": "20px" }}>
                {/* <Typography.Title level={5}>Select Menu</Typography.Title> */}
                {/* <Radio.Group defaultValue={displayMenuOption} buttonStyle="solid" onChange={(e) => setDisplayMenuOption(e.target.value)}>
                    <Radio.Button value="email">Email</Radio.Button>
                </Radio.Group> */}
                {/* <div style={{"marginTop": "20px"}}> */}
                <div>
                    <Typography.Title level={5}>{selectedRowKeys.length} catalogues selected</Typography.Title>
                    <Table
                        size="small"
                        dataSource={catalogues}
                        columns={columns}>
                    </Table>
                    {/* {onRadioButtonSwitch()} */}
                    {/* The lower component ListMSEmail should be removed when we implement other options */}
                    <ListMSEmail
                        setApiArgs={setApiArgs}
                        setApiCallText={setApiCallText}
                        selectedRowKeys={selectedRowKeys}
                        setSaveButtonText={setSaveButtonText}
                    />
                </div>
                <div style={{ "float": "left" }}>
                    <Space>
                        <Button type="danger" onClick={() => close()}>Cancel</Button>
                    </Space>
                </div>                
                <br clear="all" />
            </div>
        </>
    )
}