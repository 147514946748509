import { DownOutlined } from '@ant-design/icons';
import { Divider, message, Select, Space } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional";
import { useSession } from "../utils/Session";

export default function SelectStores(props) {
    const [session, setSession] = useSession();
    const [suggestedCarts, setSuggestedCarts] = useState([]);
    const [scCount, setSCCount] = useState(0);
    const [initLoaded, setInitLoaded] = useState(false);
    const [sharedStores, setSharedStores] = useState([]);

    //DO NOT DELETE following stores const. It is used for testing. Comment out when not testing
    // const stores = [
    //     {name: "Test000", contact_id: "B0V8CUuY7XY"}, 
    //     {name: "Mosaic books", contact_id: "PlzHTPKhH6g"}, 
    //     {name: "Black Bond Books", contact_id: "MWt6BvanZrc"}, 
    //     {name: "Black Bond Books #3", contact_id: "GJTD4-Oo84M"}, 
    //     {name: "Black Bond Books #4", contact_id: "9twlzaPJDaE"},
    //     {name: "Black Bond Books #6", contact_id: "pYYLp2jVCVw"}
    // ]

    //Likewise, this is live code that can be used when not testing
    
    const getSharedStores = () => {
        let api_object = {
            my_accounts: true,
            approved: false,
            pending: false,
            declined: false,
            potential: false,
        }
        apiCall("rep_control/getAll", api_object, (_status, _result) => {
            if(_status){    
                setSharedStores(_result)
                setInitLoaded(true);
            }else{
                message.error(_result.error)
            }
        })
    }

    useEffect(getSharedStores, []);

    const [storeCount, setStoreCount] = useState(session.stores_working_with.map(item => item.contact_id).length);
    function switchSuggestedCarts(results = false){
        let store_ids = []
        if(!results){
            session.stores_working_with.forEach((store) => {
                store_ids.push(store.contact_id)
            })
        }else{
            results.stores_working_with.forEach((store) => {
                store_ids.push(store.contact_id)
            });
        }
        apiCall("suggested_cart/getAll", {hide_shared : true, limit: 50, offset: 0, store_ids: JSON.stringify(store_ids)}, (_status, _result) => {
            if (_status) {
                setSuggestedCarts(_result.rows);
                setSCCount(session.suggested_carts_working_with.length);
            } 
        })
    }

    useEffect(switchSuggestedCarts, []);

    function setSuggestedCartsWorkingWith(_sc, _task){
        let this_stores_carts = session.suggested_carts_working_with.map(item => item.id)
        if(this_stores_carts.includes(_sc.toString()) && _task === "remove"){
            this_stores_carts.splice(this_stores_carts.indexOf(_sc.toString()), 1);
        }else if(!this_stores_carts.includes(_sc.toString()) && _task === "add"){
            this_stores_carts.push(_sc.toString())
        }
        apiCall("session/setSuggestedCartsWorkingWith", {suggested_cart_ids_json: JSON.stringify(this_stores_carts)}, (_status, _result) => {
            if(_status){
                setSCCount(_result.suggested_carts_working_with.length);
                setSession(_result)
            }
        })
    }

    const switchStore = (e) => {
        setStoreCount(e.length)
        apiCall("session/setStoresWorkingWith", { store_ids_json: JSON.stringify(e) }, (_status, _result) => {
            if (_status) {
                setSession(_result)
                switchSuggestedCarts(_result)
            } else {
                message.error(_result.error);
            }
        })
    }

    function drawSelectOptions(store){
        return suggestedCarts.map((item) => {
            if(item.store_info.name === store.name){
                return (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
            }
        })
    }

    function getStoresSuggestedCarts(store){
        let this_stores_carts = session.suggested_carts_working_with.filter((item) => item.store_info.name == store.name)
        return this_stores_carts.map(cart => cart.id)
    }

    return (
        <>  
            {((initLoaded) && 
                <div>
                    <conditional.true value={storeCount < 5}>
                        <label>Select up to {5 - storeCount} more stores:</label><br />
                    </conditional.true>
                    <conditional.true value={storeCount >= 5}>
                        <label style={{"color": "red"}}>You cannot select any more stores</label><br />
                    </conditional.true>
                    <Select 
                        mode="multiple" 
                        getPopupContainer={trigger => trigger.parentElement}
                        className='ant-select-with-custom-arrow'
                        defaultValue={session.stores_working_with.map(item => item.contact_id)} 
                        onChange={(e) => switchStore(e)} 
                        style={{ width: 250 }}>
                        {sharedStores.map(item => {
                            return (
                                <Select.Option 
                                    key={item.contact_id} 
                                    value={item.contact_id}>{item.name}
                                </Select.Option>
                            )
                        })}
                    </Select>
{/* Hiding the Suggested Cart selections until this feature actually works */}
                    {/* {session.stores_working_with.map((store) => {
                        return(
                            <>
                                <Divider dashed style={{ "margin": "5px 0px" }} />
                                <conditional.true value={scCount < 5}>
                                    <label>Select up to {5 - scCount} more suggested orders for: <br></br>{store.name}</label><br />
                                </conditional.true>
                                <conditional.true value={scCount >= 5}>
                                    <label style={{"color": "red"}}>You cannot select any more carts!</label><br />
                                </conditional.true>
                                <Select 
                                    mode="multiple" 
                                    defaultValue={getStoresSuggestedCarts(store)}
                                    onDeselect={(e) => setSuggestedCartsWorkingWith(e, "remove")}
                                    onSelect={(e) => setSuggestedCartsWorkingWith(e, "add")} 
                                    style={{ width: 250 }}>
                                    {drawSelectOptions(store)}
                                </Select>  
                            </>
                        )
                    })} */}
                </div>
            )}
        </>
    )
}