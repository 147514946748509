import React from "react";
import { Typography, Divider, Row, Col, Space, Form, Input } from "antd";
export default function SeoSection(props) {


    const { ws } = props;

    return (
        <>
            <div id="seo" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>SEO</Typography.Title>
                <Divider />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Hours */}
                        <div id="ws_metadesc"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5} >Meta tags</Typography.Title>
                                <Typography.Paragraph type="secondary" >Meta tags provide web browsers and search engines with a simple description of your site and important keywords that people might use to find you. It can take a while for service providers and search engines to apply your changes.</Typography.Paragraph>
                                <Typography.Paragraph><strong>Meta Tag Description:</strong></Typography.Paragraph>
                                <Form.Item name="ws_metadesc" initialValue={ws["ws_metadesc"]} noStyle >
                                    <Input maxLength={255} style={{ "maxWidth": "500px" }}></Input>
                                </Form.Item>
                                <Typography.Paragraph type="secondary">Example: Busytown's local independent bookstore since 1942 (limit to 255 characters)</Typography.Paragraph>
                                <Typography.Paragraph><strong>Meta Tag Keywords:</strong></Typography.Paragraph>
                                <Form.Item name="ws_metakeyw" initialValue={ws["ws_metakeyw"]} noStyle >
                                    <Input maxLength={200} style={{ "maxWidth": "500px" }}></Input>
                                </Form.Item>
                                <Typography.Paragraph type="secondary">Example: bookstore, books, busytown, independent, Canadian fiction (limit to 200 characters and separate keywords with a comma and space)</Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Third party codes */}
                        <div id="ws_google_analytics_ua"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5} >Third party codes</Typography.Title>
                                <Typography.Text><strong>Google Analytics code: </strong></Typography.Text>
                                <div style={{"width" : "400px"}} className="shim"></div>
                                <Form.Item name="ws_google_analytics_ua" initialValue={ws["ws_google_analytics_ua"]} noStyle >
                                    <Input maxLength={20} style={{"maxWidth" : "200px"}} ></Input>
                                </Form.Item>
                                {/* <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                                <Typography.Text><strong>Google Site Verification code: </strong></Typography.Text>
                                <div className="shim"></div>
                                <Form.Item name="ws_meta_google" initialValue={ws["ws_meta_google"]} noStyle >
                                    <Input maxLength={100}></Input>
                                </Form.Item>
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                                <Typography.Text><strong>Bing Site Verification code: </strong></Typography.Text>
                                <div className="shim"></div>
                                <Form.Item name="ws_meta_bing" initialValue={ws["ws_meta_bing"]} noStyle >
                                    <Input maxLength={100}></Input>
                                </Form.Item> */}
                            </div>
                        </Space>
                    </Col>
                </Row>
               
            </div>
        </>
    )
}