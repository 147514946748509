import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Spin, Result, Divider } from "antd";
import { apiCall } from "../utils/Api";
import { LoadingOutlined } from '@ant-design/icons';
import { useSession } from "../utils/Session";
import Conditions from "../components/Conditions";

export default function StockCheckPage() {


    const [context, setContext] = useSession(); 

    const { isbn = "", session = "", store_id = "" } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({ sales: [] });
    const [error, setError] = useState("");
    const ref = useRef(); 

    const loadAnimation = <LoadingOutlined style={{ fontSize: 45 }} spin />


    let container_height = 500;
    const getHeight = () => {
        setTimeout(() => {
            let h = ref.current.clientHeight || 0;
            if(h !== container_height){
                window.parent.postMessage({size: h + "px"}, "*");
            }
            container_height = h; 
            getHeight(); 
        }, 1000)
    }
    useEffect(getHeight, [])



    const getSession = () => {
        apiCall("session/get", {}, (_status, _result) => {
            if (_status) {
                setContext({...context, "temp_session" : session});
                window.store_id = store_id;
                Cookies.set("session_repsite_id", _result.session_id, { expires: 365, secure : true, sameSite : "None" });
                if (_result.logged_in) {
                    getSettings();
                } else {
                    setError("There was an error");
                }
            }
        }, "store", true, session, store_id);
    }

    const getSettings = () => {
        apiCall("store/getSettings", {}, (_status, _result) => {
          if (_status) {
            window.sitesettings = _result;
            getItem();
          } else {
            setError("There was an error");
          }
        }, "store", true, session, store_id)
      }

    
    useEffect(getSession, []);

    const getItem = () => {

        apiCall("title/getItem", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                setError("There was an error");
            }
            setLoading(false);
        }, "store", true, session, store_id)
    }


    
        
   

    if(loading){
        return (
            <Result
                  status={"success"}
                  icon={<Spin indicator={loadAnimation} />}
                  title={"Loading..."}
                  subTitle={"This will only take a moment..."}
              />
        )
    }

    if (error) {
        return (
            <Result status="warning" title={error} />
        )
    }

    return (
        <div ref={ref}>
            {/* <Conditions conditions={results.conditions} data={results} isbn={(results) ? results.isbn : isbn} /> */}
            
        </div>
    )




}