import { CloseOutlined, ShareAltOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Divider, Form, Input, Layout, message, Modal, Radio, Row, Space, Table, Tag, Tooltip, Typography } from "antd";
import moment from 'moment-timezone';
import React, { useEffect, useState } from "react";

import IndividualAccountHistoryModal from '../../components/admin/IndividualAccountHistoryModal';
import Overlay from '../../components/Overlay';
import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";

export default function IndividualAccount(props) {

    const { updateRow = () => { }, data = {}, index = 0 } = props;
    const [expanded, setExpanded] = useState([]);

    /* 
        ----------
        share_relationship_info.status
        ----------
        No data share connection: null
        Once button is clicked: null -> requested
        Three options after the request has been sent:
            1. Approved by the store: requested -> approved
            2. Declined by the store: requested -> declined -> rerequested
                - Once the store declines it, the rep can choose to "rerequest" to have the stores data shared
            3. requested -> approved_inactive
                - Don't think "approved_inactive" makes sense, but that's how it seems to work

        ----------
        basic_link_status
        ----------
        No data share connection: null
        Once button is clicked: null -> requested
        Two options after the request has been sent:
            1. Approved by the store
            2. Declined by the store
        If declined: requested -> declined -> rerequested
            - Once the store declines it, the rep can choose to "rerequest" to have the stores data shared
    */

    const {
        contact_id = "",
        name = "",
        san = "",
        email = "",
        city = "",
        province = "",
        has_basic_link = false,
        basic_link_status = "",
        category = "",
        share_relationship_info = {
            relationship_id: "",
            status: "",
            declined_reason: "",
            ack_contact_name: "",
            last_change: 0,
        },
    } = data;


    const [basicLinkStatus, setBasicLinkStatus] = useState(basic_link_status);
    const [hasBasicLink, setHasBasicLink] = useState(has_basic_link);
    const [reason, setReason] = useState(share_relationship_info.declined_reason);

    const sendStoreConnectionRequest = () => {
        apiCall("rep_control/submitLink", { contact_id: contact_id}, (_status, _result) => {
            if (_status) {
                setHasBasicLink(true)
                setBasicLinkStatus("requested")
                updateRow(_result);
            }
            setReason("");  
        });
    }

    const removeStoreConnectionRequest = () => {
        apiCall("rep_control/removeLink", { contact_id: contact_id }, (_status, _result) => {
            if (_status) {
                setHasBasicLink(false);
                setBasicLinkStatus(null);
                updateRow(_result);
            }
            setReason("");
        });
    }

    const sendStoreDataShareRequest = () => {
        apiCall("rep_control/submitShareLink", { contact_id: contact_id }, (_status, _result) => {
            if (_status) {
                updateRow(_result);
            }
            setReason("");
        });
    }

    const removeStoreDataShareRequest = () => {
        apiCall("rep_control/removeShareLink", { contact_id: contact_id }, (_status, _result) => {
            if (_status) {
                updateRow(_result);
            }
            setReason("");
        });
    }

    const drawStatus = () => {
        let ret = "";
        if(hasBasicLink && basicLinkStatus === "approved"){
            if(share_relationship_info.status !== null){
                switch (share_relationship_info.status) {
                    case "approved":
                        ret = <Tooltip title="Click to stop seeing data from this store"><Tag className="tag-btn" onClick={() => removeStoreDataShareRequest()} color="#78c042"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
                        break;
                    case "rerequested":
                    case "requested":
                        ret = <Space size={0}>
                            <Tooltip title="Cancel your request to see this stores data"><Tag className="tag-btn" onClick={() => removeStoreDataShareRequest()} color="#f12a46"><strong><CloseOutlined /></strong></Tag></Tooltip>
                        </Space>
                        break;
                    case "declined":
                        ret = <Tooltip title="Resend your request to see this stores data"><Tag className="tag-btn" onClick={() => sendStoreDataShareRequest()} color="#f12a46"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
                        break;
                    case "approved_inactive":
                            ret = <Tooltip title="Click to request to see data from the store"><Tag className="tag-btn" onClick={() => sendStoreDataShareRequest()} color="gray"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
                            break;
                    default:
                        ret = <Tooltip title="Cannot send a request for data until you are connected with this store."><Tag style={{"cursor": "not-allowed"}} color="#808080"><ShareAltOutlined style={{"cursor": "not-allowed"}} /></Tag></Tooltip>
                    break;
                }
            }else{
                switch (basicLinkStatus) {
                    case "approved":
                        ret = <Tooltip title="Click to request to see data from the store"><Tag className="tag-btn" onClick={() => sendStoreDataShareRequest()} color="#808080"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
                        break;
                    default:
                        ret = <Tooltip title="Cannot send a request for data until you are connected with this store."><Tag style={{"cursor": "not-allowed"}} color="#808080"><ShareAltOutlined style={{"cursor": "not-allowed"}} /></Tag></Tooltip>
                    break;
                }
            }
            
        }else{
            ret = <Tooltip title="Cannot send a request for data until you are connected with this store."><Tag style={{"cursor": "not-allowed"}} color="gray"><strong><ShareAltOutlined style={{"cursor": "not-allowed"}}  /></strong></Tag></Tooltip>
        }
        return ret;
    }

    const drawIfAccountCheckbox = () => {
        let ret = "";
        switch (basicLinkStatus) {
            case "approved":
                ret = <Tooltip title="You are connected, click to remove them as one of your stores"><Tag className="tag-btn" onClick={() => removeStoreConnectionRequest()} color="#78c042"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                break;
            case "requested":
                ret = <Space size={0}>
                    <Tooltip title="Cancel the request to connect with this store"><Tag className="tag-btn" onClick={() => removeStoreConnectionRequest()} color="#8f3fe4"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                </Space>
                break;
            case "rerequested":
                ret = <Space size={0}>
                    <Tooltip title="Cancel the request to connect with this store"><Tag className="tag-btn" onClick={() => removeStoreConnectionRequest()} color="#8f3fe4"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                </Space>
                break;
            case "declined":
                ret = <Tooltip title="Resend your request to connect with this store"><Tag className="tag-btn" onClick={() => sendStoreConnectionRequest()} color="#f12a46"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                break;
            default:
                ret = <Tooltip title="Not working with this store. Click to request to work with them"><Tag className="tag-btn" onClick={() => sendStoreConnectionRequest()} color="#808080"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                
            break;
        }
        return ret;
    }

    function drawNameSANEmailHeader(){
        if(email !== ""){
            return <>{name} - {san} - <a href={"mailto:" + email} >{email}</a></>
        }
        return <>{name} - {san}</>
        
    }

    function drawLastChange(){
        if(share_relationship_info.status === "approved"){
            return <>Accepted on {moment(share_relationship_info.last_change * 1000).format("MMMDD-YY")}</> 
        }else if(share_relationship_info.status === "declined"){
            return <>Declined on {moment(share_relationship_info.last_change * 1000).format("MMMDD-YY")}</> 
        }
        return <>Request sent on {moment(share_relationship_info.last_change * 1000).format("MMMDD-YY")}</> 
    }

    //rep_control/getContactHistory
    //rep_control/getDeletedHistory

    return (
        <div style={{ "margin": "5px -20px" }}>
            <Card className="bc bcg c rep" size="small" headStyle={{ "border": "none" }} style={{ "padding": "10px 0px", "border": "none" }} title={<>
                <Space size={0}>
                    <div style={{ "width": "100px" }}>
                        {/* <Space size={0}> */}
                            {/* <div>&nbsp;&nbsp;</div> */}
                            <div style={{ "width": "35px", "paddingBottom": "10px" }}>
                                {drawIfAccountCheckbox()}
                            </div>
                            <div style={{ "width": "35px" }}>
                                {drawStatus()}
                            </div>
                        {/* </Space> */}
                    </div>
                </Space>
                <div style={{ "float": "right", "fontSize": "11px", "fontWeight": "normal", "width": "210px", "marginRight": "-15px" }}>
                    <Row>
                        <Col flex={"100px"}>
                            <span className="c">{city}</span>
                        </Col>
                        <Col flex={"30px"}>
                            <span className="c">{province}</span>
                        </Col>
                        <Col flex={"80px"}>
                        </Col>
                    </Row>
                </div>
                <span className="c">{drawNameSANEmailHeader()}</span>
            </>}>
                <conditional.true value={share_relationship_info.last_change > 0}>
                    <div style={{"marginLeft": "100px", "marginTop": "-40px"}} className='c'>{drawLastChange()}</div>
                    <div style={{"float": "right", "fontSize": "11px", "marginTop": "-20px", "fontWeight": "normal", "width": "210px", "marginRight": "-15px" }}>
                        <Button type='primary'>
                            <Overlay 
                                width={600}
                                component={
                                    <IndividualAccountHistoryModal contact_id={contact_id}/>
                                } 
                                >Show History
                            </Overlay>
                            {/* Show History */}
                        </Button>
                    </div>
                </conditional.true>
                <conditional.true value={share_relationship_info.last_change <= 0 || share_relationship_info.last_change === null}>
                    <div style={{"float": "right", "fontSize": "11px", "marginTop": "-40px", "fontWeight": "normal", "width": "210px", "marginRight": "-15px" }}>
                        <Button type='primary'>
                            <Overlay 
                                width={600}
                                component={
                                    <IndividualAccountHistoryModal contact_id={contact_id}/>
                                } 
                                >Show History
                            </Overlay>
                            {/* Show History */}
                        </Button>
                    </div>
                </conditional.true>
                
            </Card>
        </div>
    )
}