import { Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall, apiDownload } from "../../utils/Api";

export default function ValidMissingData(props){

    const {
        loading = false,
        getDrilldown = () => {},
        isbnValues = {},
        dataValues = {},
        validPageData = [],
    } = props;

    const [validEANMissingData, setValidEANMissingData] = useState();
    const [otherMissingData, setOtherMissingData] = useState();

    //getDrilldown(grouping_row, group, drilldown)
    //grouping_row: y/n, the date, or the prefix
    //group:        valid (none), prefix, or pubdate
    //drilldown:    the specific oo_isbn, oh_isbn, etc.

    const determineDrilldownValue = (type, value) => {
        if(value === "_notitle"){
            return type.concat("_notit").toLowerCase();
        }
        return type.concat(value).toLowerCase();
    }

    const columns = [
        {
            title:  
                <Tooltip title="Individual ISBN types">
                    {/* <small>ISBN Type</small> */}
                    <small></small>
                </Tooltip>, 
            width: '150px', 
            dataIndex: 'isbntype', 
            key: 'isbntype'
        },
        // {
        //     title: 
        //         <Tooltip title="Shows whether or not an ISBN is a valid EAN">
        //             <small>Valid EAN?</small>
        //         </Tooltip>, 
        //     dataIndex: 'valid_ean', 
        //     key:'valid_ean',  
        //     render: (e, f) => {
        //         return <span className="isbnCount">{f.valid_ean}</span>
        //     }
        // },
        {
            title:
                <Tooltip title="Total ISBNs in the ISBN type, not the total of ISBNs missing some data">
                    <small>Count</small>
                </Tooltip>, 
            dataIndex: 'count', 
            key:'count',  
            render: (e, f) => {
                return <span 
                    className="isbnCount" 
                    style={{ "cursor": "pointer"}}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_isbns"))}>{f.count}</span>
            }
        },
    ]
    if(dataValues.no_title){
        columns.push({
            title: 
                <Tooltip title="ISBNs missing a title">
                    <small>No Title</small>
                </Tooltip>, 
            dataIndex: 'notitle', 
            key: 'notitle',  
            render: (e, f) => {
                return <span 
                    className="noTitle" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_notitle"))}>{f.notitle}</span>
            }
        })
    }
    if(dataValues.no_img){
        columns.push({
            title:
                <Tooltip title="ISBNs missing an image. Does not include ISBNs that don't have interior images">
                    <small>No Img</small>
                </Tooltip>, 
            dataIndex: 'noimg', key: 'noimg',  render: (e, f) => {
                return <span 
                    className="noImg" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_noimg"))}>{f.noimg}</span>
            }
        })
    }
    if(dataValues.no_desc){
        columns.push({
            title:
                <Tooltip title="ISBNs missing a description">
                    <small>No Desc</small>
                </Tooltip>, 
            dataIndex: 'nodesc', key: 'nodesc',  render: (e, f) => {
                return <span 
                    className="noDesc" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nodesc"))}>{f.nodesc}</span>
            }
        })
    }
    if(dataValues.no_bio){
        columns.push({
            title:
                <Tooltip title="ISBNs missing an author biography">
                    <small>No Bio</small>
                </Tooltip>, 
            dataIndex: 'nobio', key: 'nobio',  render: (e, f) => {
                return <span 
                    className="noBio" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nobio"))}>{f.nobio}</span>
            }
        })
    }

    const prepMissingDataForDisplay = () => {
        let valid_temp_array = [];
        let invalid_temp_array = [];
        if(validPageData.length > 0){
            if(isbnValues.ito_isbns){
                valid_temp_array.unshift(
                    {
                        isbntype: "ITO",
                        valid_ean: "Y",
                        count: validPageData[0].ito_isbns,
                        notitle: validPageData[0].ito_notit,
                        nodesc: validPageData[0].ito_nodesc,
                        nobio: validPageData[0].ito_nobio,
                        noimg: validPageData[0].ito_noimg,
                    },
                )
            }
            if(isbnValues.oo_isbns){
                valid_temp_array.unshift(
                    {
                        isbntype: "OO",
                        valid_ean: "Y",
                        count: validPageData[0].oo_isbns,
                        notitle: validPageData[0].oo_notit,
                        nodesc: validPageData[0].oo_nodesc,
                        nobio: validPageData[0].oo_nobio,
                        noimg: validPageData[0].oo_noimg,
                    },
                )
            }
            if(isbnValues.oh_isbns){
                valid_temp_array.unshift(
                    {
                        isbntype: "OH",
                        valid_ean: "Y",
                        count: validPageData[0].oh_isbns,
                        notitle: validPageData[0].oh_notit,
                        nodesc: validPageData[0].oh_nodesc,
                        nobio: validPageData[0].oh_nobio,
                        noimg: validPageData[0].oh_noimg,
                    },
                )
            }
            if(isbnValues.nyr_isbns){
                valid_temp_array.unshift(
                    {
                        isbntype: "NYR",
                        valid_ean: "Y",
                        count: validPageData[0].nyr_isbns,
                        notitle: validPageData[0].nyr_notit,
                        nodesc: validPageData[0].nyr_nodesc,
                        nobio: validPageData[0].nyr_nobio,
                        noimg: validPageData[0].nyr_noimg,
                    },
                )
            }
            if(isbnValues.rank_isbns){
                valid_temp_array.unshift(
                    {
                        isbntype: "Rank",
                        valid_ean: "Y",
                        count: validPageData[0].rank_isbns,
                        notitle: validPageData[0].rank_notit,
                        nodesc: validPageData[0].rank_nodesc,
                        nobio: validPageData[0].rank_nobio,
                        noimg: validPageData[0].rank_noimg,
                    },
                )
            }
            if(isbnValues.all_isbns){
                valid_temp_array.unshift(
                    {
                        isbntype: "All",
                        valid_ean: "Y",
                        count: validPageData[0].all_isbns,
                        notitle: validPageData[0].all_notit,
                        nodesc: validPageData[0].all_nodesc,
                        nobio: validPageData[0].all_nobio,
                        noimg: validPageData[0].all_noimg,
                    },
                )
            }
    
            if(validPageData.length > 1){
                if(isbnValues.ito_isbns){
                    invalid_temp_array.unshift(
                        {
                            isbntype: "ITO",
                            valid_ean: "N",
                            count: validPageData[1].ito_isbns,
                            notitle: validPageData[1].ito_notit,
                            nodesc: validPageData[1].ito_nodesc,
                            nobio: validPageData[1].ito_nobio,
                            noimg: validPageData[1].ito_noimg,
                        },
                    )
                }
                if(isbnValues.oo_isbns){
                    invalid_temp_array.unshift(
                        {
                            isbntype: "OO",
                            valid_ean: "N",
                            count: validPageData[1].oo_isbns,
                            notitle: validPageData[1].oo_notit,
                            nodesc: validPageData[1].oo_nodesc,
                            nobio: validPageData[1].oo_nobio,
                            noimg: validPageData[1].oo_noimg,
                        },
                    )
                }
                if(isbnValues.oh_isbns){
                    invalid_temp_array.unshift(
                        {
                            isbntype: "OH",
                            valid_ean: "N",
                            count: validPageData[1].oh_isbns,
                            notitle: validPageData[1].oh_notit,
                            nodesc: validPageData[1].oh_nodesc,
                            nobio: validPageData[1].oh_nobio,
                            noimg: validPageData[1].oh_noimg,
                        },
                    )
                }
                if(isbnValues.nyr_isbns){
                    invalid_temp_array.unshift(
                        {
                            isbntype: "NYR",
                            valid_ean: "N",
                            count: validPageData[1].nyr_isbns,
                            notitle: validPageData[1].nyr_notit,
                            nodesc: validPageData[1].nyr_nodesc,
                            nobio: validPageData[1].nyr_nobio,
                            noimg: validPageData[1].nyr_noimg,
                        },
                    )
                }
                if(isbnValues.rank_isbns){
                    invalid_temp_array.unshift(
                        {
                            isbntype: "Ranked",
                            valid_ean: "N",
                            count: validPageData[1].rank_isbns,
                            notitle: validPageData[1].rank_notit,
                            nodesc: validPageData[1].rank_nodesc,
                            nobio: validPageData[1].rank_nobio,
                            noimg: validPageData[1].rank_noimg,
                        },
                    )
                }
                if(isbnValues.all_isbns){
                    invalid_temp_array.unshift(
                        {
                            isbntype: "All",
                            valid_ean: "N",
                            count: validPageData[1].all_isbns,
                            notitle: validPageData[1].all_notit,
                            nodesc: validPageData[1].all_nodesc,
                            nobio: validPageData[1].all_nobio,
                            noimg: validPageData[1].all_noimg,
                        },
                    )
                }
                setOtherMissingData(invalid_temp_array);
            }
            setValidEANMissingData(valid_temp_array);
        }
    }
        

    useEffect(prepMissingDataForDisplay, [])

    // const alternateRowColours = (record, index) => {
    //     if(index%2 === 1){
    //         return "evenRowColour"
    //     }
    // }
    

    return(
        <>
            <div style={{ "padding": "0px 0px" }}>
                <div style={{ "margin": "0 0px" }}>
                    <Typography.Title style={{"padding": "5px"}} level={5}>
                        Valid EANs/ISBNs Analysis
                    </Typography.Title>
                    <Table
                        size="small"
                        pagination={false}
                        loading={loading}
                        // rowClassName={(record, index) => alternateRowColours(record, index)}
                        className="missingDataTable"
                        columns={columns}
                        dataSource={validEANMissingData}
                    />
                </div>
                {(otherMissingData &&  <div style={{ "margin": "0 0px" }}>
                    {/* Table for the Non-Valid EAN values */}
                    <Typography.Title style={{"padding": "5px"}} level={5}>
                        Invalid EANs/ISBNs  Analysis
                    </Typography.Title>
                    <Table
                        size="small"
                        pagination={false}
                        // rowClassName={(record, index) => alternateRowColours(record, index)}
                        loading={loading}
                        columns={columns}
                        className="missingDataTable"
                        dataSource={otherMissingData}
                    />
                </div>)}
            </div>
        </>
    )
}