import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Input, Radio, Space, Table, Tooltip, Typography } from "antd";
import { useState } from "react";

export default function ListShareAccountsTab(props) {
    const {
        list = {},
        close = () => { },
        loading = false,
        setChosenList = [],
        shareableStores = [],
        setShareableStores = () => { },
        getStartingValues = () => { },
        setShared = () => { },
        selectedRowKeys = [],
        setSelectedRowKeys = () => { },
        drawReturnToReportCardButton = () => { },
    } = props;

    const [initShareableStores, setInitShareableStores] = useState([]);

    const [shareCount, setShareCount] = useState(shareableStores.filter(item => item.is_shared).length);
    const [hasBeenShared, setHasBeenShared] = useState(false);
    const { shared_status = "none" } = list;
    const is_public = (shared_status === "everyone");

    function toggleMultiSelect(id, checked) {
        
        // let tempSelectedKeys = [...selectedRowKeys]
        // if (checked) {
        //     tempSelectedKeys.push(id);
        // } else {
        //     tempSelectedKeys.splice(tempSelectedKeys.indexOf(id), 1);
        // }
        let _sharableStores = [...shareableStores];
        let _f = _sharableStores.findIndex(item => item.contact_id === id);
        if(_f > -1){
            // found
            _sharableStores[_f].is_shared = checked; 
            setShareableStores(_sharableStores);
            setShareCount((checked) ? shareCount + 1 : shareCount - 1);
            setSelectedRowKeys(_sharableStores);
        }
      

        
       
    }

    const drawSelect = (e, f) => {
        if (is_public) {
            return (<Tooltip title={<small>Catalogue is public</small>}><Checkbox disabled checked /></Tooltip>)
        } else {
            return (
                <Tooltip title={(f.is_read && <small>{f.name} has read this catalogue and it can no longer be unshared with them.</small>)}>
                    <Checkbox disabled={f.is_read} defaultChecked={f.is_shared} onClick={(e) => toggleMultiSelect(f.contact_id, e.target.checked)} />
                </Tooltip>
            )
        }
    }

    const columns = [
        {
            title: <small>Shared</small>, dataIndex: 'is_shared', key: 'is_shared', width: "60px", render: (e, f) => {
                return <div style={{ "textAlign": "left" }}>{drawSelect(e, f)}</div>
                return <Checkbox disabled={f.is_shared} defaultChecked={f.is_shared} onClick={(e) => toggleMultiSelect(f.contact_id, e.target.checked)} />
            }
        },
        // MH - Removed because it's redundant
        // {
        //     title: <small>Shared</small>, width: '50px', dataIndex: 'is_shared', key: 'is_shared',  align: 'center',  render: (e,f) => {
        //         if(f.is_shared){
        //             if(!hasBeenShared){
        //                 setHasBeenShared(true);
        //             }
        //             return (
        //                 <CheckCircleTwoTone twoToneColor="green"/>
        //             )
        //         }
        //         return(
        //             <CloseCircleTwoTone twoToneColor="red"/>
        //         )
        //     }
        // },
        {
            title: <small>SAN</small>, width: '80px', dataIndex: 'san', key: 'san', sorter: (a, b) => a.san.localeCompare(b.san),
        },
        {
            title: <small>Linked</small>, width: '50px', dataIndex: 'is_client', key: 'is_client', align: 'center', render: (e, f) => {
                if (f.is_client) {
                    return (
                        <CheckCircleTwoTone twoToneColor="green" />
                    )
                }
                return (
                    <CloseCircleTwoTone twoToneColor="red" />
                )
            }
        },

        {
            title: <small>Store Name</small>, dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: <small>City</small>, width: '130px', dataIndex: 'city', key: 'city', sorter: (a, b) => a.city.localeCompare(b.city),
        },
        {
            title: <small>Province/State</small>, width: '40px', dataIndex: 'province', key: 'province', sorter: (a, b) => a.province.localeCompare(b.province),
        },
    ];

    const resetSelection = () => {
        getStartingValues();
        setSelectedRowKeys([]);
    }

    function shareWithAllAccounts() {
        let temp_client_ids_array = shareableStores.filter((store) => store.is_client);
        setSelectedRowKeys(temp_client_ids_array.map(store => store.contact_id))
    }

    const searchLocal = (_search, temp_init_stores) => {
        let new_table_data = temp_init_stores.map(item => {
            return { ...item }
        });
        setShareableStores(new_table_data.filter(item => item.name.toUpperCase().includes(_search.toUpperCase())));
    }

    const onChange = (_search) => {
        // ensure init is set
        let temp_init_stores = initShareableStores;
        if (shareableStores.length > initShareableStores.length) {
            temp_init_stores = shareableStores.map(item => {
                return { ...item }
            });
            setInitShareableStores(temp_init_stores);
        }
        if (!_search) {
            setShareableStores(temp_init_stores);
        }
        return searchLocal(_search, temp_init_stores);
    }

    return (
        <div style={{ "padding": "20px" }}>
            <Typography.Title level={5}>Share with your Accounts</Typography.Title>
            <Space>
                <Input onChange={(e) => onChange(e.target.value)} allowClear style={{ "fontSize": "12px" }} size="small" placeholder="Refine by name" />
            </Space>
            <span>
                <Button style={{ "marginLeft": "10px" }} type="primary" onClick={() => shareWithAllAccounts()}>
                    Select all my accounts
                </Button>
                <Button style={{ "marginLeft": "10px" }} type="danger" onClick={() => resetSelection()}>
                    Reset Choices
                </Button>
            </span>
            <div className="shim"></div>
            <Table
                rowKey="san"
                size="small"
                loading={loading}
                pagination={true}
                columns={columns}
                dataSource={shareableStores}
            />
            <div className="shim" />
            <div className="shim" />
            <span>
                <div style={{ "float": "left" }}>
                    <Space>
                        {drawReturnToReportCardButton()}
                        <Button type="danger" onClick={() => close()}>Cancel</Button>
                    </Space>
                </div>
                <div style={{ "float": "right" }}>
                    <Space size={20}>
                        <div>Shared with {shareCount} store{(shareCount !== 1 && "s")}.</div>
                        <Button
                            onClick={(e) => setShared(false, false, shareableStores.filter(item => item.is_shared).map(item => item.contact_id))}
                            type="primary">Save</Button>
                    </Space>
                </div>
            </span>
            <br clear="all" />
        </div>
    )
}