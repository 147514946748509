/* eslint-disable jsx-a11y/anchor-is-valid */
import { CloseOutlined, CopyOutlined, SelectOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Image, message, Space, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { settings } from "../../data";
import nocover from "../../media/no-cover.png";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { usePrefs } from "../../utils/Prefs";
import { useSession } from "../../utils/Session";
import { futureColor, getCanIcon, handleTermSlash, isValidUPC, parseAuthors, parseSubjects, parseSubjectsPlain, parseSubjectsWeird, ucfirst } from "../../utils/Utils";
import CatalogueCount from "../catalogues/CatalogueCount";
import EyeIcon from "../icons/EyeIcon";
import ItemLayouts from "../ItemLayouts";
import ListCount from "../lists/ListCount";
import ListQuickPick from "../lists/ListQuickPick";
import SalesCount from "../lists/SalesCount";
import SeriesCount from "../lists/SeriesCount";
import Overlay from "../Overlay";
import Pubstock from "../Pubstock";
import Renderer from "../Renderer";
import SupplementaryInfo from "../SupplementaryInfo";
import DigitalResources from "./DigitalResources"
import TitleDetails from "./TitleDetails";
import TitleExtras from "./TitleExtras";
import TitleImage from "./TitleImage";
import TitleLinks from "./TitleLinks";
import TitlePDF from "./TitlePDF";
import TitleRank from "./TitleRank";
import TitleRelated from "./TitleRelated";
import TitleResults from "./TitleResults";
import TitleShare from "./TitleShare";

dayjs.extend(advancedFormat);
// Variants

Title.Summary = (props) => {
    return (<Title display="summary" isbn={props.isbn} />)
}

export default function Title(props) {

    const {
        data = {},
        drawComponent = () => { },
        vendor = {},
        display = "summary",
        selected = "",
        setSelected = () => { },
        catalogueID = "",
        selectMultiple = false,
        toggleMultiSelect = () => { },
        highlight = false,
        setHighlight = () => { },
        effectiveDates = [],
        suppliers = [],
        agencies = [],
        categories = [],
        selectedRowKeys = [],
    } = props;

    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(false);
    const [session, setSession] = useSession();
    const [prefs, setPrefs] = usePrefs();

    //used to keep ListCount and ListQuickPick in sync for live updates
    const [myCatalogues, setMyCatalogues] = useState(props.data.my_catalogues);

    const parseExpand = () => {
        if (prefs) {
            let _ret = [];
            Object.keys(prefs).forEach(key => {
                if (prefs[key]) {
                    _ret.push(key);
                }
            })
            return _ret;
        } else {
            return [];
        }
    }


    const [opened, setOpened] = useState(parseExpand())

    const toggleOpened = (_key) => {
        if (opened.includes(_key)) {
            setOpened([])
        } else {
            setOpened([_key]);
        }
    }
    const recheck = () => {
        if (JSON.stringify(parseExpand()) !== JSON.stringify(opened)) {
            setOpened(parseExpand())
        }
    }



    useEffect(recheck, [prefs])
    const [showPubstock, setShowPubstock] = useState("");
    const { url_isbn } = useParams();
    const {
        authors = [],
        price = null,
        list_price_usa = null,
        rank = {
            bestseller: null,
        },
        binding = "",
        binding_code = "",
        release_date = null,
        conditions = [],
        is_forthcoming = false,
        available = [],
        canadian = false,
        title = "",
        subtitle = "",
        eisbn = "",
        isbn = (data.hasOwnProperty("isbn")) ? data.isbn : props.isbn.toString(),
        upc = "",
        height = null,
        width = null,
        pages = null,
        publisher = "",
        has_libro_audiobook = true,
        audience = null,
        edition = "",
        edition_type = "",
        has_interiors = null,
        print_status = null,
        imprint = "",
        series = "",
        series_num = "",
        weight = null,
        carton_qty = null,
        sales_marketing = [],
        description = "",
        bio = [],
        comments = [],
        reviews = [],
        subjects = [],
        catalogues = [],
        illustrations = "",
        interiors = [],
        language = "",
        digital_resources = [],
        isbn_type = "ean",
        my_catalogues = false,
        public_catalogue_count = 0,
        sequence_num = 0,
        related_count,
        prefix_length = 7,
        can_edit = false,
        cover_image_cache = 0,
        interior_objects,
        cover_image_key = ""
    } = results;

    const [coversHighlight, setCoversHighlight] = useState(data.highlight)

    const getTitle = () => {
        if (data.hasOwnProperty("isbn")) {
            setResults(data);
            setLoading(false)
        } else {
            let _isbn = props.isbn;
            if (url_isbn) {
                _isbn = url_isbn;
            }
            apiCall("title/getItem", { isbn: _isbn }, (_status, _result) => {
                if (_status) {
                    setResults(_result);
                    setLoading(false)
                }
            })
        }
    }

    useEffect(getTitle, [isbn])
    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }


    const [copied, setCopied] = useState(false);
    const copyToClipboard = (e) => {
        if (!navigator.clipboard) {
            message.error("Can't copy");
            return;
        }
        navigator.clipboard.writeText(isbn);
        setCopied(true);
        setTimeout(() => {
            setCopied(false)
        }, 1000);
    }

    const [copied2, setCopied2] = useState(false);
    const copyToClipboard2 = (e) => {
        if (!navigator.clipboard) {
            message.error("Can't copy");
            return;
        }
        navigator.clipboard.writeText(upc);
        setCopied2(true);
        setTimeout(() => {
            setCopied2(false)
        }, 1000);
    }

    function updateMyListsInParent(new_list) {
        setMyCatalogues(new_list);
    }

    function highlightStatusChange(e) {
        apiCall((e.target.checked) ? "titlelist/highlight" : "titlelist/unhighlight", { list_id: catalogueID, isbns_json: JSON.stringify([isbn]) }, (_status, _result) => {
            if (_status) {
                setHighlight(e.target.checked)
                setCoversHighlight(e.target.checked)
            }
        });
    }


    const drawUPC = (_upc) => {
        if (isValidUPC(_upc)) {
            return "UPC " + _upc;
        } else {
            return "VN " + _upc;
        }
    }

    const drawISBN = (_isbn) => {
        if (isbn_type !== "ean") {
            return isbn_type.toUpperCase() + " " + _isbn + "   Copy";
        }
        let ret = _isbn;
        let link = <>
            <a onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <Tooltip title="Click prefix to see titles with the same prefix">
                    <span
                        style={{ "fontFamily": "ApercuMono" }}
                        onClick={(e) => {
                            if (e.ctrlKey) {
                                drawComponent(<TitleResults term={ret.substr(0, prefix_length)} isbn={isbn} name={"ISBN prefix search: " + ret.substr(0, prefix_length)} drawComponent={drawComponent} />)
                            } else {
                                window.open("/browse/filter/t/" + ret.substr(0, prefix_length), "_blank");
                            }
                        }}>
                        {ret.substr(0, prefix_length)}
                    </span>
                </Tooltip>
            </a>
            <span style={{ "fontFamily": "ApercuMono" }}>{ret.substr(prefix_length, ret.length)}  &nbsp; Copy</span></>
        return link;
    }

    const parseAuthorsLink = (_authors) => {
        if (!_authors || _authors.length === 0) { return "" }
        return _authors.map((_author, index) => {
            let flag = (_author.canadian && settings.is_canadian);
            return (<nobr>
                <Tooltip title="View other titles by this author in a new tab">
                    <a onClick={(e) => {
                        e.stopPropagation();
                        window.open("/browse/filter/k/author/t/" + _author.name)
                    }}
                        style={{ "color": "#007EF5", "fontSize": "14px" }}
                        key={index}
                    >
                        {_author.name}{(flag && getCanIcon())} {(index !== _authors.length - 1) ? ", " : ""}
                    </a>
                </Tooltip>
                {/* <Tooltip title="View other titles by this author below">
                    <Button 
                        style={{ "height": "20px", "padding": "0px", "paddingRight": "5px" }} 
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            drawComponent(<TitleResults term={_author.name} type={"author"} isbn={isbn} name={"Other titles for: " + _author.name} drawComponent={drawComponent} />)
                        }} type="link"
                    > 
                        {(flag && getCanIcon())}
                    </Button>
                </Tooltip> */}
            </nobr>);
        });
    }

    const drawPrice = () => {
        let _denom = (settings.is_canadian) ? " CA" : "";
        return (
            <div className="c2" style={{ "fontSize": "14px", "fontWeight": "normal" }} >{binding}
                {(edition && <span style={{ "fontSize": "12px", "display": "block", "fontWeight": "normal" }}>{edition}</span>)}
                <div style={{ "fontWeight": "600" }}>{(price > 0) ? "$" + price + _denom : ""} </div>{(settings.is_canadian && <><span style={{ "fontWeight": "600" }}>{(list_price_usa > 0) ? "$" + list_price_usa + " US" : ""}</span></>)}</div>)
    }

    function drawShopLocalButton() {
        let imageText = "https://bookmanager.com/public/api/btns/Shop Local Button.png"
        return (
            <Button
                className="tbm_shop_local tbm_injected"
                type='text'
                href={'https://shoplocal.bookmanager.com/isbn/' + isbn}
                target='_blank'>
                <Image
                    src={imageText} />
            </Button>
        )
    }

    function shopLocalParams() {
        let imageText = "https://bookmanager.com/public/api/btns/'Shop Local Button.png"
        return {
            'api-key': "pea0O4sbfjGHbHyOgKLrIGXKhuHzOohR",
            'product-code': isbn,
            'image': imageText
        }
    }

    function canHighlight() {
        if (data.highlight != null && myCatalogues.find((cat) => catalogueID === cat) != null) {
            return true;
        }
        return false;
    }

    const drawSummary = () => {
        const pagesValue = pages != null ? pages + " pages | " : null;
        const widthValue = width && height != null ? width + "\" x " : null;
        const heightValue = width && height != null ? height + "\" | " : null;
        const weightValue = weight != null ? weight + "lbs | " : null;
        const cartonValue = carton_qty != null ? "Crtn: " + carton_qty : null;
        return (
            <div style={{ "padding": "0px", "paddingBottom": "0px" }}>
                <Space size={13} align="start" style={{ "overflow": "hidden" }}>
                    <div style={{ "maxWidth": "140px" }}>
                        {/* <TitleImage isbn={isbn} eisbn={eisbn} has_interiors={has_interiors} /> */}
                        <TitleImage highlight={highlight} cover_image_cache={cover_image_cache} interior_objects={interior_objects} isbn={isbn} cover_image_key={cover_image_key} eisbn={eisbn} has_interiors={has_interiors} />

                        <div className="shim" />
                        <Space size={2}>
                            <Button
                                style={{ "width": "140px" }}
                                type={(copied ? "primary" : "default")}
                                onClick={() => copyToClipboard(isbn)}
                                size="small"
                                className="mini-btn nofocus"
                            >
                                <small>
                                    &nbsp;{(copied) ? "Copied" : drawISBN(isbn)}
                                </small>
                            </Button>
                        </Space>
                        {(upc &&
                            <div>
                                <Button
                                    style={{ "width": "140px" }}
                                    type={(copied2 ? "primary" : "default")}
                                    onClick={() => copyToClipboard2(upc)}
                                    size="small"
                                    className="mini-btn nofocus"
                                >
                                    <small>
                                        &nbsp;{(copied2) ? "Copied" : drawUPC(upc)}
                                    </small>
                                </Button>
                            </div>
                        )}
                        <Space size={2}>
                            <Overlay component={<div style={{ "padding": "20px" }}><TitleDetails drawComponent={drawComponent} isbn={isbn} /></div>}>
                                <Button disabled={!can_edit} style={{ "width": "35px" }} size="small" className="mini-btn" ><small>Edit</small></Button>
                            </Overlay>
                            <TitlePDF isbn={isbn} />
                            <TitleShare title={title} eisbn={eisbn} isbn={isbn} />
                            <div style={{ "textAlign": "center", "width": "30px" }}>
                                <TitleLinks authors={authors} isbn={isbn} />
                            </div>
                        </Space>
                        {drawShopLocalButton()}
                    </div>
                    <div>
                        <Typography.Title style={{ "lineHeight": "19px" }} level={5}>
                            {/* <span className="tag-btn" >
                                <span onClick={(e) => {
                                    drawComponent(<TitleResults term={title} isbn={isbn} name={"Other Editions for: " + title} drawComponent={drawComponent} />)
                                }}>
                                    <Tooltip title="View other editions of this title">{title}{(subtitle) ? <small>: {subtitle}</small> : ""} </Tooltip><Tooltip title="View other editions of this title in a new tab"><Button size="small" onClick={() => {
                                        let _term = title;
                                        if (authors.length > 0) { _term += " " + authors[0].name; }
                                        window.open("/browse/filter/v/related:" + isbn + "/t/" + handleTermSlash(_term, true));
                                    }} type="link"><SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "10px" }} /></Button></Tooltip>
                                    
                                </span>
                                <conditional.true value={canHighlight()}>
                                    <Tooltip title="Choose to highlight/unhighlight this title">
                                        <Checkbox defaultChecked={highlight}  onChange={(e) => highlightStatusChange(e)}>Highlight</Checkbox>
                                    </Tooltip>
                                </conditional.true>
                            </span> */}
                            <span className="tag-btn" >
                                <span onClick={(e) => {
                                    e.stopPropagation();
                                    let _term = title;
                                    if (authors.length > 0) { _term += " " + authors[0].name; }
                                    window.open("/browse/filter/t/" + handleTermSlash(_term, true));
                                }
                                }>
                                    <Tooltip title="View other editions of this title in a new tab"><a>{title}</a>{(subtitle) ? <small>: {subtitle}</small> : ""} </Tooltip><Tooltip title="View other editions of this title below"><Button style={{ "padding": "0px 0px" }} size="small" onClick={(e) => {
                                        e.stopPropagation();
                                        let _term = title;
                                        if (authors.length > 0) { _term += " " + authors[0].name; }
                                        drawComponent(<TitleResults term={handleTermSlash(_term, true)} isbn={isbn} name={"Other Editions for: " + title} drawComponent={drawComponent} />)
                                    }} type="link"><EyeIcon /></Button></Tooltip>
                                </span>

                            </span>
                            <div style={{ "marginTop": "-2px" }}>{drawPrice()}</div>
                            <div className="shim" />
                            <Space split={<small className="c2"> </small>}>
                                <conditional.true value={(release_date)}>
                                    <span className={(is_forthcoming) ? "c2 futureColor" : "c2"} style={{ "fontSize": "14px", "display": "block", "fontWeight": "600" }}>{moment(release_date * 1000).format("MMM Do, YYYY")}</span>
                                </conditional.true>
                                <TitleRank rank={rank} />
                            </Space>
                            <small><span style={{ "color": "#069", "fontSize": "14px", "display": "block" }}>{parseAuthorsLink(authors)}</span></small>

                            {(series && <div className="c2" style={{ "fontSize": "14px", "display": "block", "fontWeight": "normal", "marginTop": "-2px" }}>Series: <Tooltip title="View titles in series in a new tab"><a onClick={(e) => {
                                window.open("/browse/filter/z/" + series, "_blank");
                            }}>{series} {(series_num) ? ": (#" + series_num.toString() + ")" : ""}</a></Tooltip> <Tooltip title="View titles in series below"><Button style={{ "padding": "0px 0px" }} size="small" onClick={(e) => {
                                e.stopPropagation();
                                drawComponent(<TitleResults type="series" series={series} term={series} isbn={isbn} name={"Other titles in series: " + series} drawComponent={drawComponent} />)
                            }} type="link"><EyeIcon /></Button></Tooltip>

                            </div>)}


                            <div className="c2" style={{ "fontSize": "14px", "display": "block", "fontWeight": "normal", "marginTop": "-2px" }}><span style={{ "fontWeight": "600" }}>{publisher}</span>{(imprint && (imprint !== publisher) && <em> | {imprint}</em>)}</div>
                            {(audience && <span style={{ "fontSize": "14px", "display": "block", "fontWeight": "normal" }}>Audience: {audience}</span>)}
                            <div className="shim" />
                            <div className="shim" />
                            <div style={{ "lineHeight": "12px" }}>
                                <div className="c2" style={{ "fontSize": "12px", "display": "block", "fontWeight": "normal", "lineHeight": "14px" }}>{parseSubjects(subjects)}</div>
                                <div className="shim" />
                                {(print_status && <span className="c2" style={{ "color": "#f12a46", "fontSize": "11px", "display": "block", "fontWeight": "normal" }}>{ucfirst(print_status)}</span>)}
                                <span
                                    className="c2"
                                    style={{ "fontSize": "11px", "display": "block", "fontWeight": "normal" }}>
                                    {pagesValue} {widthValue} {heightValue} {weightValue} {cartonValue}
                                </span>
                                {(language && <span className="c2" style={{ "fontSize": "11px", "display": "block", "fontWeight": "normal" }}>Language: {language}</span>)}
                            </div>
                        </Typography.Title>
                        <Space align="top">
                            <Space wrap size={[3, 0]}>
                                <ListCount
                                    // For preloading the create catalogue with the ISBN data - MH
                                    data={{ isbns: [data] }}
                                    title={title}
                                    myCatalogues={myCatalogues}
                                    isbn={isbn}
                                    updateMyListsInParent={updateMyListsInParent}
                                    effectiveDates={effectiveDates}
                                    agencies={agencies}
                                    suppliers={suppliers}
                                    categories={categories}
                                />
                                <CatalogueCount count={public_catalogue_count} isbn={isbn} />
                                <SalesCount title={title} data={sales_marketing} />
                                <TitleRelated related_count={related_count} title={title} drawComponent={drawComponent} isbn={isbn} />
                                <DigitalResources data={digital_resources} />
                                {((series && series_num && binding) && <>
                                    <SeriesCount series_num={(series_num) ? ": (#" + series_num.toString() + ")" : ""} series={series} next={true} drawComponent={drawComponent} isbn={isbn} />
                                    <SeriesCount series_num={(series_num) ? ": (#" + series_num.toString() + ")" : ""} series={series} next={false} drawComponent={drawComponent} isbn={isbn} />
                                </>)}
                            </Space>
                        </Space>
                        {(my_catalogues && <div><ListQuickPick isbn={isbn} myCatalogues={myCatalogues} updateMyListsInParent={updateMyListsInParent} /></div>)}
                    </div>
                </Space>
                {/* <TitleExtras data={data} isbn={isbn} catalogueID={catalogueID}/> */}
                <conditional.true value={(showPubstock)}>
                    <div style={{ "padding": "0px 20px" }}>
                        <Pubstock drawComponent={drawComponent} show_stores={(showPubstock === "stores")} isbn={isbn} />
                    </div>
                </conditional.true>
                <SupplementaryInfo opened={opened} data={data} isbn={data.isbn || isbn} />
            </div>
        )
    }

    const drawPrint = () => {
        let image_path = "https://cdn1.bookmanager.com/i/b?b=" + eisbn;
        if (cover_image_key) {
            image_path = image_path + "&b2b=" + cover_image_key;
        }
        return (
            <div style={{ "padding": "0px", "paddingBottom": "0px", backgroundColor: (selected === isbn) ? "#dcf4ff" : "#fff" }}>
                <Space align="start" style={{ "overflow": "hidden" }}>
                    <div style={{ "minWidth": "130px" }}>
                        <img className="shadow-large" onError={(e) => noCover(e)} style={{ "width": "130px", "marginRight": "10px" }} src={image_path} alt="" />
                        <div className="shim" />
                    </div>
                    <div style={{ "minWidth": "265px" }}>
                        <Typography.Title style={{ "lineHeight": "17px" }} level={5}>{title}
                            <span style={{ "color": "#333", "fontSize": "14px", "display": "block" }}>{subtitle}</span>
                            <span style={{ "color": "#069", "fontSize": "14px", "display": "block", "pointerEvents": "none" }}><small>{parseAuthors(authors)}</small></span>
                            <div className="shim" />
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>Subjects:</strong> {parseSubjectsPlain(subjects)}</span>
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>ISBN:</strong> {isbn}</span>
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>Publisher:</strong> {publisher}</span>
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>Binding:</strong> {binding}</span>
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>Date:</strong> {moment(release_date * 1000).format("MMMM Do, YYYY")}</span>
                        </Typography.Title>
                    </div>
                </Space>
            </div>
        )
    }

    const [imgError, setImageError] = useState(false);

    const CardImage = (props) => {
        const ref = useRef();
        const scrollIntoViewWithOffset = (selector, offset) => {
            window.scrollTo({
                behavior: 'smooth',
                top:
                    selector.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    offset,
            })
        }
        useEffect(() => {
            scrollIntoViewWithOffset(ref.current, 280);
        }, [])
        return (
            <>
                <div style={{ "marginTop": "0px", "marginLeft": "0px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                    <div className="shim" /><div className="shim" /><div className="shim" />
                    <div ref={ref}><Renderer close={true}><ItemLayouts data={data} display="details2" /></Renderer></div>
                </div>
            </>
        )
    }

    function drawBorder() {
        if (highlight) {
            return {
                borderStyle: "solid",
                borderColor: "#ff0099",
                borderWidth: "3px",
                opacity: props.loading ? "0.5" : "1",
                pointerEvents: props.loading ? "none" : "inherit",
                marginTop: sequence_num ? "8px" : "0px"
            }
        }
        return {
            opacity: props.loading ? "0.5" : "1",
            pointerEvents: props.loading ? "none" : "inherit",
            marginTop: sequence_num ? "8px" : "0px"
        }
    }

    function drawCardBorder() {
        if (coversHighlight) {
            return {
                top: "-27px",
                left: "0px",
                lineHeight: "14px",
                position: "absolute",
                padding: "-5px",
                borderStyle: "solid",
                borderColor: "#ff0099",
                borderWidth: "3px",
                backgroundColor: "#ff0099",
            }
        }
        return {
            top: "-27px",
            left: "0px",
            lineHeight: "10px",
            position: "absolute",
            padding: "3px 4px"
        }
    }

    const drawImage = () => {
        let image_path = "https://cdn1.bookmanager.com/i/n?b=" + eisbn
        if (cover_image_key) {
            image_path = image_path + "&b2b=" + cover_image_key;
        }
        if (cover_image_cache) {
            image_path = image_path + "&cb=" + cover_image_cache;
        }
        if (imgError) {
            return (

                <div onClick={() => drawComponent(<CardImage />)} style={{ "backgroundImage": `url(${nocover})`, "backgroundSize": "contain" }}>
                    <div className="card-fallback">
                        <div><strong>{title}</strong></div>
                        <div className="shim" /><div className="shim" />
                        {(subtitle && <>
                            <div style={{ "lineHeight": "14px" }}><small>{subtitle}</small></div>
                            <div className="shim" /><div className="shim" />
                        </>)}
                        <div>{parseAuthors(authors, "#000")}</div>
                    </div>
                </div>
            )
        }
        return (
            <img alt="example" onClick={() => drawComponent(<CardImage />)} onError={(e) => setImageError(true)} src={image_path} />
        )
    }
    function checkIfSelected() {
        let is_selected = false;
        if (selectedRowKeys.some((row) => row === isbn)) {
            is_selected = true;
        }
        return is_selected;
    }

    function drawCardDescription() {
        return (
            <>
                <conditional.true value={selectMultiple}>
                    <Checkbox checked={checkIfSelected()} onChange={(e) => toggleMultiSelect(isbn, e.target.checked)} > Select </Checkbox>
                    <div className="shim" />
                </conditional.true>
                <div style={{ "fontSize": "16px", "lineHeight": "16px" }} className="c"><strong>{title}</strong>
                    <div>{parseAuthors(authors, "inherit", "12px")}</div>
                </div>
                <div className="shim" />
                <div className="c" style={{ "fontSize": "14px", "lineHeight": "16px", "justifyContent": "space-between" }}>
                    <div>
                        {binding}<div className='shim' />
                        <span className="c" style={{ "color": (is_forthcoming) ? futureColor : "" }}>{dayjs(release_date * 1000).format('MMM Do, YYYY')}</span>
                    </div>
                    <small style={{ "fontWeight": "600", "fontSize": "16px", }}>${(settings.is_canadian) ? price : list_price_usa}</small>
                </div>
                <div className="shim" />
                {/* Below is the listing of catalogues to make it easier to do this kinda thing without switching views */}
                <ListCount
                    // For preloading the create catalogue with the ISBN data - MH
                    data={{ isbns: [data] }}
                    title={title}
                    agencies={agencies}
                    suppliers={suppliers}
                    myCatalogues={myCatalogues}
                    effectiveDates={effectiveDates}
                    categories={categories}
                    isbn={isbn}
                    updateMyListsInParent={updateMyListsInParent}
                />
                {(my_catalogues && <div className="c"><ListQuickPick isbn={isbn} myCatalogues={myCatalogues} updateMyListsInParent={updateMyListsInParent} /></div>)}
                <TitleShare title={title} eisbn={eisbn} />
                <div style={{ "height": "3px" }} className='shim' />
            </>
        )
    }


    const drawCard = () => {
        return (
            <Card
                hoverable
                style={drawBorder()}
                key={isbn}
                size="small"
                className={(selected === isbn) ? "card-selected" : "card"}
                cover={
                    <>
                        {drawImage()}
                        <conditional.true value={sequence_num > 0}>
                            <div style={drawCardBorder()}>
                                <small className="c"><strong>{"#" + sequence_num} {coversHighlight ? "Highlighted" : ""}</strong></small>
                            </div>
                        </conditional.true>
                    </>
                }
            >
                <Card.Meta description={
                    <>
                        {drawCardDescription()}
                    </>
                } />
            </Card>
        )
    }

    switch (display) {
        case "summary":
            return drawSummary();
        case "card":
            return drawCard();
        case "print":
            return drawPrint();
        default:
            return drawSummary();
    }
}

