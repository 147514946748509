import { EyeInvisibleOutlined, EyeOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Tabs, Tooltip } from "antd";
import Cookies from "js-cookie";
import moment from 'moment-timezone';
import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player'

import { apiCall } from "../../utils/Api";
import Html from "../../utils/Html";
import { usePrefs } from "../../utils/Prefs";
import { ucfirst } from "../../utils/Utils";
import Comments from "../Comments";
import Conditions from '../Conditions';
import PeerSales from '../PeerSales';
import TitlePubstockTab from './TitlePubstockTab';

export default function TitleExtras(props) {
    const {
        isbn = "",
        data = {},
        dataSet,
        conditions,

    } = props;
    const [prefs, setPrefs] = usePrefs();
    const [active, setActive] = useState(prefs.selected);

    const {
        media = [],
        tab_data = {
            comments: [],
            description: "",
            bios: [],
            reviews: [],
            marketing: {
                key_points: [],
                more_info: [],
                awards: [],
                promotion: [],
                printing: [],
                extras: {
                    links: [],
                    publisher_links: [],
                    author_links: [],
                    video_links: []
                },
                sales_rights: []
            }
        }
    } = data;

    const recheck = () => {
        if (active !== prefs.selected) {
            setActive(prefs.selected);
        }
    }

    useEffect(recheck, [prefs.selected]);

    const pin = (e, _key) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (prefs.selected === _key) {
            Cookies.set("selected", "");
            setPrefs({ ...prefs, "selected": "" });
        } else {
            Cookies.set("selected", _key);
            setPrefs({ ...prefs, "selected": _key });
        }
    }

    const drawPin = (_key) => {
        if (_key === prefs.selected) {
            return <>&nbsp; <div style={{ "display": "inline-block", "margin": "-5px" }}><PushpinFilled onClick={(e) => pin(e, _key)} style={{ "marginRight": "0px", "opacity": "1", "fontSize": "11px", "padding": "5px" }} /></div></>
        } else {
            if (active === _key) {
                return <>&nbsp; <div style={{ "display": "inline-block", "margin": "-5px" }}><Tooltip title={<small>Click to pin</small>}><PushpinOutlined onClick={(e) => pin(e, _key)} style={{ "marginRight": "0px", "opacity": "0.5", "fontSize": "11px", "padding": "5px" }} /></Tooltip></div></>
            } else {
                return "";
            }
        }
    }

    const drawCount = (_key) => {
        let _ret;
        let _active = (active === _key);
        switch (_key) {
            case "description":
                let _desc_length = (tab_data.description) ? 1 : 0;
                if (!_active && _desc_length) {
                    _ret = <small>({_desc_length})</small>
                } else {
                    _ret = "";
                }
                break;

            case "comments":
                let _comment_length = tab_data.comments.length;
                if (!_active && _comment_length) {
                    _ret = <small>({_comment_length})</small>
                } else {
                    _ret = "";
                }
                break;

            case "author":
                let _bios_length = tab_data.bios.length;
                if (!_active && _bios_length) {
                    _ret = <small>({_bios_length})</small>
                } else {
                    _ret = "";
                }
                break;

            case "reviews":
                let _reviews_length = tab_data.reviews.length;
                if (!_active && _reviews_length) {
                    _ret = <small>({_reviews_length})</small>
                } else {
                    _ret = "";
                }
                break;

            case "marketing":
                let _marketing_length = tab_data.marketing.key_points.length;
                _marketing_length += tab_data.marketing.more_info.length;
                _marketing_length += tab_data.marketing.awards.length;
                _marketing_length += tab_data.marketing.promotion.length;
                _marketing_length += tab_data.marketing.printing.length;
                // Removed by kellynda's request #1131
                //_marketing_length += results.marketing.sales_rights.length;
                if (tab_data.marketing.hasOwnProperty("extras") && !Array.isArray(tab_data.marketing.extras)) {
                    _marketing_length += tab_data.marketing.extras.publisher_links.length;
                    _marketing_length += tab_data.marketing.extras.author_links.length;
                    _marketing_length += tab_data.marketing.extras.video_links.length;
                }
                if (!_active && _marketing_length) {
                    _ret = <small>({_marketing_length})</small>
                } else {
                    _ret = "";
                }
                break;

            case "videos":
                let youtube_ids = grabYouTubeIDs();
                let display_urls = grabMediaURLs();
                let video_links = youtube_ids.concat(display_urls);
                if (!_active && video_links.length > 0) {
                    _ret = <small>({video_links.length})</small>
                } else {
                    _ret = "";
                }
                break;
            default:
                _ret = "";
                break;
        }
        return _ret;
    }

    const drawMarketing = (_arr) => {
        let inc = 0;
        let _ret = Object.keys(_arr).map(_key => {
            if (_key === "extras") {
                return Object.keys(_arr[_key]).map(_k => {
                    return _arr[_key][_k].map((_itm, index) => {
                        if (_itm) {
                            inc++;
                        }
                        if (_itm.hasOwnProperty("url") && _itm.hasOwnProperty("desc")) {
                            return <>{(index === 0 && <div><div className="shim" /><div className="shim" /><strong className="c">{ucfirst(_k)}</strong></div>)}<ul style={{ "marginBottom": "0px" }}><li><div><a target={"_blank"} href={_itm.url}>{(_itm.desc) ? _itm.desc : _itm.url}</a></div></li></ul></>
                        }
                        return (<>{(index === 0 && <div><div className="shim" /><div className="shim" /><strong className="c">{ucfirst(_k)}</strong></div>)}<Html key={index} html={_itm} /></>);
                    });
                })
            } else {
                return _arr[_key].map((_itm, index) => {
                    if (_itm) {
                        inc++;
                    }
                    // Removed by kellynda's request #1131
                    if (_key === "sales_rights") {
                        return (<></>);
                    }
                    if (_key === "more_info" || _key === "awards") {
                        return (<>{(index === 0 && <div><div className="shim" /><div className="shim" /><strong className="c">{ucfirst(_key)}</strong></div>)}<Html key={index} html={_itm} /><div className="shim" /></>);
                    }
                    return (<>{(index === 0 && <div><div className="shim" /><div className="shim" /><strong className="c">{ucfirst(_key)}</strong></div>)}<ul style={{ "marginBottom": "0px" }}><li><Html key={index} html={_itm} /></li></ul><div className="shim" /></>);
                });
            }
        })
        if (inc > 0) {
            return _ret;
        } else {
            return <></>// <Empty imageStyle={{ height: 20}} className="small-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
    }

    const drawSource = (_review) => {
        let _source = [];
        if (_review.source_title) {
            _source.push(_review.source_title);
        }
        if (_review.author) {
            _source.push(_review.author);
        }
        if (_review.publication_date) {
            _source.push(moment(_review.publication_date).format("MMMM YYYY"))
        }
        if (_review.author || _review.source_title) {
            return <div className="float-flex"><div></div><em>-{_source.join(", ")}</em></div>
        } else {
            return (<></>)
        }
    }

    const parseReviews = (_reviews) => {
        if (!_reviews || _reviews.length === 0) {
            return ("");
        }
        return _reviews.map((_review, index) => {
            return (<span className='c' key={index}><Html html={_review.content} />{drawSource(_review)}<div className="shim" /><div className="shim" /></span>);
        });
    }

    function marketingCheck() {
        for (const [key, value] of Object.entries(tab_data.marketing)) {

            if(tab_data.marketing?.extras){
                return true;
            }

            if (value.length > 0 && key !== "sales_rights") {
                return true;
            }
        }
        return false;
    }

    function drawMarketingTab() {
        if (marketingCheck()) {
            return (

                <div className="testClass">{drawMarketing(tab_data.marketing)}</div>

            )
        }
    }

    function grabYouTubeIDs() {
        let media_urls = "";
        for (let i = 0; i < media.length; i++) {
            if (media[i].type === "component") {
                media_urls = media[i].data
            }
        }
        return media_urls;
    }

    function grabMediaURLs() {
        let media_urls = "";
        for (let i = 0; i < media.length; i++) {
            if (media[i].type === "url") {
                media_urls = media[i].data
            }
        }
        return media_urls;
    }

    function drawVideosTab() {
        let youtube_ids = grabYouTubeIDs();
        let display_urls = grabMediaURLs();
        if (display_urls.length > 0 || youtube_ids.length > 0) {
            return (<>

                {/* 
                        See this for more info on ReactPlayer:
                        https://github.com/CookPete/react-player
                        A good ISBN to test how the player looks:
                        9798396136106
                    */}
                {youtube_ids.map((item) => {
                    return (
                        <div style={{ "marginBottom": "10px" }} className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url={'https://www.youtube.com/watch?v=' + item}
                                controls={true}
                                width="100%"
                                height="100%" />
                        </div>)
                })}
                {display_urls.map((item) => {
                    return (
                        <div style={{ "marginBottom": "10px" }} className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url={item}
                                controls={true}
                                width="100%"
                                height="100%" />
                        </div>)
                })}
            </>)
        }
    }


    const tabComments = () => {
        return (<Comments comments={tab_data.comments} row isbn={isbn} specific_title_in_list_id={data.titlelist_id > 0 ? data.titlelist_id : 0} />)
    }

    const tabDescription = () => {
        return (<Html key="description" html={tab_data.description} />)
    }

    const tabAuthor = () => {

        return (
            <>
                {tab_data.bios.map((_itm, index) => {
                    return (<div key={index}><Html html={_itm} /><div className="shim" /><hr /><div className="shim" /></div>);
                })}
            </>
        )

    }

    const tabReviews = () => {
        return parseReviews(tab_data.reviews);
    }

    const tabVideos = () => {
        return drawVideosTab();
    }

    const tabMarketing = () => {
        return (drawMarketingTab());
    }

    const tabPubstock = () => {
        return (<TitlePubstockTab pubstockData={tab_data.pubstock} />)
    }

    const tabPeerData = () => {
        return (<PeerSales total_stores={data.sales_summary.total_stores} isbn={isbn} />);
    }

    const tabStoreData = () => {
        return (<Conditions
            sideColumn={false}
            storeData={true}
            conditions={conditions}
            data={dataSet}
            isbn={isbn} />)
    }


    return (
        <>
            <div className="shim" /><div className="shim" />
            <Tabs
                className="itemTabs"
                onChange={(e) => {
                    if (e !== active) {
                        setActive(e)
                    } else {
                        setActive("")
                    }
                }}
                tabBarStyle={{ "padding": "0px" }}
                size="small"
                type="card"
                activeKey={(active) ? active : false}>
                <Tabs.TabPane tab={<small>Comments {drawPin("comments")} {drawCount("comments")}</small>} key="comments">
                    {tabComments()}
                </Tabs.TabPane>
                <Tabs.TabPane tab={<small>Description {drawPin("description")} {drawCount("description")}</small>} key="description">
                    {tabDescription()}
                </Tabs.TabPane>
                <Tabs.TabPane tab={<small>Author {drawPin("author")} {drawCount("author")}</small>} key="author">
                    {tabAuthor()}
                </Tabs.TabPane>
                <Tabs.TabPane tab={<small>Reviews {drawPin("reviews")} {drawCount("reviews")}</small>} key="reviews">
                    {tabReviews()}
                </Tabs.TabPane>
                <Tabs.TabPane tab={<small>Videos {drawPin("Videos")} {drawCount("videos")}</small>} key="video_links">
                    {tabVideos()}
                </Tabs.TabPane>
                {(tab_data.marketing.hasOwnProperty("awards") &&
                    <Tabs.TabPane tab={<small>Marketing {drawPin("marketing")} {drawCount("marketing")}</small>} key="marketing">
                        {tabMarketing()}
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane tab={<small>Pubstock {drawPin("pubstock")}</small>} key="pubstock">
                    {tabPubstock()}
                </Tabs.TabPane>
                {((data !== false) &&
                    <Tabs.TabPane tab={<small>Peer Data {drawPin("peer-data")}</small>} key="peer-data">
                        {tabPeerData()}
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane tab={<small>Store Data {drawPin("ind-store-data")}</small>} key="ind-store-data">
                    {tabStoreData()}
                </Tabs.TabPane>

                <Tabs.TabPane tab={<small>All {drawPin("all")}</small>} key="all">
                    <Divider className='c' orientation='left' plain>Comments</Divider>
                    {tabComments()}
                    <Divider className='c' orientation='left' plain>Description</Divider>
                    {tabDescription()}
                    <Divider className='c' orientation='left' plain>Author</Divider>
                    {tabAuthor()}
                    <Divider className='c' orientation='left' plain>Reviews</Divider>
                    {tabReviews()}
                    <Divider className='c' orientation='left' plain>Videos</Divider>
                    {tabVideos()}
                    {(tab_data.marketing.hasOwnProperty("awards") && <>
                        <Divider className='c' orientation='left' plain>Marketing</Divider>
                        {tabMarketing()}
                    </>)}
                    <Divider className='c' orientation='left' plain>Pubstock</Divider>
                    {tabPubstock()}
                    {((data !== false) && <>
                        <Divider className='c' orientation='left' plain>Peer Data</Divider>
                        {tabPeerData()}
                    </>)}
                    <Divider className='c' orientation='left' plain>Store Data</Divider>
                    {tabStoreData()}
                </Tabs.TabPane>
            </Tabs>
        </>
    );
}