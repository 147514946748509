import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Form, Input, Layout, message, Modal, Radio, Row, Select, Space, Spin, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall } from "../../utils/Api";

export default function SuggestedCartCreateEdit(props){
    const { 
        data = {},
        close = () => {},
        callback = () => {},
        valid = true, 
        getAllSuggestedCarts,
        getAllAvailableStores = () => {},
        availableStores,
        edit = false,
        copy = false,
    } = props

    const [saving, setSaving] = useState(false);
    const [titleText, setTitleText] = useState("Create New Suggested Order");
    const [selectedStores, setSelectedStores] = useState(data.store_info ? data.store_info.name : "");
    const [apiString, setApiString] = useState("suggested_cart/create")

    function submitCart(_f){
        setSaving(true)
        if(!_f.cart_name){
            _f.cart_name = data.name;
        }
        if(edit){
            apiCall("suggested_cart/update", {cart_id: data.id, name: _f.cart_name}, (_status, _result) => {
                if (_status) {
                    message.success("Suggested Order edited successfully");
                }
            })
        }else if(copy){
            apiCall("suggested_cart/copy", {cart_id: data.id, store_eid: selectedStores, name: _f.cart_name}, (_status, _result) => {
                if (_status) {
                    message.success("Suggested Order copied successfully");
                }
            })
        }else{  
            apiCall("suggested_cart/create", {store_eid: selectedStores, name: _f.cart_name}, (_status, _result) => {
                if (_status) {
                    message.success("Suggested Order created successfully");
                }
            })
        }
        getAllSuggestedCarts(true);
        close();
        setSaving(false);
    }

    // function multiStoreCheck(){
    //     selectedStores.forEach(element => {
    //         if(true){
    //         // if(is_ms && element.ms_info.length > 1){
    //             return(
    //                 <Typography.Paragraph>
    //                     Warning, due to the original settings for the list you are about to copy, you risk losing some data.
    //                     Titles, notes, and overall quantities will copy over, but any multi-store speciic quantities will be lost.
    //                 </Typography.Paragraph>
    //             )
    //         }else{
    //             return false;
    //         } 
    //     });
    // }

    const columns = [
        {
            title: <small></small>,
            width: 50,
            dataIndex: 'eisbn',
            key: 'eisbn',
            render: (e) => {
                return <div style={{"height": "34px"}}>
                        <img 
                        className="lightshadow" 
                        onError={(e) => e.target.src = "/no-cover.png"} 
                        style={{ "maxHeight": "34px", "maxWidth": "30px" }} 
                        src={"https://bookmanager.com/i/slider_23.php?b=" + e} />
                    </div>
            }
        },
        {
            title: <small>ISBN</small>,
            dataIndex: 'isbn',
            key: 'isbn',
            width: 150
        },
        {
            title: <small>Title</small>,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: <small></small>,
            dataIndex: 'success',
            key: 'success',
            render: (e) => {
                return (e === false) ? <span style={{"color" : "#f00"}}>* Title not found</span> : ""
            }
        },
        {
            title: <small></small>,
            dataIndex: 'success',
            width : "80px",
            key: 'success',
            render: (e, f) => {
                return <div><Button onClick={() => removeItem(f)} danger size="small"><small>Remove</small></Button></div>
            }
        },
    ];

    const removeItem = (f) => {
        let _index = data.rows.findIndex(item => item.isbn === f.isbn);
        if(_index > -1){
            data.rows.splice(_index, 1);
        }
    }

    // function displaySmallTable(){
    //     if(edit){
    //         return(
    //             <Table 
    //                 columns={columns}
    //                 dataSource={data.rows}
    //                 className="bc">
    //             </Table>
    //         )
    //     }
    // }

    function presetText(){
        if(edit){ 
            setTitleText("Edit Suggested Order");
            // setApiString("suggested_cart/update");
        }else if(copy){
            setTitleText("Copy Suggested Order");
            // setApiString("suggested_cart/copy");
        }
    }

    useEffect(() => {
        presetText();
        getAllAvailableStores();
    }, []);

    function drawSelectOptions(){
        return availableStores.map(store  => {
            return <Select.Option value={store.id}>{store.name}</Select.Option>
        });
    }

    return (
        <div style={{ "padding": "20px" }}>
            <Typography.Title>{titleText}</Typography.Title>
            <Form className={(!valid) ? "ant-form-item-has-error" : ""} onFinish={(_f) => submitCart(_f)} layout="vertical">
                <Form.Item help={<small style={{color : (!valid) ? "#ff4d4f" : "#AAA"}}>Name for this Suggested Order</small>} rules={[{required : !(edit||copy) ? true : false}]} name="cart_name" label="Suggested Order Name">
                    <Input defaultValue={data ? data.name : ""} value={data.name} placeholder="Suggested order name"/>
                </Form.Item>
                <div className="shim"/>
                <Form.Item rules={[{ required: true }]} help={<small style={{color : (!valid) ? "#ff4d4f" : "#AAA"}}>Store(s) this Suggested Order is intended for</small>} name="store" label="Available Stores">
                    <Select 
                        // mode="multiple" Going to remove this for now because the logic is just easier without considering multiple on one store
                        onChange={(e) => setSelectedStores(e)} 
                        suffixIcon={<DownOutlined className='ant-select-custom-arrow'/>}
                        getPopupContainer={trigger => trigger.parentElement}
                        placeholder="Select store(s) to work with" 
                        style={{width: '100%'}}
                        defaultValue={selectedStores}>
                        {drawSelectOptions()}
                    </Select>
                </Form.Item>
                {/* {displaySmallTable()} */}
                <span style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => close()}>Cancel</Button>
                        <Button loading={saving} htmlType="submit" type="primary">Save</Button>
                    </Space>
                </span>
                <br clear="all" />
            </Form>
        </div>
    )
}