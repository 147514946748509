import { DownOutlined } from '@ant-design/icons';
import { Button, Divider, Input, message, Popover, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import AccountCard from "./AccountCard";
import LogOutLocations from "./LogOutLocations";
import SelectStores from "./SelectStores";

export default function AccountBox() {

    const history = useHistory();
    const [session, setSession] = useSession();
    const [trigger, setTrigger] = useState(false);

    const drawCorps = () => {
        return session.available_corps.filter(item => item.name !== "My Personal Account").map(item => {
            return (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
        })
    }

    const switchStore = (_id) => {
        history.push("/reload", { id: _id, path: history.location.pathname });
    }

    const logout = () => {
        apiCall("account/logout", {}, (_status, _result) => {
            window.store_id = false;
            setSession(_result);
        })
    }

    const content = (
        <Space direction="vertical">
            <label>Account</label>
            <Select
                className='ant-select-with-custom-arrow'
                onChange={(e) => switchStore(e)}
                getPopupContainer={trigger => trigger.parentElement}
                defaultValue={session.corp_id}
                style={{ width: 250 }}>
                {drawCorps()}
            </Select>
            <Divider dashed style={{ "margin": "5px 0px" }} />
            <SelectStores />
            <LogOutLocations trigger={trigger} />
            <Button onClick={() => logout()} danger type="primary" block>Log out</Button>
        </Space>
    );

    const drawWorkingWith = () => {

        if(session.hasOwnProperty("stores_working_with") && Array.isArray(session.stores_working_with) && session.stores_working_with.length > 0){

            let _name = session.stores_working_with[0].name; 
            let _more = false;
            if(session.stores_working_with.length > 1){
                _more = session.stores_working_with.length-1
            }

            return(<div style={{"textAlign" : "right", "color" : "#FDDA0D", "marginTop" : "-8px", "fontWeight" : "normal"}}><small><em>Viewing <strong>{_name}</strong> {(_more && <> + {_more} more</>)}&nbsp;</em></small></div>)
        } else {
            return(<></>);
        }

    }

    return (
        <>
            <Popover onVisibleChange={(e) => {
                if (e) {
                    setTrigger(!trigger);
                }
            }} placement="bottomRight" content={content} trigger="click">

                <div style={{ "cursor": "pointer" }}>
                    <Space size={0} direction="vertical">
                        <AccountCard store_name={session.picklist_identity} contact={session.contact} />
                        {drawWorkingWith()}
                    </Space>
                </div>
            </Popover>
        </>
    );
}

