import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Select, Space } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import { saveMemory } from "../utils/Utils";

export default function QuickPick(props) {

    const { supplier = "" } = props;
    const [session, setSession] = useSession();

    let checked = "";
    let group = "O";

    const stores = window.sitesettings.stores.map((item, index) => { return index.toString() })
    const groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    session.quick_picks.forEach(element => {
        if (element.supplier === supplier) {
            checked = "checked";
            group = element.group;
        }
    });

  


    const redrawGroup = () =>{

        session.quick_picks.forEach(element => {
            if (element.supplier === supplier) {
               
            }
        });


    }

  
    const hash = str => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          const char = str.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash &= hash; // Convert to 32bit integer
        }
        return new Uint32Array([hash])[0].toString(36);
      };

    const toggleQuickPick = (_add, _grp) => {

      
        
        apiCall((_add) ? "store/addQuickPick" : "store/removeQuickPick", { supplier: supplier, group: _grp }, (_status, _result) => {
            if (_status) {


                setSession({ ...session, "quick_picks": _result.quick_picks })
            }
        })
    }

    return (
        <Space size={5}>
            <Checkbox checked={checked} onChange={(e) => toggleQuickPick(e.target.checked, group)} />
            <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' style={{ width: "30px", "fontSize": "11px" }} dropdownClassName="tinyDropdown" className="tinyAutocomplete" onChange={(e) => toggleQuickPick(true, e)} disabled={!(checked)} size="small" showArrow={false} value={group} >
                {stores.map((_item) => {
                    return <Select.Option key={_item} value={_item}>{_item}</Select.Option>
                })}
                <Select.Option disabled value=""></Select.Option>
                {groups.map((_item) => {
                    return <Select.Option key={_item} value={_item}>{_item}</Select.Option>
                })}
            </Select>
        </Space>
    );

}