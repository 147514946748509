import React from "react";
import { Form, Typography, Divider, Space, Button, Row, Col, Checkbox, Input, message } from "antd";
import WebstoreItem from "../WebstoreItem";
import conditional from "../../../utils/conditional";
import { apiCall } from "../../../utils/Api";
import { useState } from "react";
export default function MultistoreSection(props) {

    const { ws, form } = props;

    const [loading, setLoading] = useState(false);
    
    const verifyEmail = (_field) =>{

        setLoading(true);
        apiCall("settings/verifyEmail", {email : form.getFieldValue("multistore_location_info")[_field].order_email}, (_status, _results) =>{
           
            if(_status){
                message.success("Email verfication successful."); 
            } else {
                message.success("Email verfication failed."); 
            }
            setLoading(false);
        })
    }

    const drawLocations = () => {

        let m = 0;
        return Object.keys(ws["multistore_location_info"]).map((item) => {

            m++;
            
            let obj = ws["multistore_location_info"][item];
            return (
                <div key={item}>
                    <Typography.Title level={5}>{obj.altname}</Typography.Title>
                    <Form.Item initialValue={obj.altname} label="Location name:" name={["multistore_location_info", item, "altname"]}>
                        <Input maxLength={80} style={{ "width": "300px" }} />
                    </Form.Item>
                    <conditional.true value={(obj.addr1)}>
                        <div>{obj.addr1}</div>
                    </conditional.true>
                    <conditional.true value={(obj.addr2)}>
                        <div>{obj.addr2}</div>
                    </conditional.true>
                    <conditional.true value={(obj.addr3)}>
                        <div>{obj.addr3}</div>
                    </conditional.true>
                    <conditional.true value={(obj.addr4)}>
                        <div>{obj.addr4}</div>
                    </conditional.true>
                    <conditional.true value={(obj.city)}>
                        <div>{obj.city} {obj.prov}, {obj.country}</div>
                    </conditional.true>
                    <conditional.true value={(obj.postal)}>
                        <div>{obj.postal}</div>
                    </conditional.true>

                    <conditional.true value={(m !== 1)}>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                        <Form.Item rules={[{ type: "email", message: "Must be a valid email." }]} initialValue={obj.order_email} name={["multistore_location_info", item, "order_email"]}>
                            <Input type="email" style={{ "maxWidth": "500px" }} suffix={<Button loading={loading} onClick={() => verifyEmail(item)} type="primary">Verify Email</Button>} enterButton="Verify email" placeholder="name@email.com" />
                        </Form.Item>
                    </conditional.true>

                    <Divider dashed />
                </div>
            );

        })

    }

    return (
        <>

            <div id="multistore" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Multi-Store</Typography.Title>
                <Divider />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Require email verification from new users when they create a new account */}
                        <div id="ws_nonconsolidated_multistore"></div>
                        <WebstoreItem
                            name="ws_nonconsolidated_multistore"
                            value={(ws["ws_nonconsolidated_multistore"]) ? true : false}
                            heading="Each store location has an individual webstore"
                            description={<div>If you would like a single webstore for your customers to browse and shop, with the ability for your customers to choose their preferred shopping/pickup location, leave this option unchecked. A single domain name will be used.
                            <br /><br />
                            If checked, you will need to maintain a separate consumer webstore for each of your locations. Each of these locations will need unique domain names.
                            </div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={24}>
                        {/* Require email verification from new users when they create a new account */}
                        <div id="contact_information"></div>
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Contact information for orders</Typography.Title>
                                <Typography.Paragraph type="secondary">If you have a multi-store setup, your customers can choose which location they would like to pick up their books from. Enter a different name for each store in the "Location name" field below (example: "Uptown" or "Downtown").</Typography.Paragraph>
                                <Typography.Paragraph type="danger">Note that unless you enter a name for a store, it will NOT be shown in the "Choose your store" dialog.</Typography.Paragraph>
                                <Typography.Paragraph type="secondary">You can also upload a logo image for each location if you desire.</Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />

                <Row gutter={[30, 30]}>
                    <Col md={24} xl={24}>
                        {/* Require email verification from new users when they create a new account */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                {drawLocations()}
                            </div>
                        </Space>
                    </Col>
                </Row>






            </div>
        </>
    )
}