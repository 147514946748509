import { Button, Checkbox, Divider, Form, Input, Modal, Space, Typography } from "antd";
import react, { useState } from "react";

import { apiCall, apiDownload } from "../../utils/Api";
import { useSession } from "../../utils/Session";

export default function ListCreateExcel(props) {

    const { list_id, close = () => {} } = props;
    const [session, setSession] = useSession(); 



    const initOptions = [
        {
            label: "Include ISBN barcode",
            value: "show_barcodes",
            checked: false,
        },
        {
            label: "Include \"Print Status\" column",
            value: "show_print_status",
            checked: false,
        },
        {
            label: "Include \"My Lists\" column to reference other lists",
            value: "show_my_catalogues",
            checked: false,
        },
        {
            label: "Include comments column",
            value: "show_comments",
            checked: false,
        },

        {
            label: "Include URLs",
            value: "include_urls",
            checked: false,
        }

    ]

    if(session.permissions.titlelist_onhand){

        initOptions.push(
            {
                label: "Include On hand / On Order",
                value: "show_oh_oo",
                checked: false,
            }
    
        )
    }

    const [options, setOptions] = useState(initOptions)

    const onFormFinish = (_f) => {
        _f["list_id"] = list_id;
        apiDownload(_f, "titlelist/getExcel");
    }

    const onChange = (_val, _ind) => {
        let _options = [...options];
        _options[_ind].checked = _val;
        setOptions(_options);
    }



    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Create Excel</Typography.Title>
                <Divider style={{"marginBottom" : "10px"}} />
                <p>To export this title list to Excel format, choose from the display option(s) below and click "Export"</p>
                <div className="shim" />
                <Form onFinish={(f) => onFormFinish(f)}>
                    <Space direction="vertical">
                        {options.map((item, index) => {
                            return (
                                <Form.Item valuePropName="checked" initialValue={item.checked} name={item.value} noStyle >
                                    <Checkbox onChange={(e) => onChange(!item.checked, index)}  >&nbsp; {item.label}</Checkbox>
                                </Form.Item>
                            )
                        })}
                    </Space>
                    <Divider />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Export</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </div>
        </>
    )
}