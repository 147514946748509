import { DownOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Layout, Pagination, Radio, Select, Skeleton, Space, Switch, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import { bmDate } from "../utils/Utils";
import CustomerActivity from "./activity/CustomerActivity";
import InvoiceActivity from "./activity/InvoiceActivity";
import PurchaseActivity from "./activity/PurchaseActivity";
import SupplierActivity from "./activity/SupplierActivity";
import Overlay from "./Overlay";

export default function SalesActivity(props) {

    const { openState = "", isbn, condition = "", summary = false, drawComponent = () => { }, label = "Sales activity", setShowActivity = () => { } } = props;
    const [session, setSession] = useSession(); 
    const [results, setResults] = useState({ total: 0, rows: [] });
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState([]);
    const [drawType, setDrawType] = useState("")

    const getStoreName = () => {
        let _store = session.stores_working_with.find(item => item.store_number === props.store);
        if(_store){
            return _store;
        } else {
            return "";
        }
    }

    const defaultStore = getStoreName();

    const [storeSan, setStoreSan] = useState(defaultStore.san);
    const initFilters = {
        limit: (summary) ? 3 : 200,
        offset: 0,
        orders: (openState === "S/O" || openState === "Rcvd") ? true : false,
        returns: (openState === "Retn") ? true : false,
        sales: (openState === "Held" || openState === "Ttl" || openState.substring(0,1) === "#") ? true : false,
        adjustments: (openState === "Adj") ? true : false,
        transfers: false,
        recent: false,
    }



    const [filters, setFilters] = useState(initFilters);

    const saveFilters = (_obj) => {
        // saveFilterSet("sales_activity", _obj);
        setFilters(_obj)
    }

    const getStoreActivity = () => {

        let _filters = { ...filters };
        _filters.isbn = isbn;
        _filters.condition = condition;

        if (storeSan) {
            _filters.wanted_san = storeSan;
        }

        apiCall("activity/getByIsbn", _filters, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getStoreActivity, [filters, isbn, storeSan]);

    const columns = [


        {
            title: <small>Date</small>, width: 60, dataIndex: "date", key: "date", render: (e) => {
                return (e) ? <nobr>{bmDate(e * 1000)}</nobr> : "";
            }
        },
        { title: <small><nobr>Status</nobr></small>, width: 40, dataIndex: "status", key: "status" },
        { title: <small>Qty</small>, width: 32, dataIndex: "quantity", key: "quantity" },
        {
            title: <small>Price</small>, width: 42, dataIndex: "price", key: "price"
        },
        {
            title: <small>%</small>, width: 28, dataIndex: "discount", key: "discount", render: (e) => {
                return e || ""
            }
        },
        { title: <small>PM</small>, width: 28, dataIndex: "profit_margin", key: "profit_margin" },

        {
            title: <small>Supp</small>, width: 45, dataIndex: "supplier", key: "supplier", render: (e, f) => {



                return (e) ? <Overlay component={<SupplierActivity close={setExpanded} invoice_is_supplier={f.invoice_is_supplier} supplier={f.supplier} />}><a>{e}</a></Overlay> : ""
            }
        },
        {
            title: <small>P/O</small>, width: 42, dataIndex: "purchase_order", key: "purchase_order", render: (e, f) => {

                return ((e) ? <Overlay component={<PurchaseActivity close={setExpanded} invoice_is_return={f.invoice_is_return} supplier={f.supplier} purchase_order={f.purchase_order} />}><a>{e}</a></Overlay> : "")


            }
        },
        {
            title: <small>Customer</small>, className: "nowrap", dataIndex: "customer_name", key: "customer_name", render: (e, f) => {

                return (e) ? <Overlay component={<CustomerActivity close={setExpanded} invoice_is_supplier={f.invoice_is_supplier} customer_ship_to={f.customer_ship_to} />}><a>{e}</a></Overlay> : ""

 
            }
        },
        { title: <small>Cust PO</small>, dataIndex: "customer_purchase_order", key: "customer_purchase_order" },
        {
            title: <small>Invoice</small>, width: 76, dataIndex: "invoice_display", key: "invoice_display", render: (e, f) => {

                if (f.invoice_key) {
                    return (e) ? <nobr><Overlay component={<InvoiceActivity close={setExpanded} invoice_is_supplier={f.invoice_is_supplier} invoice={f.invoice_key} />}><a>{e}</a></Overlay></nobr> : "";
                } else {
                    return (e) ? e : ""
                }

            }
        },
        { title: <small>Clrk</small>, width: 32, dataIndex: "cashier", key: "cashier" },
    ];

    if (session.stores_working_with.length > 1) {
        columns.unshift({ title: <small>#</small>, width: "15px", dataIndex: "chain_number", key: "chain_number" })
    }



    const expandCell = (_data, _type) => {
        setDrawType(_type);
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            if (_type === drawType) {
                setExpanded([]);
            } else {
                setExpanded([_data.key]);
            }
        }
    }

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const drawChildTable = (_record) => {




   return;

     
    }


    if (summary) {



        const summaryColumns = [


            {
                title: <small>Date</small>, dataIndex: "date", key: "date", render: (e) => {
                    return (e) ? bmDate(e * 1000) : "";
                }
            },
            { title: <small>Stat</small>, dataIndex: "status", key: "status" },
            { title: <small>Qty</small>, dataIndex: "quantity", key: "quantity" },
            { title: <small>Price</small>, dataIndex: "price", key: "price", render: (e) => { return (e) ? "$" + e.toString() : "" } },
            {
                title: <small>Supl</small>, dataIndex: "supplier", key: "supplier", render: (e, f) => {
                    return (e) ? <a onClick={() => expandCell(f, "supplier")}>{e}</a> : ""
                }
            },
            { title: <small>PO#</small>, dataIndex: "purchase_order", key: "purchase_order" },
            {
                title: <small>Customer</small>, dataIndex: "customer_name", key: "customer_name", render: (e, f) => {
                    return (e) ? <a onClick={() => expandCell(f, "customer")}>{e}</a> : ""
                }
            },
            { title: <small>CustPO</small>, dataIndex: "customer_purchase_order", key: "customer_purchase_order" },
            {
                title: <small>Inv#</small>, dataIndex: "invoice_display", key: "invoice_display", render: (e, f) => {

                    if (f.invoice_key) {
                        return (e) ? <a onClick={() => expandCell(f, "invoice")}>{e}</a> : "";
                    } else {
                        return (e) ? e : ""
                    }

                }
            },
            { title: <small>Cl</small>, dataIndex: "cashier", key: "cashier" },
        ];



        return (
            <div style={{ "border": "1px solid #eee" }}>
                <Table
                    size="small"
                    columns={summaryColumns}
                    className={"tableBorder mini"}
                    dataSource={results.rows}
                    pagination={false}

                    expandable
                    expandedRowKeys={expanded}
                    expandIconColumnIndex={-1}
                    onExpand={(e) => { return false; }}
                    expandIcon={() => { return (<></>) }}
                    expandedRowRender={((record) => drawChildTable(record))}
                />
                <div className="shim" />
                <div style={{ "float": "right" }}>
                    <Space size={2}>
                        <Overlay component={<SalesActivity isbn={isbn} />}><Button className="mini-btn" type="primary" size="small"><small>Detail Activity</small></Button></Overlay>
                    </Space>
                </div>
                <br clear="all" />
            </div>

        )
    }


    const drawHeader = (_header) => {
        return (<>
            {/* <div className="bc" style={{ "borderBottom": "1px solid", "textAlign": "left", "fontSize": "12px", "padding": "0px 8px", "height": "22px", "lineHeight": "20px" }}>

                <strong className="c"><small>{_header}</small></strong>

                <div style={{ "float": "right" }}><Button onClick={() => setShowActivity(false)} className="tiny-btn" size="small" type="text"><small style={{ "fontSize": "10px" }}><CloseOutlined /></small></Button></div>
                <br clear="both" />

            </div> */}


            <div>
                <div className="bc" style={{ "borderBottom": "1px solid", "height": "29px" }}>
                    <div style={{ "padding": "2px 5px", "marginTop": "0px" }} >
                        <span style={{ "fontSize": "13px" }}>
                            <Space size={5}>
                                {((session.stores_working_with.length > 1) &&
                                    <div style={{ "marginTop": "2px" }}>
                                        <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' defaultValue={defaultStore.san} onChange={(e) => setStoreSan(e)} style={{ "width": "79px" }} dropdownClassName="tinyDropdown" className="tinyAutocomplete" size="small">
                                            <Select.Option value={""}>All stores</Select.Option>
                                            {session.stores_working_with.map(item => {
                                                return (<Select.Option value={item.san}>Store #{item.store_number}</Select.Option>)
                                            })}
                                        </Select>
                                    </div>
                                )}
                            </Space>
                        </span>

                        <div style={{ "float": "right", "fontSize": "13px" }}>
                            <Space size={8}>
                                {/* <div>
                                    <Checkbox className="mini-checkbox" checked={(filters.recent)} onChange={(e) => saveFilters({ ...filters, "offset": 0, "recent": e.target.checked })} />
                                    <small> Last 60 days</small>
                                </div> */}
                                <div>
                                    <Checkbox className="mini-checkbox" onClick={(e) => saveFilters({ ...filters, "offset": 0, "orders": false, "returns": false, "sales": false, "adjustments": false, "transfers": false })} checked={(!filters.orders && !filters.returns && !filters.sales && !filters.adjustments && !filters.transfers)} value="all" />
                                    <small> All</small>
                                </div>
                                <div>
                                    <Checkbox className="mini-checkbox" onClick={(e) => saveFilters({ ...filters, "offset": 0, "orders": (!filters.orders) })} checked={filters.orders} value="orders" />
                                    <small> Orders</small>
                                </div>
                                <div>
                                    <Checkbox className="mini-checkbox" onClick={(e) => saveFilters({ ...filters, "offset": 0, "returns": (!filters.returns) })} checked={filters.returns} value="returns" />
                                    <small> Returns</small>
                                </div>
                                <div>
                                    <Checkbox className="mini-checkbox" onClick={(e) => saveFilters({ ...filters, "offset": 0, "sales": (!filters.sales) })} checked={filters.sales} value="sales" />
                                    <small> Sales</small>
                                </div>
                                <div>
                                    <Checkbox className="mini-checkbox" onClick={(e) => saveFilters({ ...filters, "offset": 0, "adjustments": (!filters.adjustments) })} checked={filters.adjustments} value="adjustments" />
                                    <small> Adjustments</small>
                                </div>
                                <div>
                                    <Checkbox className="mini-checkbox" onClick={(e) => saveFilters({ ...filters, "offset": 0, "transfers": (!filters.transfers) })} checked={filters.transfers} value="transfers" />
                                    <small> Transfers</small>
                                </div>

                                <Button onClick={() => setShowActivity("")} className="tiny-btn" size="small" type="text"><small style={{ "fontSize": "10px" }}><CloseOutlined /></small></Button>

                            </Space>

                        </div>

                    </div>

                </div>



            </div>
        </>
        )
    }


    return (
        <div style={{"maxWidth" : "590px"}}>

            {/* <div className="closebar"> */}
           
            <div className="shim" /><div className="shim" /><div className="shim" />
            <div className="bcg4" style={{ "fontWeight": "normal", "fontSize": "11px", "display": "inline", "padding": "5px 8px", "borderRadius": "2px 2px 0 0" }}>History</div>
            <Table
                size="small"
                columns={columns}
                loading={loading}
                rowClassName={(record, index) => {

                    let ret = "";
                    if (record.is_active) {
                        ret = "sales-Active";
                    } else {
                        ret = "sales-" + record.status;
                    }

                    if (record.status.substring(0, 1).toUpperCase() === "C") {
                        ret += " dimmed";
                    }

                    return ret;


                }}
                title={() => drawHeader("History (activity)")}
                className={"mediumTable bc"}
                style={{ "border": "1px solid", "borderLeft": "6px solid" }}
                scroll={{ y: 528 }}
                dataSource={results.rows}
                pagination={false}
                expandable
                expandedRowKeys={expanded}
                expandIconColumnIndex={-1}
                onExpand={(e) => { return false; }}
                expandIcon={() => { return (<></>) }}
                expandedRowRender={((record) => drawChildTable(record))}


            />




            {/* </div> */}

            {/* <conditional.true value={(results.total > 10)}>
                <div style={{ "float": "right" }}>
                    <Pagination size="small" current={((filters.offset / filters.limit) + 1)} onChange={(e, f) => setFilters({ ...filters, "offset": (e - 1) * f, "limit": f })} total={results.total} showSizeChanger />
                </div>
                <br clear="all" />
            </conditional.true> */}



        </div>
    )
}