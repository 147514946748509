import { UploadOutlined } from '@ant-design/icons';
import { Button, Image, Input, Space, Spin, Tabs, Typography, Upload } from "antd";
import { useState } from 'react';

import conditional from '../../utils/conditional';

export default function ListPDFUploader(props){
    
    const {
        removeFile = () => {},    
        uploading = false,
        pdfFile = {},
        setPdfFile = () => {},
        setChangePdf = () => {},
        showPDF = false,
        setShowPDF = () => {},
    } = props;

    
    const [pdfErrorText, setPdfErrorText] = useState(false);

    const prepUploadFile = (file) => {
        setChangePdf(true);
        setShowPDF(true);
        setPdfFile({ ...pdfFile, "file": file.file })
    }

    const prepRemoveFile = () => {
        removeFile("pdf");
        setShowPDF(false);
        setPdfFile({ ...pdfFile, "url": false })
    }

    const prepURLUpload = (url_text) => {
        if(url_text.includes(".pdf")){
            setChangePdf(true);
            setShowPDF(true);
            setPdfErrorText(false);
        }else{
            setPdfErrorText("Your URL does not contain a valid pdf. Please revise your URL and try again.");
            setChangePdf(false);
            setShowPDF(false);
        }
    }

    function changeURLText(url_text){
        if(url_text === ""){
            setPdfErrorText(false);
            setPdfFile({ ...pdfFile, "url": false })
        }else{
            setChangePdf(true);
            setPdfFile({ ...pdfFile, "url": url_text })
            setShowPDF(true);
            prepURLUpload(url_text);
        }
    }

    function showPDFImage(){
        return(<conditional.true value={showPDF}>
            <Space>
                <Image 
                    preview={false} 
                    style={{"cursor": "pointer", "marginRight": "10px", "marginTop": "7px"}}
                    width="80px" 
                    height="80px" 
                    src="https://bookmanager.com/i/pdf.png"
                />
                <Button type='danger' onClick={() => prepRemoveFile()}>Remove PDF</Button>
            </Space>
        </conditional.true>)
    }

    return (
        <Tabs className="itemTabs" size='small' style={{"width": "90%"}} type='card'>
            <Tabs.TabPane tab="Upload a PDF Directly" key={1} style={{"height": "210px"}}>
                <div>
                    <conditional.true value={showPDF === false}>
                        <Typography.Paragraph>
                            Upload PDF file, or from a URL <br /><br />
                        </Typography.Paragraph>
                        <div>
                            <Upload.Dragger accept=".pdf" showUploadList={false} customRequest={(e) => prepUploadFile(e)} style={{ "padding": "0px 20px", "height": "100px", "width": "400px" }}>
                                <div >
                                    {(uploading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                                </div>
                                <p className="ant-upload-text">
                                    Click or drag to upload PDF
                                    </p>
                                <p className="ant-upload-hint">
                                    Upload a PDF to be associated with this Catalogue.
                                </p>
                            </Upload.Dragger>
                        </div>
                        <br />
                        <conditional.true value={pdfErrorText !== false}>
                            <div className='c' style={{"padding": "5px"}}>
                                {pdfErrorText}  
                            </div>
                        </conditional.true>
                    </conditional.true>    
                    {showPDFImage()}  
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Upload via URL" key={2}>
                <Input onChange={(e) => changeURLText(e.target.value)} value={pdfFile.url !== false ? pdfFile.url : ""} placeholder="Paste PDF URL here"/>
                <Space direction='vertical'>
                    <br />
                    <conditional.true value={pdfErrorText !== false}>
                        <div className='c' style={{"padding": "5px"}}>
                            {pdfErrorText}  
                        </div>
                    </conditional.true>
                    {showPDFImage()}
                </Space>
            </Tabs.TabPane>
        </Tabs>
    )
}    