import React from "react";
import {TimePicker } from "antd";
import moment from "moment";

export default function FormatPicker(props) {

    const { value, onChange, disabled = false } = props;
    const triggerChange = (_val) => {
        onChange(_val.hour()*60 + _val.minutes());
    }

    return (
        <>
            <TimePicker disabled={disabled} onChange={(_val) => triggerChange(_val)} defaultValue={moment.utc().startOf('day').add({ minutes: value })} format="h:mm A" use12Hours />
        </>
    )
}