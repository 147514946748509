import { DownOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import { Affix, Anchor, Button, Card, Checkbox, Col, Collapse, Divider, Drawer, Dropdown, Form, Input, Menu, Pagination, Radio, Row, Select, Skeleton, Space, Switch, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { settings } from "../data";
import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import Overlay from './Overlay';
import PeerDataMarketSelector from './title/PeerDataMarketSelector';

export default function PeerSales(props) {

    const { 
        sideColumn = false,
        setShowActivity = () => { }, 
        total_stores = 0,
        isbn = "" } = props;
    const [results, setResults] = useState({ rows: [] })
    const [summary, setSummary] = useState({});
    const [loading, setLoading] = useState(true);
    
    const [session, setSession] = useSession(); 
    const [filters, setFilters] = useState({
        period: "month",
        summarize_province: false,
        location_group: session.sales_group,
        isbn: isbn,
        just_my_accounts: false
    })

    const getPeerSales = () => {
        // setLoading(true)
        apiCall("title/getPeerSalesBreakdown", filters, (_status, _result) => {
            if (_status) {
                // first row is summary data.. 
                let _summary = _result.rows.shift();
                setSummary(_summary);
                setResults(_result);
                setLoading(false)
            }
        })
    }

    const [locationGroups, setLocationGroups] = useState([])

    /* 
        Below API Call data structure
        {
            "id": "113984",
            "san": "7603800",
            "name": "Talisman Books & Gallery",
            "shared": false
        },
    */

    const getLocations = () => {
        apiCall("title/getLocationPickerData", {}, (_status, _result) => {
            if (_status) {
                setLocationGroups(_result);
            }
        })
    }

    useEffect(getLocations, []);

    

    useEffect(getPeerSales, [filters]);
    let columns = [];
    columns.push({ title: <small></small>, width: "5px", dataIndex: "blank", key: "blank" });
    if (filters.summarize_province) {
        columns.push({
            title: <small>Flag</small>, 
            dataIndex: "flag", 
            className: "cellBorder", 
            width: "40px", 
            key: "flag", 
            render: (e, f) => { 
                let flag_path = "https://cdn1.bookmanager.com/i/flags/" + f.country + "_" + f.province + ".png";
                flag_path = flag_path.replace(" ", "");
                return (<Tooltip title={<small>{f.name}</small>}><img onError={e => e.target.style.display = 'none'} style={{ "maxWidth": "17px", "cursor": "pointer" }} src={flag_path} /> {f.province}</Tooltip>) 
            }
        })
        // columns.push({
        //     title: <small>Prov / State</small>, className: "cellBorder", dataIndex: "name", key: "name", render : (e) => {
        //         return <div style={{"maxWidth" : "60px", "overflow" : "hidden", "whiteSpace" : "nowrap", "textOverflow" : "ellipsis"}}>
        //             <Tooltip title={<small>{e}</small>}>{e}</Tooltip>
        //             </div>
        //     }
        // })
    }
    if(!filters.summarize_province){
        columns.push({ title: <small>Name</small>, className:"cellBorder", dataIndex: "name", key: "name" })
    }
    columns.push({
        title: <small><nobr>OH</nobr></small>, width:"50px",  className: "cellBorder", dataIndex: "onhand", key: "onhand", render: (e) => {
            return <div style={{ "color": "#008000", "textAlign": "center" }}>{(e) ? e : ""}</div>
        }
    })
    columns.push({
        title: <small>OO</small>, width:"50px", className: "cellBorder",  dataIndex: "onorder", key: "onorder", render: (e) => {
            return <div style={{ "color": "#FFA500", "textAlign": "center" }}>{(e) ? e : ""}</div>
        }
    })
    if (!filters.summarize_province) {
        columns.push({
            title: <small>Price</small>, width:"50px", className: "cellBorder", dataIndex: "price", key: "price", render: (e) => {
                return (e) ? <nobr>{e.toString()}</nobr> : "";
            }
        })
    }
    // Add months procedurally
    let loopNumForWeekFilter = filters.period === "week" ? 11 : 12;
    for (let i = loopNumForWeekFilter; i >= 0; i--) {
        let start = (filters.period === "week") ? moment().startOf("week").add(-1, "day") : moment();
        let month = start.add(-i, filters.period).format((filters.period === "month") ? "MMM" : "MMMD");
        columns.push({ title: <small>{month}</small>, width: "40px", className: (i === 0 && filters.period !== "week") ? "cellBorder" : "", dataIndex: filters.period + i.toString(), key: filters.period + i.toString(), render: (e) => e || "" });
    }
    // Add Week bs
    if (filters.period === "week") {
        columns.push({ title: <small style={{ "color": "#888" }}>{moment().format("MMM D")}</small>, width: "40px", className: "cellBorder", dataIndex: "week", key: "week", render: (e) => { return <span style={{ "color": "#888" }}>{(e) ? e : ""}</span> } })
        columns.push({ title: <Tooltip title="Totals sales for current month + previous 12."><small><strong>Total</strong></small></Tooltip>, className: "cellBorder", dataIndex: "total", key: "total", render: (e) => { return <strong>{(e) ? e : ""}</strong> } })
    }else{
        columns.push({ title: <Tooltip title="Totals sales for current month + previous 12."><small style={{"borderLeft": "1px"}}><strong>Total</strong></small></Tooltip>, className: "cellBorder", dataIndex: "total", width:"100px", key: "total", render: (e) => { return <strong>{(e) ? e : ""}</strong> } })
    }
    // Add static columns
    // columns.push({ title: <small><strong>Total</strong></small>, className: "cellBorder", dataIndex: "total", key: "total", render: (e) => { return <strong>{(e) ? e : ""}</strong> } })
    

    // Clone columns from summary
    let summary_columns = [...columns];
    // drop the first three columns()
    summary_columns.shift();
    summary_columns.shift();
    summary_columns.shift();
    if (filters.summarize_province) {
        summary_columns.shift();
    }

    const parseData = () => {
        if (loading) {
            return ([]);
        }
        return results.rows.map((item, index) => {
            let m = { ...item };
            for (let i = 12; i >= 0; i--) {
                m[filters.period + i.toString()] = m.values[i];
            }
            return m;
        })
    }

    const parseSummary = () => {
        if (loading) {
            return ([]);
        }
        let _summary = [{ ...summary }];
        return _summary.map((item, index) => {
            let m = { ...item };
            for (let i = 12; i >= 0; i--) {
                m[filters.period + i.toString()] = m.values[i];
            }
            return m;
        })
    }

    const drawHeader = () => {
        return (
            <>
                <div>
                    <div className="bc" style={{  "borderBottom": "1px solid", "height": "29px" }}>
                        <div style={{ "padding": "2px 5px", "marginTop": "0px" }} >
                            <span style={{ "fontSize": "13px" }}>
                                <Space>
                                    <Tooltip title="Summarize grid data by province or state">
                                        <Checkbox 
                                            className="mini-checkbox" 
                                            value={filters.summarize_province} 
                                            onChange={(e) => setFilters({ ...filters, "summarize_province": e.target.checked })}>
                                            <small>Province/State</small>
                                        </Checkbox>
                                    </Tooltip>
                                    <Tooltip title="Only show accoutnts sharing with me.">
                                        <Checkbox 
                                            className="mini-checkbox" 
                                            value={filters.just_my_accounts} 
                                            onChange={(e) => setFilters({ ...filters, "just_my_accounts": e.target.checked })}>
                                            <small>Just My Accounts</small>
                                        </Checkbox>
                                    </Tooltip>
                                    
                                    <div style={{ "float": "right" }}>
                                        {locationPickerMenu()}
                                    </div>

                                    {/* <Tooltip title={"yes" + 2 + "yes"}>
                                        <div className='rowRawData' style={{"font-size": "10px", "paddingTop": "2px"}}>
                                            {total_stores} Peers
                                        </div>
                                    </Tooltip> */}
                                </Space>
                            </span>
                            <div style={{ "float": "right", "fontSize": "13px" }}>
                                <div style={{ "marginTop": "-10px" }}>
                                    <Radio.Group size="small" style={{ "marginTop": "10px" }} defaultValue={filters.period} onChange={(e) => setFilters({ ...filters, "period": e.target.value })} buttonStyle="solid">
                                        <Radio className="mini-radio" value="month"><span style={{ "fontSize": "13px" }}><small>Monthly</small></span></Radio>
                                        <Radio className="mini-radio" value="week"><span style={{ "fontSize": "13px" }}><small>Weekly</small></span></Radio>
                                        <Radio className="mini-radio" value="day"><span style={{ "fontSize": "13px" }}><small>Daily</small></span></Radio>
                                    </Radio.Group>
                                    <Button onClick={() => setShowActivity(false)} className="tiny-btn" size="small" type="text"><small style={{ "fontSize": "10px" }}><CloseOutlined /></small></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const locationPickerMenu = () => {
        return (
            <Button 
                style={{"marginRight": "5px"}}
                type="primary" 
                size="small"><small>
                    <Overlay 
                        width={450} 
                        component={
                            <PeerDataMarketSelector
                                locationGroups={locationGroups}
                                setFilters={setFilters}
                                filters={filters}/>
                        }>
                        Market: {filters.location_group + " (" + locationGroups.locations[filters.location_group].length + ")"} 
                        {/* {locationGroups.locations[filters.location_group].length} */}
                    </Overlay>
                </small>
            </Button>
        )
        // return (
        //     <Menu selectedKeys={[filters.location_group]} onClick={(e) => setFilters({ ...filters, "location_group": e.key })}>
        //         {locationGroups.map((item, index) => {
        //             return (<Menu.Item key={item}>{item}</Menu.Item>)
        //         })}
        //     </Menu>
        // )
    }

    return (
        <>
            {!loading && 
                <div>
                    <div className="shim" /><div className="shim" /><div className="shim" />
                    <div 
                        className="bcg4" 
                        style={{ 
                            "fontWeight": "normal", 
                            "fontSize": "11px", 
                            "display": "inline", 
                            "padding": "5px 8px", 
                            "borderRadius": "2px 2px 0 0" }}>
                        Raw Peer Data
                    </div>
                    <Table
                        columns={columns}
                        title={() => drawHeader()}
                        loading={loading}
                        className={"peerTable bc"}
                        style={{ "border": "1px solid", "borderLeft": "6px solid"}}
                        dataSource={parseSummary().concat(parseData())}
                        scroll={{ y: 240 }}
                        pagination={false}
                        onExpand={(e) => { return false; }}
                    />
                    <div className='shim'/>
                </div>
            }
        </> 
    )
}