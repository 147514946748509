import React, { useState } from "react";
import { Form, Typography, Divider, Input, Button, Row, Col, Space, Checkbox, Switch, Radio, Alert } from "antd";
import WebstoreItem from "../WebstoreItem";
import Editor from "react-medium-editor";
import Region from "../Region";
import AddRegion from "../AddRegion";
import Multiship from "../Multiship";
import Overlay from "../../../components/Overlay";
export default function ShippingSection(props) {

    const { ws, form, regions, setChanged = () => { } } = props;
    const default_ws_message_ship_cart = "Shipping charges still need to be calculated and then added to your order. We will inform you of the final amount before shipping your order."
    const default_ws_message_ship = "We will contact you during normal business hours to confirm availability, final pricing, taxes or shipping charges (if applicable.) We can be reached by phone at @@phone@@ or by email at @@email@@ should you have any questions or concerns."


    const [multiship, setMultiship] = useState((ws.multiship_enabled));
    const showMultishipInfo = (!ws.multiship_enabled);

    const toPrice = (_n) => {

        return Number.parseFloat(_n).toFixed(2);

    }

    const drawRegions = () => {


        return (<>
            {regions.map(function (region, index) {
                return (<Region form={form} data={region} />)
            })}
        </>)
            ;
    }



    return (
        <>

            <div id="shipping" style={{ "padding": "10px 30px" }}>

                <Typography.Title level={4}>Shipping</Typography.Title>
                <Divider />

                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Enable local delivery */}
                        <div id="ws_local_delivery_enabled" />
                        <WebstoreItem
                            name="ws_local_delivery_enabled"
                            value={(ws["ws_local_delivery_enabled"]) ? true : false}
                            heading="Enable local delivery"
                            description={<div>Customer's will be offered another shipping option outside of your regular <strong>Shipping Providers / Cost calculator.</strong> Customers will only be able to checkout with Local Delivery if their ship to postal/ZIP code qualifies. They will be warned if their address does not qualify so they can try an alternate address that may qualify.</div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Local delivery message */}
                        <div id="ws_message_local_cart" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Local delivery message</Typography.Title>
                                <Typography.Paragraph type="secondary">This message is displayed to customers at checkout, as well as in the customer’s order confirmation email.</Typography.Paragraph>
                                <Typography.Paragraph type="secondary">Use this message to list the qualifications and details for local delivery. This should include the cities, areas, or distance from the store that you are willing to deliver to. If you are doing the delivery yourself (not using a shipping courier), it is best to also provide an estimated delivery time or order cut-off, along with any days you are not delivering. If you are using a shipping service, specify that service so your customers know what to expect. It may also be worth noting that apartment buildings or gated communities with mail rooms only accessible to valid courier services may pose a problem for deliveries done by store staff.</Typography.Paragraph>
                                <Form.Item name="ws_message_local_cart" initialValue={ws["ws_message_local_cart"]}>
                                    <Input.TextArea maxLength={300} placeholder="Example message: Local delivery is available Monday through Saturday for most Kelowna, Westbank, and West Kelowna addresses. There is a $10 delivery fee for orders under $99 (before taxes), while orders over $99 deliver for free. Orders must be prepaid in advance before delivery. Most in-stock orders are delivered same day if placed before 3pm, and will be dropped off between 4-6pm. Orders will be placed on your doorstep, so please specify in the check out instructions area if you would like us to text, call, ring the doorbell, or knock when we arrive. Out of stock orders will be delivered once they arrive, and we will notify you when they are ready for delivery. If you live in a gated community or apartment with a locked mail room, Local Delivery is unfortunately not available, so please select another shipping options instead." showCount style={{ "maxWidth": "500px" }} rows={8} >
                                    </Input.TextArea>
                                </Form.Item>

                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Local delivery postal or ZIP codes */}
                        <div id="ldc_local_delivery_code" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Local delivery postal or ZIP codes</Typography.Title>
                                <Typography.Paragraph type="secondary">Local delivery will only be available to postal or ZIP codes on this list. There is no limit to the number of postal codes on this list. Separate codes with commas. You can input only the first three digits of a postal code if you want to target a larger area. For example, both <strong>V1Y7G9</strong> and <strong>V1Y</strong> will be accepted. <br /><div className="shim" /><em>We recommend saving a copy of this list in case it needs to be restored.</em></Typography.Paragraph>
                                <Form.Item name="ldc_local_delivery_code" noStyle initialValue={ws["ldc_local_delivery_code"]}>
                                    <Input.TextArea placeholder="Example: V1W,V1Y7G9,11231,V1V 1H1,07802" style={{ "maxWidth": "500px" }} rows={3} >
                                    </Input.TextArea>
                                </Form.Item>
                                <div className="shim"></div><div className="shim"></div>
                                <Button size="small" onClick={() => form.setFieldsValue({ "ldc_local_delivery_code": "" })} type="primary"><small>Clear</small></Button>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Find postal codes */}
                        <div id="ldc_local_find_postal_codes" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Find postal codes</Typography.Title>
                                <Typography.Paragraph type="secondary">You can use the following third-party site to generate a list based on a radius or drawn boundaries on a map:</Typography.Paragraph>
                                <Typography.Link><a target="_blank" href="https://www.freemaptools.com/find-canada-postcodes-inside-radius.htm">Canadian postal codes by radius from a point</a></Typography.Link>
                                <br />
                                <Typography.Link><a target="_blank" href="https://www.freemaptools.com/find-canada-postcodes-inside-user-defined-area.htm">Canadian postal codes inside user defined boundaries</a></Typography.Link>
                                <br />
                                <Typography.Link><a target="_blank" href="https://www.freemaptools.com/find-zip-codes-inside-radius.htm">US ZIP codes by radius from a point</a></Typography.Link>
                                <br />
                                <Typography.Link><a target="_blank" href="https://www.freemaptools.com/find-zip-codes-inside-user-defined-area.htm">US ZIP codes inside user defined boundaries</a></Typography.Link>
                                <br />
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Local delivery postal or ZIP codes */}
                        <div id="ws_delivery_local_availability" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>"Local Delivery is available to..." message:</Typography.Title>
                                <Typography.Paragraph type="secondary">Specify the areas, boundaries, distance from the store, distance from a point, or cities that qualify for local delivery. This message is displayed before checkout, at the step where customers choose their pick up or delivery preference. This message will begin with “Local delivery is available to” followed by the below text:</Typography.Paragraph>
                                <Form.Item name="ws_delivery_local_availability" initialValue={ws["ws_delivery_local_availability"]}>
                                    <Input maxLength={100} style={{ "maxWidth": "500px" }}></Input>
                                </Form.Item>

                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Find postal codes */}
                        <div id="ws_delivery_local_fee" />
                        <div id="ws_delivery_local_minimum" />
                        <div id="ws_delivery_local_free_enabled" />
                        <div id="ws_delivery_local_free" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Local delivery threshold and fees</Typography.Title>
                                <Typography.Text>Local delivery fee &nbsp;
                                    <Form.Item name="ws_delivery_local_fee" noStyle initialValue={toPrice(ws["ws_delivery_local_fee"])}>
                                        <Input size="small" prefix="$" style={{ "maxWidth": "65px" }}></Input>
                                    </Form.Item>
                                </Typography.Text>
                                <br /><div className="shim"></div><div className="shim"></div>
                                <Typography.Text>Order total must exceed &nbsp;
                                    <Form.Item name="ws_delivery_local_minimum" noStyle initialValue={toPrice(ws["ws_delivery_local_minimum"])}>
                                        <Input size="small" prefix="$" style={{ "maxWidth": "65px" }}></Input>
                                    </Form.Item> &nbsp;to qualify.</Typography.Text>
                                <br /><div className="shim"></div><div className="shim"></div>
                                <Typography.Text>
                                    <Form.Item valuePropName='checked' initialValue={(ws["ws_delivery_local_free_enabled"]) ? true : false} name="ws_delivery_local_free_enabled" noStyle>
                                        <Checkbox />
                                    </Form.Item> Enable free local delivery over a set amount</Typography.Text>
                                <br /><div className="shim"></div><div className="shim"></div>
                                <Typography.Text>Free delivery if order total exceeds: &nbsp;
                                    <Form.Item name="ws_delivery_local_free" noStyle initialValue={toPrice(ws["ws_delivery_local_free"])}>
                                        <Input size="small" prefix="$" style={{ "maxWidth": "85px" }}></Input>
                                    </Form.Item>
                                </Typography.Text>
                                <br />
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Enable shipping */}
                        <div id="ws_delivery_ship_enabled" />
                        <WebstoreItem
                            name="ws_delivery_ship_enabled"
                            value={(ws["ws_delivery_ship_enabled"]) ? true : false}
                            heading="Enable shipping"
                            description={<div>If disabled, your customers will only be offered <strong>Pick up in-store</strong> or <strong>Local Delivery</strong> (if enabled).</div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Shipping cost exception list */}
                        <div id="ws_shipping_base_rate_ignore_csv" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Shipping cost exception list</Typography.Title>
                                <Typography.Paragraph type="secondary">ISBNs in this list will not be factored into shipping rates. Separate product numbers by commas.</Typography.Paragraph>
                                <Form.Item initialValue={ws["ws_shipping_base_rate_ignore_csv"]} name="ws_shipping_base_rate_ignore_csv" >
                                    <Input.TextArea style={{ "maxWidth": "500px", "fontFamily": "ApercuMono" }} rows={3} >
                                    </Input.TextArea>
                                </Form.Item>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Shipping messages */}
                        <div id="ws_message_ship_cart" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Shipping message (Before checkout)</Typography.Title>
                                <Typography.Paragraph type="secondary">This message will display alongside the standard courier shipping options. Enter shipping details that you want your customer to know BEFORE they checkout, such as the regions you ship to, the various shipping services you use, costs, delivery times for couriers, how long it takes from the time you receive an order until you ship it out, etc.</Typography.Paragraph>


                                <div className="ant-input" style={{ "padding": "10px", "borderRadius": "2px", "minHeight": "150px", "maxHeight": "150px", "overflow": "auto" }}>
                                    <Editor
                                        tag="p"
                                        forcePlainText={true}
                                        aria-multiline="true"
                                        role="textbox"
                                        data-placeholder="Example:
                                            We ship to Canada and the US only, using Purolator and Canada Post. Oversized items may incur additional charges, and we will contact you with an updated quote before processing if that's the case. Orders ship out within 24 hours of receiving payment. See the About -> Shipping menu for rates and ETAs.
                                            Please Note:
                                            Schools & Institutional Accounts: We offer discounted rates for large orders and we will supply a custom quote based on each order. Ignore shipping costs generated at the time of checkout."
                                        text={(ws["ws_message_ship_cart"]) ? ws["ws_message_ship_cart"] : ""}
                                        onChange={(e) => {
                                            setChanged(true)
                                            form.setFieldsValue({ ws_message_ship_cart: e })
                                        }}

                                        options={{ toolbar: { buttons: ['bold', 'italic', 'underline'] } }}
                                    />
                                </div>


                                <Form.Item noStyle initialValue={(ws["ws_message_ship_cart"]) ? ws["ws_message_ship_cart"] : ""} name="ws_message_ship_cart" >
                                    <Input.TextArea maxLength={1200} showCount hidden={((ws["ws_message_ship_cart"]))} style={{ "maxWidth": "500px" }} rows={3} >
                                    </Input.TextArea>
                                </Form.Item>
                                <div className="shim"></div><div className="shim"></div>
                                {/* <Button size="small" onClick={() => form.setFieldsValue({ "ws_message_ship_cart": default_ws_message_ship_cart })} type="primary"><small>Use default</small></Button> */}
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Shipping cost exception list */}
                        <div id="ws_message_ship" />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Title level={5}>Shipping message (After checkout)</Typography.Title>
                                <Typography.Paragraph type="secondary">This message will show up in the customer's order confirmation email. Enter any details that your customers may want to know AFTER they have checked out.</Typography.Paragraph>


                                <div className="ant-input" style={{ "padding": "10px", "borderRadius": "2px", "minHeight": "150px", "maxHeight": "150px", "overflow": "auto" }}>
                                    <Editor
                                        tag="p"
                                        forcePlainText={true}
                                        aria-multiline="true"
                                        role="textbox"
                                        data-placeholder="Example: Your order will be shipped out within 24 hours after we receive payment. We will let you know when your order has shipped, and provide a tracking number and an ETA."
                                        text={(ws["ws_message_ship"]) ? ws["ws_message_ship"] : ""}
                                        onChange={(e) => {
                                            setChanged(true)
                                            form.setFieldsValue({ ws_message_ship: e })
                                        }}

                                        options={{ toolbar: { buttons: ['bold', 'italic', 'underline'] } }}
                                    />
                                </div>

                                <Form.Item noStyle initialValue={(ws["ws_message_ship"]) ? ws["ws_message_ship"] : ""} name="ws_message_ship" >
                                    <Input.TextArea hidden maxLength={1200} showCount style={{ "maxWidth": "500px" }} rows={4} >
                                    </Input.TextArea>
                                </Form.Item>
                                <div className="shim"></div><div className="shim"></div>
                                {/* <Button size="small" type="primary" onClick={() => form.setFieldsValue({ "ws_message_ship": default_ws_message_ship })}><small>Use default</small></Button> */}
                            </div>
                        </Space>
                    </Col>
                </Row>

                <Divider dashed />

                {((multiship) &&
                    <Row gutter={[30, 30]}>
                        <Col md={24} xl={12}>
                            {/* Enable local delivery */}
                            <div id="ws_batch_shipping" />
                            <WebstoreItem
                                name="ws_batch_shipping"
                                value={(ws["ws_batch_shipping"]) ? true : false}
                                heading="Enable batch shipping"
                                description={<div>Enabling will offer customers the option to ship their order in batches.  In-store items can be shipped right away, while backordered items will be shipped when the remainder of their order has arrived. Shipping prices will be affected, and the price difference will be displayed to the customer before selecting the option.</div>}
                            />
                        </Col>

                    </Row>
                )}


                <Divider dashed />


                {((multiship) &&

                    <>
                        <Row gutter={[30, 30]}>
                            <Col md={24} xl={24}>
                                {/* Shipping cost calculator */}
                                <div id="multiship" />
                                <Space>
                                    <div style={{ "width": "35px" }}></div>
                                    <div>

                                        {((showMultishipInfo) && <>
                                            <Alert
                                                style={{ "width": "935px" }}
                                                showIcon
                                                // message="Warning Text"
                                                description="Enabling multiple courier shipping will replace the current legacy shipping settings. "
                                                type="info"
                                            />
                                            <br />
                                        </>)}

                                        <Typography.Title level={5}>Shipping Providers / Cost calculator</Typography.Title>
                                        <Typography.Paragraph type="secondary">These options control how shipping costs are calculated for customers. First add a <strong>Region</strong> (Country or Province/State/Territory), then add <strong>Provider</strong>(s) for that region. Providers could be a specific courier like UPS, a specific service from a courier like FedEx Next Day, or a general term like Standard Shipping if you use multiple couriers and simply pick the cheapest. The Provider's name is what is displayed to customers during checkout, so choose something clear but concise. After a Provider name is set, you can configure costs. Setting <strong>Minimum shipping cost</strong> and <strong>Each additional item</strong> to $0.00 means free shipping for this Provider. We recommend setting the fallback <strong>All Other Regions</strong> to <strong>Disallowed.</strong></Typography.Paragraph>
                                        <Typography.Paragraph type="secondary">Use the ISBN field to have a provider-specific ISBN for shipping sent to Bookmanager. For example, <strong>OS-STD</strong> could be used for your standard shipping provider, and <strong>OS-MED</strong> could be for Media Mail. This way, when orders drop into Bookmanager, the ISBN will make it clear which shipping provider the customer chose. You will need to edit the new OS- ISBN in Bookmanager to give it your shipping class and a receipt-friendly title. If left blank, the default 0 shipping ISBN will be sent.</Typography.Paragraph>
                                        <Typography.Paragraph type="secondary">Regions with the same Provider settings will automatically be grouped together. This allows you to make Provider changes to a group of regions without having to do so on a per region basis. You can separate a Region from the group by clicking on one of the Regions in the grouping.</Typography.Paragraph>
                                        {/* <Typography.Paragraph type="secondary">By default, all products that a customer can normally browse or search for on your webstore will qualify for a shipping Provider. If you would like a Provider to only be offered for specific products, first create a <em>Browse Filter Preset</em> under the <strong>Purchasing → Browse menu.</strong> <em>Browse Filter Presets</em> can be configured to exclude products, which may be easier in terms of setup if you only wish to exclude a small selection of products. For example, if setting up Media Mail as a Provider, you can create a <em>Browse Filter Preset</em> that excludes your non-book BM classes instead of trying to include all your book classes.</Typography.Paragraph> */}
                                        <Multiship setChanged={setChanged} form={form} regions={ws.ws_multiship_json} />
                                    </div>
                                </Space>
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                            </Col>
                        </Row>
                    </>
                )}


                <div className={(multiship) ? "disabled" : ""}>

                    {((!multiship || showMultishipInfo) &&
                        <Row gutter={[30, 30]}>
                            <Col md={24} xl={24}>
                                {/* Shipping cost calculator */}
                                <div id="cost_calculator" />
                                <Space>
                                    <div style={{ "width": "35px" }}></div>
                                    <div>
                                        {(!(multiship) &&
                                            <div style={{ "float": "right", "marginTop": "-3px" }}>
                                                <Button onClick={() => setMultiship(!multiship)} type="primary" size="small"><small>Upgrade to multiple courier shipping</small></Button>
                                            </div>
                                        )}
                                        <Typography.Title level={5}>Shipping cost calculator</Typography.Title>
                                        <Typography.Paragraph type="secondary">These options control how shipping costs are calculated for customers. "All Other Regions" is reserved as a fallback in case anyone tries to place an order for a shipping destination that doesn't match any of the criteria already set up. Similarily, you can add an entire country like "Canada" or "United States", and specify rules for more specific states or provinces by entering in "Alaska" or "British Columbia" in case there are exceptions. In the case that they enter something other than those two examples, the country itself will be considered the fallback. Use the Disallowed option to exclude a region from shipping, notifying your customer before they fully checkout that you do not ship to this region.</Typography.Paragraph>
                                        <Row gutter={[30, 30]}>
                                            {drawRegions(regions)}
                                        </Row>
                                        <div className="shim"></div><div className="shim"></div>
                                        <Typography.Text><strong>Add new region</strong> </Typography.Text>
                                        <div className="shim"></div>
                                        <Typography.Paragraph type="secondary">Add a new region by selecting a country (and region for US and CAN)</Typography.Paragraph>
                                        {/* <AddRegion setUpdate={props.setUpdate} update={props.update} setRegions={props.setRegions} regions={regions} /> */}
                                        {/* <AddRegion2 setUpdate={props.setUpdate} update={props.update} setRegions={props.setRegions} regions={regions} /> */}
                                    </div>
                                </Space>
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                            </Col>

                        </Row>

                    )}

                </div>








            </div>



        </>
    )
}