import { Button, Checkbox, Divider, Form, message, Select, Space, Typography } from "antd";
import { useEffect, useState } from "react";

import { exceloptions } from "../../../data";
import conditional from "../../../tools/conditional";
import { apiCall, apiDownload } from "../../../utils/Api";
import { useSession } from "../../../utils/Session"

export default function MultiSelectExcel(props ){
    const {
        close = () => {},
        isbns,
        setSelectedRowKeys = () => {}, 
    } = props
    const [session, setSession] = useSession(); 

    const initOptions = exceloptions

    if(session.permissions.titlelist_onhand){
        initOptions.push(
            {
                label: "Include On hand / On Order",
                value: "show_oh_oo",
                checked: false,
            }
        )
    }

    const [options, setOptions] = useState(initOptions)

    const onFormFinish = (form_data) => {
        form_data["isbns_json"] = JSON.stringify(isbns);
        apiDownload(form_data, "title/getExcelOnIsbns")
        setSelectedRowKeys([]);
        close();
    }

    const onChange = (valid, _ind) => {
        let _options = [...options];
        _options[_ind].checked = valid;
        setOptions(_options);
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Create Excel</Typography.Title>
                <Divider style={{"marginBottom" : "10px"}} />
                <p>To export these titles to Excel format, choose from the options below and click "Generate"</p>
                <div className="shim" />
                <Form onFinish={(f) => onFormFinish(f)}>
                    <Space direction="vertical">
                        {options.map((item, index) => {
                            return (
                                <Form.Item valuePropName="checked" initialValue={item.checked} name={item.value} noStyle >
                                    <Checkbox onChange={(e) => onChange(!item.checked, index)}  >&nbsp; {item.label}</Checkbox>
                                </Form.Item>
                            )
                        })}
                    </Space>
                    <Divider />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Generate</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </div>
        </>
    )
}