import { LoadingOutlined } from '@ant-design/icons';
import { Button, Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { apiCall } from "./utils/Api";
import { useSession } from "./utils/Session";

export default function ReloadStore() {
    const location = useLocation();
    const history = useHistory(); 
    const [, setSession] = useSession(); 
    const {id = false, path = "/browse"} = location.state; 
    const [error, setError] = useState("");

    const changeCorp = () =>{
        if(id){
            apiCall("account/setCorp", {corp_id : id}, (_status, _result) =>{
                if(_status){
                    setSession(_result);
                    window.store_id = _result.store_id; 
                    apiCall("store/getSettings", {}, (_s, _r) =>{
                        window.sitesettings = _r;
                     
                        history.push(path);
                    })
                } else {
                    setError(_result.error);
                }
            })
        } else {
            history.back(); 
        }
    }

    useEffect(changeCorp, [history, id, path, setSession]);

    if(!id){
        return(<></>)
    }
    
    const loadAnimation = <LoadingOutlined style={{ fontSize: 45 }} spin />

    return (
        <>      
           <div className="shim" style={{ "minHeight": "120px", "height" : "calc(50vh - 250px)" }}></div>
            <Result
                status={(error) ? "warning" : "success"}
                icon={(error) ? false : <Spin indicator={loadAnimation} />}
                title={(error) ? "Failed to switch store" : "Reloading..."}
                subTitle={(error) ? <>{error} <br /><br /> <Button onClick={() => history.push(path)}>Return to site</Button></> : "This will only take a moment..."}
            />
        </>
    )
}


