import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ListCreatePdf from "../components/lists/ListCreatePdf";
import ListPrint from "../components/lists/ListPrint";
import PreviousOrder from "../components/PreviousOrder";
import ProcessPDF from "../components/ProcessPdf";
import Slider from "../components/Slider";
import Title from "../components/title/Title";
import TitleDetails from "../components/title/TitleDetails";
import Blank from "../layouts/Blank";
import Default from "../layouts/Default";
import Left from "../layouts/Left";
import LoginLayout from "../layouts/LoginLayout";
import Standard from "../layouts/Standard";
import URLShortcuts from "../layouts/URLShortcuts";
import MyAccountsPage from "../pages/administration/MyAccountsPage";
import PermissionsPage4 from "../pages/administration/PermissionsPage4";
import SANLists from "../pages/administration/SANLists"
import StockTitleUploadPage from "../pages/administration/StockTitleUploadPage";
import AnalyticsTableHtml from "../pages/analytics/AnalyticsTableHtml";
import BrowsePage from "../pages/BrowsePage";
import BusinessDirectoryPage from "../pages/BusinessDirectoryPage";
import CartPage from "../pages/CartPage";
import CataloguesPage from "../pages/CataloguesPage";
import MyReps from "../pages/content/MyReps";
import MyListsPage from "../pages/MyListsPage";
import PickListPage from "../pages/PicklistPage";
import PrintGiftRegistry from "../pages/PrintGiftRegistry";
import EdiActivityPage from "../pages/reports/EdiActivityPage";
import ManageTitleCommentsPage from "../pages/reports/ManageTitleCommentsPage"
import MissingDataPage from "../pages/reports/MissingDataPage";
import TrackingISBNsPage from "../pages/reports/TrackingISBNsPage";
import WebstoreSettingsPage from "../pages/settings/WebstoreSettingsPage";
import StockCheckPage from "../pages/StockCheckPage";
import SuggestedCart from "../pages/SuggestedCartsPage";
import SwitchPage from "../pages/SwitchPage";
import ReloadStore from "../ReloadStore";
import Ani from "../utils/Ani";
import { apiCall } from "./Api";
import LiveToggle from "./LiveToggle";
import { useSession } from "./Session";
import { objectToPath } from "./Utils";

function SiteRouter(props) {

    const {dark_mode = ""} = props; 
    const [collapsed, setCollapsed] = useState(true);
    const [session, setSession] = useSession();
    const [redirect, setRedirect] = useState("");
    const location = useLocation(); 
    const history = useHistory();

    let _defaultpath = "/catalogues/my-catalogues";

    if (session.hasOwnProperty("filter_preset_defaults")) {
        if (session.filter_preset_defaults.hasOwnProperty("mylists") ) {
            _defaultpath = "/catalogues/my-catalogues" + objectToPath(session.filter_preset_defaults.mylists.selections);
        }
    }
    // Log Errors
    const logError = (e, s, l, c, n) => {
        if (e === "Script error.") {
            return;
        }
        let err = {};
        err.location = (location && location.pathname) ? location.pathname : "no location";
        err.user_agent = (navigator && navigator.userAgent) ? navigator.userAgent : "no agent";
        err.error_info = (e) ? e : "no error info";
        err.source = (s) ? s : "no source";
        err.lineno = (l) ? l : "no lineno";
        err.colno = (c) ? c : "no colno";
        err.error = (n) ? n : "no error";
        apiCall("log/error", err, () => { });
    }

    const errorListener = () => {
        window.onerror = (e, s, l, c, n) => {
            logError(e, s, l, c, n);
        }
    }

    useEffect(errorListener, []);

    const drawRoutes = () => {
        if (!session.logged_in) {
            return (
                <Switch>
                    {/* Available without login */}
                    <Route path="/switch/:ses" children={<Blank><SwitchPage /></Blank>} />
                    <Route path="/stockcheck/:isbn/:session/:store_id" children={<Blank><StockCheckPage /></Blank>} />
                    {/* Send all others to login screen */}
                    <Route exact path="/">
                        <LoginLayout setRedirect={setRedirect} dark={props.dark} redirect={history.location.pathname} />
                    </Route>
                    <Redirect from="*" to="/" />
                </Switch>
            )
        }
        if (redirect) {
            _defaultpath = redirect;
        }
        const drawDarkMode = () => {
            if(location.pathname.includes("/stockcheck/")){
                return <></>
            } else {
                return dark_mode; 
            }
        }
        return (
            <>
                <Switch>
                    {/* Test */}
                    <Route path="/table/" children={<AnalyticsTableHtml />} />
                    <Route path="/picklist/:action/:ee_def_id" children={<Blank><PickListPage /></Blank>} />
                    <Route path="/stockcheck/:isbn/:session/:store_id" children={<Blank bg_color="#fff"><StockCheckPage /></Blank>} />
                    <Route path="/switch/:ses" children={<Blank><SwitchPage /></Blank>} />
                    <Route path="/item/:url_isbn" children={<Blank><Title /></Blank>} />
                    <Route path="/reload" children={<Blank><ReloadStore /></Blank>} />
                    <Route path="/excel/:process_id" children={<Blank><ProcessPDF /></Blank>} />
                    <Route path="/pdf/:process_id" children={<Blank><ProcessPDF /></Blank>} />
                    <Route path="/test-pdf" children={<ListCreatePdf />} />
                    <Route path="/print/list/:list_id" children={<Blank><ListPrint /></Blank>} />
                    {/* Slider */}
                    <Route path="/slider" children={<Left collapsed={collapsed} setCollapsed={setCollapsed}>
                        <Ani>
                            <div style={{ "padding": "50px" }}>
                                <Slider />
                            </div>
                        </Ani>
                    </Left>}
                    />
                    <Route path="/title" children={<Left collapsed={collapsed} setCollapsed={setCollapsed}><Ani>
                        <div style={{ "padding": "30px" }}>
                            <TitleDetails isbn="ATEST00000000" />
                            {/* <TitleDetails isbn="9780062060624" /> */}
                        </div>

                    </Ani></Left>} />

                    {/* Admin */}
                    <Route path="/administration/group-lists" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SANLists /></Default>} />
                    <Route path="/administration/permissions" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><PermissionsPage4 /></Default>} />
                    <Route path="/administration/accounts" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><MyAccountsPage /></Default>} />
                    {/* {((session.interm_upload_access) && <Route path="/administration/stock-title-uploads" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><StockTitleUploadPage /></Default>} />)} */}
                    <Route path="/administration/stock-title-uploads" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><StockTitleUploadPage /></Default>} />

                    {/* Report */}
                    <Route path="/reports/tracking/" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><TrackingISBNsPage /></Default>} />
                    <Route path="/reports/edi-activity" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><EdiActivityPage /></Default>} />
                    <Route path="/tools/missing-data" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><MissingDataPage /></Default>} />
                    <Route path="/catalogues/manage-title-comments" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><ManageTitleCommentsPage /></Default>} />
                    {/* <Route path="/purchasing/cart" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><CartPage /></Default>} /> */}
                    
                    {/* Catalogues */}
                    <Route path="/catalogues/my-catalogues" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><MyListsPage /></Default>} />
                    <Route path="/catalogues/my-company-catalogues" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><MyListsPage is_company={true}/></Default>} />
                    <Route path="/catalogues/public-catalogues" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><CataloguesPage collapsed={collapsed} /></Default>} />

                    {/* <Route path="/purchasing/business-directory" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BusinessDirectoryPage collapsed={collapsed} /></Default>} />
                    <Route path="/purchasing/my-reps" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><MyReps /></Default>} /> */}
                    
                    {/* Browse */}
                    <Route path="/browse" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BrowsePage /></Default>} />
                    <Route path="/orders/suggested-orders" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SuggestedCart /></Default>} />

                    <Route path="/url-shortcuts" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><URLShortcuts /></Default>} />
                    <Route path="/webstore" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><WebstoreSettingsPage /></Default>} />
                    {/* <Route path="/" children={<LoginLayout />} /> */}
                    <Redirect from="/" to={_defaultpath} />
                    <Redirect from="" to={_defaultpath} />
                    <Route path="*" children={<Standard />} />
                </Switch>
                <LiveToggle />
            </>
        )
    }
    //to be kept in case there's something useful here in the future
    //{/* Settings */}
    //<Route path="/reports/uscan-price-comparison" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><UsCanPriceComparisonPage /></Default>} />
    // <Route path="/reports/analytics" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><AnalyticsPage /></Default>} />
    // <Route path="/reports/backups" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BackupPage /></Default>} />
    // <Route path="/reports/calandar-reports" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><CalandarReportsPage /></Default>} /> 
    return drawRoutes();

}

export default SiteRouter;
