import { FolderFilled, FolderOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal, Radio, Space, Typography } from "antd";
import React, { useState } from "react";

import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { disabledColor, highlightColor } from "../../utils/Utils";

export default function ListFolder(props) {

    const { results, setResults, list_id = "", folder = "", icon = false } = props;


    // 12 chars
    const [selectedFolder, setSelectedFolder] = useState(folder);
    const [visible, setVisible] = useState(false)
    const [folders, setFolders] = useState([]);

    const fetchFolders = () => {
        if (folders.length > 0) {
            setVisible(!visible);
        }
        apiCall("titlelist/getFolders", {}, (_status, _result) => {
            if (_status) {
                setFolders(_result);
                setVisible(true);
            }
        })
    }


    const updateItem = (_list) => {

        props.getLists();
        // let newArr = [...results];
        // newArr[results.findIndex(item => item.list_id === _list.list_id)] = _list;
        // setResults(newArr);
    }

    const changeFolder = (_folder, _close = false) => {
        apiCall("titlelist/changeFolder", { list_id: list_id, folder: _folder }, (_status, _result) => {
            if (_status) {
                setSelectedFolder(_folder);
                updateItem(_result);
                if (_close) {
                    setVisible(false);
                }
            }
        })
    }


    return (<>
        <Modal
            width={250}
            footer={false}
            visible={visible}
            onCancel={() => setVisible(false)}
            onVisibleChange={(e) => setVisible(false)}
        >
            <Typography.Title style={{ "lineHeight": "17px" }} level={5}>Move to folder</Typography.Title>
            <Divider style={{ "margin": "10px 0px" }} />
            <Radio.Group onChange={(e) => changeFolder(e.target.value)} value={selectedFolder}>
                <Space direction="vertical">
                    {folders.map((itm) => {
                        return (<Radio value={itm}>{itm}</Radio>)
                    })}
                </Space>
            </Radio.Group>
            <Divider dashed style={{ "margin": "10px 0px" }} />
            <Typography.Title style={{ "lineHeight": "14px" }} level={5}><small>Create new</small></Typography.Title>
            <Input.Search maxLength={12} onKeyDown={(e) => {
                if (e.key === "Enter") {
                    changeFolder(e, true);
                }
            }} onSearch={(e) => changeFolder(e, true)} placeholder={"Folder name"} enterButton={"Add"} />

        </Modal>

        <conditional.true value={(icon)}>
            <Button onClick={() => fetchFolders()} size="small" type="link" icon={(!visible) ? <FolderOutlined style={{ "color": disabledColor }} /> : <FolderFilled style={{ "color": highlightColor }} />} />
        </conditional.true>

        <conditional.true value={(!icon)}>
            <Button onClick={() => fetchFolders()} size="small"><small>{selectedFolder}</small></Button>
        </conditional.true>


    </>
    )
}