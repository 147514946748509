import React, { useState, useEffect } from "react";
import { Typography, Table, Divider } from "antd";
import { apiCall } from "../../utils/Api";
import conditional from "../../tools/conditional";
import Title from "../title/Title";
import { addCommas, bmDate } from "../../utils/Utils";
import Paginate from "../Paginate";
import InvoiceActivity from "./InvoiceActivity";
import Overlay from "../Overlay";

export default function PurchaseActivity(props) {
    const { invoice_is_return = false, supplier, purchase_order, close = () => { } } = props;
    const [results, setResults] = useState({ customer_info: {}, total: 0, rows: [] });
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState([]);
    const [drawType, setDrawType] = useState("")

    const [paginate, setPaginate] = useState({ current: 1, pagesize: 10, offset: 0 });

    const [filters, setFilters] = useState({
        supplier: supplier,
        purchase_order: purchase_order,
        limit: 1000,
        offset: 0,
        invoice_is_return : invoice_is_return,
    });

    const getCustomerActivity = () => {

        let _filters = { ...filters }
        _filters.limit = paginate.pagesize;
        _filters.offset = paginate.offset;
        setLoading(true);
        apiCall("activity/getByPurchaseOrder", _filters, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getCustomerActivity, [paginate]);

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }



    const columns = [
        {
            title: <small>Date</small>, dataIndex: "date", key: "date", width: "60px", render: (e) => {
                return (e) ? <nobr>{bmDate(e * 1000)}</nobr> : "";
            }
        },
        { title: <small>Status</small>, width: 50, dataIndex: "status", key: "status" },
        { title: <small>Qty</small>, width: 40, dataIndex: "quantity", key: "quantity" },
        { title: <small>Inv.</small>, width: 60, dataIndex: "invoice_price", key: "invoice_price", render: (e) => { return (e) ? "$" + e.toString() : "" } },
        { title: <small>Discount</small>, width: 60, dataIndex: "invoice_discount", key: "invoice_discount", render: (e) => { return (e) ? e.toString() + "%" : "" } },
        { title: <small>Profit</small>, width: 50, dataIndex: "profit_margin", key: "profit_margin", render: (e) => { return (e) ? e.toString() + "%" : "" } },
        { title: <small>Sell</small>, width: 60, dataIndex: "sell_price", key: "sell_price", render: (e) => { return (e) ? "$" + e.toString() : "" } },
        {
            title: <small>Title</small>, className: "nowrap", dataIndex: "title", key: "title"
        },
        {
            title: <small>Invoice</small>, dataIndex: "invoice_display", key: "invoice_display", render: (e, f) => {

                // return (e) ? e : ""
                return (e && f.invoice) ? <nobr><Overlay component={<InvoiceActivity invoice_is_supplier={f.invoice_is_supplier} close={setExpanded} invoice={f.invoice} />}><a>{e}</a></Overlay></nobr> : <>{e}</>;
              
            }
        },
        { title: <small>Clrk</small>, width: 50, dataIndex: "cashier", key: "cashier" },
        { title: <small>Notes</small>, width: "200px", dataIndex: "notes", key: "notes" }
    ]



    const drawHeader = (_info = {}) => {


        const {

            is_bo = false,
            code_type = "",
            cancel_date = null,
            first_received = null,
            order = "",
            list_total = "",
            net_total = "",
            stage = "",
            sent_ts = null,
            bo_sta = "",
            special_orders = 0,
            units = "",
            sell_total = "",
            net_cost = "",
            profit_margin = "",
            vendor_order_min = "",
            supplier_info = {
                supplier: "",
                created: null,
                name: "",
                address_1: "",
                address_2: "",
                city: "",
                province: "",
                country: "",
                email: "",
                phone: "",
                fax: ""
            }
        } = _info;



        return (

            <>
                <div style={{ "padding": "0px 5px", "paddingBottom": "0px", "margin": "0px 0px" }}>
                    <div style={{ "float": "right", "textAlign": "right" }}>
                        <Typography.Paragraph style={{ "fontSize": "12px" }}>

                            <conditional.true value={(special_orders)}>
                                <span style={{ "fontSize": "12px", "display": "block" }}>Special orders:   <strong>{special_orders}</strong></span>
                            </conditional.true>

                            <conditional.true value={(units)}>
                                <span style={{ "fontSize": "12px", "display": "block" }}>Units:   <strong>{units}</strong></span>
                            </conditional.true>

                            <conditional.true value={(sell_total)}>
                                <span style={{ "fontSize": "12px", "display": "block" }}>Sell total:   <strong>{(sell_total) ? "$" + addCommas(sell_total) : ""}</strong></span>
                            </conditional.true>

                            <conditional.true value={(list_total)}>
                                <span style={{ "fontSize": "12px", "display": "block" }}>List Total:   <strong>{(list_total) ? "$" + addCommas(list_total): ""}</strong></span>
                            </conditional.true>

                            <conditional.true value={(net_cost)}>
                                <span style={{ "fontSize": "12px", "display": "block" }}>Net Cost:   <strong>{(net_cost) ? "$" + addCommas(net_cost) : ""}</strong></span>
                            </conditional.true>

                            <conditional.true value={(vendor_order_min)}>
                                <span style={{ "fontSize": "12px", "display": "block" }}>Vendor Order Min:   <strong>{vendor_order_min}</strong></span>
                            </conditional.true>

                            {/* 
                            <conditional.true value={(list_total)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>Created: </strong>{bmDate(supplier_info.created * 1000)}</span>
                            </conditional.true>
                            <conditional.true value={(first_received)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>1st Received: </strong>{bmDate(first_received * 1000)}</span>
                            </conditional.true>
                            <conditional.true value={(cancel_date)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>Cancel: </strong>{bmDate(cancel_date * 1000)}</span>
                            </conditional.true>
                            <conditional.true value={(list_total)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>List: </strong>${list_total}</span>
                            </conditional.true>
                            <conditional.true value={(net_total)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>Net: </strong>${net_total}</span>
                            </conditional.true> */}

                        </Typography.Paragraph>
                    </div>
                    <Typography.Paragraph style={{ "fontSize": "12px" }}>
                        <conditional.true value={(supplier_info.supplier)}>
                            <span style={{ "fontSize": "12px", "display": "block" }}>Vendor code: <strong>{supplier_info.supplier}</strong></span>
                        </conditional.true>
                        <conditional.true value={(stage)}>
                            <span style={{ "fontSize": "12px", "display": "block" }}>Status: <strong>{stage}</strong></span>
                        </conditional.true>

                        <conditional.true value={(sent_ts)}>
                            <span style={{ "fontSize": "12px", "display": "block" }}>Sent on: <strong>{bmDate(sent_ts * 1000)}</strong></span>
                        </conditional.true>

                        <conditional.true value={(cancel_date)}>
                            <span style={{ "fontSize": "12px", "display": "block" }}>Cancel date:  <strong>{bmDate(cancel_date * 1000)}</strong></span>
                        </conditional.true>

                        <conditional.true value={(bo_sta)}>
                            <span style={{ "fontSize": "12px", "display": "block" }}>B/O status:  <strong>{bo_sta}</strong></span>
                        </conditional.true>

                        <conditional.true value={(first_received)}>
                            <span style={{ "fontSize": "12px", "display": "block" }}>First received:  <strong>{bmDate(first_received * 1000)}</strong></span>
                        </conditional.true>




                        {/* <conditional.true value={(supplier_info.address_1 || supplier_info.address_2)}>
                            {supplier_info.address_1} {supplier_info.address_2} <br />
                        </conditional.true>
                        <conditional.true value={(supplier_info.city)}>
                            {supplier_info.city}, {supplier_info.province} {supplier_info.country} <br />
                        </conditional.true>
                        <conditional.true value={(supplier_info.phone)}>
                            <conditional.true value={(supplier_info.phone)}><strong>{supplier_info.phone}</strong></conditional.true>
                        </conditional.true>
                        <span style={{ "fontSize": "11px", "display": "block" }}><strong>B/O: </strong>{(is_bo) ? "Yes" : "No"}</span>
                        <span style={{ "fontSize": "11px", "display": "block" }}><strong>Send: </strong>{code_type.toUpperCase()}</span> */}
                    </Typography.Paragraph>
                  
                </div>  
                <div className="shim" /><div className="shim" /><div className="shim" />
            </>
        )
    }

    const drawChildTable = (_record) => {
        if (drawType === "title") {
            return <Title isbn={_record.isbn} />;
        }
        if (drawType === "invoice") {
            return <InvoiceActivity invoice_is_supplier={_record.invoice_is_supplier} invoice={_record.invoice_key} />
        }
        return (<></>);
    }

    const expandCell = (_data, _type) => {
        setDrawType(_type);
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            if (_type === drawType) {
                setExpanded([]);
            } else {
                setExpanded([_data.key]);
            }
        }
    }




    return (
        <div style={{ "padding": "20px", "margin": "0px" }}>

            <Typography.Title level={5}>PO# {purchase_order}</Typography.Title>
            <Divider style={{ "margin": "15px 0px" }} />

            {drawHeader(results.header_info)}
            <div className="bc" style={{ "border": "1px solid" }}>
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={results.total} />
                <Table
                    loading={loading}

                    size="small"
                    columns={columns}
                    className={"tableNoBorder"}
                    dataSource={results.rows}
                    pagination={false}
                    expandable
                    expandedRowKeys={expanded}
                    expandIconColumnIndex={-1}
                    onExpand={(e) => { return false; }}
                    expandIcon={() => { return (<></>) }}
                    expandedRowRender={((record) => drawChildTable(record))}

                />
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={results.total} />
            </div>
        </div>
    )
}