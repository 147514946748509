import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { apiCall } from "../utils/Api";

export default function FilterAutocomplete(props) {

    const inputRef = useRef();

    const {
        filters = [],
        setFilters,
        name = "",
        searchFilters = [],
        initialMessage = true,
        setInitialMessage = () => { },
        publisherMaxRows = 0,
        setPublisherMaxRows = () => { },
    } = props;
    const [initFilters, setInitFilters] = useState([]);
    const local = (name === "publisher_imprint") ? false : true;

    const searchLocal = (_search, ifilters) => {
        let index = ifilters.findIndex(item => item.name === name);
        if (index > -1) {
            let _f = ifilters.map(item => {
                return { ...item }
            });
            _f[index].rows = _f[index].rows.filter(item => item.name.toUpperCase().includes(_search.toUpperCase()))
            setFilters(_f);
        }
    }

    useEffect(() => {
        // Clear inputs when searchFilters change
        if(inputRef.current){
            inputRef.current.state.value = "";
        }
    }, [searchFilters])

    const onChange = (_search) => {
        // ensure init is set
        let ifilters = initFilters;
        if (filters.length > initFilters.length) {
            ifilters = filters.map(item => {
                return { ...item }
            });
            setInitFilters(ifilters);
        }
        if (!_search) {
            setFilters(ifilters);
        }
        if (local) {
            return searchLocal(_search, ifilters);
        }
        apiCall("browse/getAutocompleteFacets", { search_filters: JSON.stringify(searchFilters), filter_name: "publisher_imprint", search_term: _search }, (_status, _result) => {
            if (_status) {
                let index = ifilters.findIndex(item => item.name === name);
                if (index > -1) {
                    let _f = [...ifilters]
                    _f[index].rows = _result.rows;
                    if (_result.name === "publisher_imprint") {
                        setPublisherMaxRows(_result.max_rows)
                    }
                    setFilters(_f);
                }

            }
        })
    }


    return (
        <>
            <div className="shim" />
            <Input
                ref={inputRef}
                allowClear
                onChange={(e) => {
                    onChange(e.target.value);
                    if (initialMessage) {
                        setInitialMessage(false);
                    }
                }}

                onKeyDown={(e) => {
                    if (name === "publisher_imprint") {
                        if (e.key === "Enter") {
                            if (initialMessage) {
                                setInitialMessage(false);
                            }
                            onChange(e.target.value);
                        }
                    } else {
                        onChange(e.target.value)
                    }
                }}
                style={{ "fontSize": "12px" }}
                size="small"
                placeholder={name === "publisher_imprint" ? "Search..." : "Refine..."}
            />
        </>
    )
}