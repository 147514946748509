import React from "react";
import { Form, Typography, Divider, Input, Row, Col, Space, Checkbox, Radio } from "antd";
import WebstoreItem from "../WebstoreItem";
import conditional from "../../../utils/conditional";
export default function PaymentsSection(props) {

    const { ws } = props;

    return (
        <>
            <div id="payments" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Payments</Typography.Title>
                <Divider />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Pay at pick up */}
                        <div id="ws_accepts_payment_pickup"></div>
                        <WebstoreItem
                            name="ws_accepts_payment_pickup"
                            value={(ws["ws_accepts_payment_pickup"]) ? true : false}
                            heading="Enable pay at pick up"
                            description={<div>Allow customers to choose "I will pay when I pick up the order" on the "Pick up at store" cart checkout page. We highly recommend enabling this option, as it gives a convenience and competitive edge that larger online retailers cannot offer. In our experience, the vast majority of online customers choose this option when checking out, and 99% of these orders are picked up and paid for with no issue. It is very rare for a customer to place an order for pickup and not actually follow through.</div>}
                        />
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Contact for payment */}
                        <div id="ws_accepts_payment_contact"></div>
                        <WebstoreItem
                            name="ws_accepts_payment_contact"
                            value={(ws["ws_accepts_payment_contact"]) ? true : false}
                            heading="Enable contact for payment"
                            warning={true}
                            description={<div>Allow customers to choose "Contact me for payment" on the cart checkout page. This payment option does not require the customer to prepay at checkout. This option is available for both in-store pickup and shipped orders.</div>}
                        />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Paragraph type="secondary">If you would like to customise the text of this payment option here, please use this free-form box below.</Typography.Paragraph>
                                <Form.Item noStyle initialValue={(ws["ws_custom_contact_payment_text"]) ? ws["ws_custom_contact_payment_text"] : "Contact me for payment"} name="ws_custom_contact_payment_text"><Input style={{ "maxWidth": "500px" }}></Input></Form.Item>
                                <div className="shim"></div>
                                <Typography.Paragraph type="secondary">Default Value of "Contact me for payment". Limit of 40 characters.</Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]}>
                    <Col md={24} xl={12}>
                        {/* Pay at pick up */}
                        <div id="ws_accepts_paypal"></div>
                        <WebstoreItem
                            name="ws_accepts_paypal"
                            value={(ws["ws_accepts_paypal"]) ? true : false}
                            heading="Enable PayPal payments"
                        />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <conditional.true value={(ws["ws_paypal_email"])}>
                                    <Typography.Text><strong>PayPal email address </strong>
                                        <Typography.Paragraph type="secondary">Email address your business' PayPal account was set up with.</Typography.Paragraph>
                                        <Form.Item help={<small>If this email is incorrect, please contact Bookmanager.</small>} rules={[{ type: "email", message: "Must be a valid email." }]} initialValue={ws["ws_paypal_email"]} name="ws_paypal_email">
                                            <Input disabled size="small" style={{ "maxWidth": "300px" }}></Input>
                                        </Form.Item>
                                        <div className="shim" />
                                    </Typography.Text>
                                </conditional.true>
                                <Typography.Paragraph><strong>Paypal client id </strong>
                                    <div className="shim"></div>
                                    <Form.Item initialValue={ws["ws_paypal_client_id"]} name="ws_paypal_client_id" noStyle>
                                        <Input.TextArea maxLength={80} showCount size="small" style={{ "maxWidth": "500px" }}></Input.TextArea>
                                    </Form.Item>
                                </Typography.Paragraph>
                                <Typography.Paragraph><strong>Paypal secret </strong>
                                    <div className="shim"></div>
                                    <Form.Item initialValue={ws["ws_paypal_secret"]} name="ws_paypal_secret" noStyle>
                                        <Input.TextArea maxLength={80} showCount size="small" style={{ "maxWidth": "500px" }}></Input.TextArea>
                                    </Form.Item>
                                </Typography.Paragraph>
                                <Typography.Paragraph type="secondary">For instructions on where to find the Client ID and Secret, <a target={"_blank"} href="https://cdn1.bookmanager.com/i/tbm/www/New_PayPal_Setup.html">click here.</a></Typography.Paragraph>
                            </div>
                        </Space>
                    </Col>
                    <Col md={24} xl={12}>
                        {/* Enable in-store credit card processing */}
                        <div id="ws_credit_card_in_store"></div>
                        <WebstoreItem
                            name="ws_credit_card_in_store"
                            value={(ws["ws_credit_card_in_store"]) ? true : false}
                            heading="Enable in-store credit card processing"
                            notice="IMPORTANT: You must enable the option ‘Submitted orders will automatically generate a Held Sale in my Bookmanager system’ (in the Ordering section) before you can enable credit card transactions here."
                            description={<div>Enabling credit cards will allow your customers to provide credit card details for a transaction. Those details will be encrypted and passed to your Bookmanager software, where a non-recoverable password is required to decrypt the credit card information. It is then up to you to process the transaction manually at your store with the details provided. Credit card details will not be stored on Bookmanager servers or sent by email. Your local copy of Bookmanager is the only place credit cards are stored, with details securely encrypted.</div>}
                        />
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Text ><Form.Item valuePropName='checked' initialValue={(ws["ws_accepts_cc_shipping"]) ? true : false} name="ws_accepts_cc_shipping" noStyle><Checkbox disabled={(!props.autoHeldSale)} /></Form.Item>&nbsp; Enable Credit Card payments on orders to be <strong>Shipped</strong></Typography.Text><br />
                                <conditional.true value={((!props.autoHeldSale))}><Typography.Text type="danger">"Submitted orders will auto generate a Held Sale in Bookmanager" must be enabled.</Typography.Text><br /></conditional.true>
                                <div className="shim"></div><div className="shim"></div>
                                <Typography.Text ><Form.Item valuePropName='checked' initialValue={(ws["ws_accepts_cc_pickup"]) ? true : false} name="ws_accepts_cc_pickup" noStyle><Checkbox disabled={(!props.autoHeldSale)} /></Form.Item>&nbsp; Enable Credit Card payments on orders to be <strong>Picked Up</strong></Typography.Text><br />
                                <conditional.true value={((!props.autoHeldSale))}><Typography.Text type="danger">"Submitted orders will auto generate a Held Sale in Bookmanager" must be enabled.</Typography.Text><br /></conditional.true>
                                <div className="shim"></div><div className="shim"></div>
                                <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["ws_disallow_cc_debit"]) ? true : false} name={"ws_disallow_cc_debit"} noStyle><Checkbox /></Form.Item>&nbsp; Disallow debit credit cards <div className="shim" /><small>(Checking will disallow debit credit cards transactions for your customers.)</small></Typography.Paragraph>
                                <Divider style={{ "margin": "10px 0px" }} dashed />
                                <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["ws_accepts_cc"].visa) ? true : false} name={["ws_accepts_cc", "visa"]} noStyle><Checkbox /></Form.Item>&nbsp; VISA</Typography.Paragraph>
                                <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["ws_accepts_cc"].mastercard) ? true : false} name={["ws_accepts_cc", "mastercard"]} noStyle><Checkbox /></Form.Item>&nbsp; MasterCard</Typography.Paragraph>
                                <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["ws_accepts_cc"].amex) ? true : false} name={["ws_accepts_cc", "amex"]} noStyle><Checkbox /></Form.Item>&nbsp; American Express</Typography.Paragraph>
                                <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["ws_accepts_cc"].discover) ? true : false} name={["ws_accepts_cc", "discover"]} noStyle><Checkbox /></Form.Item>&nbsp; Discover</Typography.Paragraph>



                            </div>
                        </Space>
                    </Col>

                    <Divider dashed />


                    

                    {/* Disabled for now */}
                    <conditional.true value={false}>
                        <Col md={24} xl={12}>
                            <div style={{ "opacity": (!window.sitesettings.is_canadian) ? "1" : "0.2", "pointerEvents": (!window.sitesettings.is_canadian) ? "inherit" : "none" }}>
                                {/* Pay at pick up */}
                                <div id="ws_credit_card_clearent"></div>
                                <WebstoreItem
                                    name="ws_credit_card_clearent"
                                    value={(ws["ws_credit_card_clearent"]) ? true : false}
                                    heading="Enable Clearent payments"
                                    description={<>If you use Clearent in-store to process payments, you can also enable them here to facilitate payments on your consumer Webstore. Clearent integration will not send actual credit card numbers into your Bookmanager software, but instead pass along tokens that can only be used by your store. Tokens hugely reduce liability, and are useless to anyone but your store's specific merchant account with Clearent. A token-based system means staff will never see customer credit card details, giving both merchants and customers superior security and peace of mind. </>}
                                />
                                {/* Tokens can be authorized for a one-time use, or for multiple uses if the customer wishes to keep their credit card on file for future purchases. */}
                                <div className="shim" />
                                <Space>
                                    <div style={{ "width": "35px" }}></div>
                                    <div style={{ "width": "430px" }}>

                                        <Typography.Paragraph><strong>Public Key </strong>
                                            <div className="shim"></div>
                                            <Form.Item initialValue={ws["ws_clearent_public_key"]} name="ws_clearent_public_key" noStyle>
                                                <Input.TextArea placeholder="Public Key..." size="small" rows={6} style={{ "maxWidth": "500px" }}></Input.TextArea>
                                            </Form.Item>
                                        </Typography.Paragraph>

                                        <div className="shim"></div>
                                        {/* <strong>Capture preference</strong>
                                        <div className="shim" />
                                        <Form.Item help={<span className="c"><br />The <em>Capture payments when order is submitted</em> option will automatically and immediately capture payment upon checkout. You can void or refund a customer's card within the Bookmanager software if need be. Otherwise, customer cards will not be charged automatically at checkout. Instead, a payment token will be sent to the Bookmanager software, where the online order can be reviewed before the customer's token is processed manually by staff in-store.</span>} initialValue={ws["clearent_should_capture"]} name="clearent_should_capture" >
                                            <Radio.Group>
                                                <Radio value={true}>Capture payments when order is submitted</Radio>
                                                <Radio value={false}>Use payment tokens and capture payment in Bookmanager</Radio>
                                            </Radio.Group>
                                        </Form.Item> */}
                                        <div className="shim"></div> <div className="shim"></div>
                                        {/* <Typography.Paragraph type="secondary">For instructions on where to find the API Key and Merchant ID, <a target={"_blank"} href="https://clearent.com/getting-started/">click here.</a></Typography.Paragraph> */}
                                    </div>
                                </Space>
                            </div>

                            

                        </Col>
                    </conditional.true>

                    <Col md={24} xl={12}>
                        {/* Disable debit credit cards */}
                        {/* <div id="ws_disallow_cc_debit"></div>
                        <WebstoreItem
                            name="ws_disallow_cc_debit"
                            value={(ws["ws_disallow_cc_debit"]) ? true : false}
                            heading="Disallow debit credit cards"
                            description={<div>Enabling will disallow debit credit cards transactions for your customers. </div>}
                        /> */}
                    </Col>




                </Row>

            </div>



        </>
    )
}