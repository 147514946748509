import React, { useEffect, useState } from "react";
import { Col, Collapse, Divider, Row } from "antd";
import { PlusOutlined, MinusOutlined, EditOutlined } from '@ant-design/icons';
import Comments from "./Comments";
import Html from "../utils/Html";
import TitleField from "./title/TitleField";
import { ucfirst } from "../utils/Utils";
import Comment from "./Comment";
export default function SupplementaryInfo(props) {

    const { opened = [], isbn = "", data = {} } = props;

    const drawContent = (_key) => {

        switch (_key) {
            case "comments":


            return <Comments row isbn={data.isbn || isbn} />
               

            case "description":
                return (
                    <Row>
                        <Col flex={"70px"}><small>Description</small></Col>
                        <Col flex={"500px"}>
                            <TitleField isbn={data.isbn || isbn} field="description" />
                        </Col>
                    </Row>)


            case "author":
                return (<Row>
                    <Col flex={"70px"}><small>Author</small></Col>
                    <Col flex={"500px"}>
                        <TitleField isbn={data.isbn || isbn} field="bio" />
                    </Col>
                </Row>)


            case "reviews":
                return (<Row>
                    <Col flex={"70px"}><small>Reviews</small></Col>
                    <Col flex={"500px"}>
                        <TitleField isbn={data.isbn || isbn} field="reviews" />
                    </Col>
                </Row>)


            case "marketing":
                return (<Row>
                    <Col flex={"70px"}><small>Marketing</small></Col>
                    <Col flex={"500px"}>
                        <TitleField isbn={data.isbn || isbn} field="marketing" />
                    </Col>
                </Row >)


        }

    }



    const draw = () => {
        return opened.map((item, index) => {
            return drawContent(item);
        })
    }

    return (
        <>
            <div className="shim" /><div className="shim" />
            {draw()}
        </>
    )

}