import { DownOutlined } from '@ant-design/icons';
import { Alert, Button, Form, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";

export default function AddRegion(props) {

    const { show_message = true, country = "", province = "", regions = [], setRegions = () => {}, setUpdate = () => {} } = props;
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(country);
    const [selectedProvince, setSelectedProvince] = useState(province);
    const [errorMsg, setErrorMsg] = useState("");

    const [modalVisible, setModalVisible] = useState(false);

    const listCountries = () => {
        apiCall("country/getList", {}, (_status, _results) => {
            if (_status) {
                setSelectedProvince("");
                setCountries(_results);
            }
        }, "customer");
    }
    useEffect(listCountries, []);

    const listProvinces = (_country) => {
        apiCall("province/getList", { country: selectedCountry }, (_status, _results) => {
            if (_status) {
                setProvinces(_results);
            }
        }, "customer");
    }
    useEffect(listProvinces, [selectedCountry]);


    const createNewRegion = (_f) => {

        setErrorMsg("");
        let obj = {};
        obj.country = _f["country"];
        if (_f["province"]) {
            obj.region = _f["province"];
        }

        apiCall("settings/convertCountryRegion", obj, (_status, _results) => {

            if (_status) {
                // success
                setModalVisible(false);
                if(show_message){
                    message.success('Region added successfully.');
                }
               

                let obj = {};

                obj[_results.code] = {
                    "charge_type": "0",
                    "base_rate": "",
                    "region_code": _results.code,
                    "base_rate_add": "",
                    "base_rate_max": "",
                    "base_rate_threshold": "",
                    "name": (_results.region_name) ? _results.region_name : _results.country_name
                }

                let r = [...regions];
                r.push(obj[_results.code]);
                setRegions(r);
                setUpdate(!props.update);

            } else {
                setErrorMsg(_results.error)
            }

        })
    }

    return (
        <>
            <Modal destroyOnClose title="Add Region" centered footer={null} onCancel={() => setModalVisible(false)} visible={modalVisible}>
                <Form onFinish={(_f) => createNewRegion(_f)} layout="vertical">

                    <Form.Item initialValue={(selectedCountry) ? selectedCountry : null} name="country" label="Country" rules={[{ validateTrigger: "onBlur", validator: (_, value) => (countries.includes(selectedCountry)) ? Promise.resolve() : Promise.reject("no"), message: "Valid country is required.", required: true }]}>
                        <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' showSearch placeholder="Country" style={{ maxWidth: 400 }} onChange={(v) => setSelectedCountry(v)}>
                            {countries.map((item, index) => {
                                return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                            })}
                        </Select>
                    </Form.Item>

                    <conditional.true value={(selectedCountry && provinces.length > 0)}>
                        <Form.Item initialValue={(selectedProvince) ? selectedProvince : null} label="State / Province" name="province" rules={[{ validateTrigger: "onBlur", validator: (_, value) => (provinces.includes(selectedProvince) || selectedProvince === "--") ? Promise.resolve() : (provinces.length > 0) ? Promise.reject("no") : Promise.resolve(), message: "Valid state / province is required.", required: true }]}>
                            <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' showSearch placeholder="State / Province" onChange={(v) => setSelectedProvince(v)} style={{ maxWidth: 400 }}>
                            <Select.Option key={"--"} value={"--"}>All regions</Select.Option>
                                {provinces.map((item, index) => {
                                    return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                                })}
                            </Select>
                        </Form.Item>
                    </conditional.true>

                    <conditional.true value={(errorMsg)}>
                        <Alert showIcon={true} type="error" message={errorMsg} />
                    </conditional.true>
                    <br />
                    <Button htmlType="submit" type="primary">Add Region</Button>
                </Form>
            </Modal>

            <Button type="primary" onClick={() => setModalVisible(true)}>Add Region</Button>
        </>
    )
}