import { LoadingOutlined } from '@ant-design/icons';
import { message, Result, Spin } from "antd";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import { objectToPath } from "../utils/Utils";

export default function SwitchPage() {


  const { ses = "" } = useParams();
  const [, setSession] = useSession();
  const history = useHistory();

  const old_session = Cookies.get("session_store_id") || "";
  const old_store_id = window.store_id || ""; 

  const getSession = () => {
    apiCall("session/get", {}, (_status, _result) => {
      if (_status) {
        
        window.store_id = _result.store_id;
        apiCall("store/getSettings", {}, (_s, _r) => {
          window.sitesettings = _r;
          if (_result.logged_in) {
            setSession(_result);
            let _path = "/browse" + objectToPath(_result.filter_preset_defaults.status.selections);
            history.replace(_path)
          } else {
            history.replace("/")
          }
          //logOutOld(); 
        })
      }
    }, "store", true, ses);
  }

  const logOutOld = () =>{

    if(old_session === ses){
      let _path = "/";
      history.replace(_path)
      return; 
    }


    apiCall("account/logout", {}, (_status, _result) => {
      getSession(); 
    }, "store", true, old_session, old_store_id);

    Cookies.set("session_repsite_id", ses, { expires: 365 });
  }


  useEffect(logOutOld, []);


  

  const loadAnimation = <LoadingOutlined style={{ fontSize: 45 }} spin />

  return (
    <>
      <br /><br /><br /><br /><br />
      <Result
        status={"success"}
        icon={<Spin indicator={loadAnimation} />}
        title={"Switching..."}
        subTitle={"This will only take a moment..."}
      />

    </>
  )




}