import { CloseOutlined } from '@ant-design/icons';
import { Button, Space } from "antd";
import React, { useEffect, useState } from "react";

import conditional from "../tools/conditional";
import { makeKey } from "../utils/Utils";

export default function Renderer(props) {


    const [startY, setStartY] = useState(0);
    const { key, close = false } = props;
    const [showClose, setShowClose] = useState(close);
    const [component, setComponent] = useState();

    const [hash, setHash] = useState(makeKey());


    const getLoadPosition = () =>{
        setComponent(null);
        setStartY(window.scrollY);
    }

    useEffect(getLoadPosition, []);

    const drawComponent = (_component, _hide_close = false, _scroll = false) => {


        if (_hide_close) {
            setShowClose(false);
        }

        if (JSON.stringify(component) === JSON.stringify(_component)) {
            setComponent(null);


        } else {

           
            setComponent(_component);

            // if(_scroll){
            //     window.scrollTo({
            //         top: document.getElementById(hash).offsetTop + 212,
            //         left: 0,
            //         behavior: 'smooth'
            //     });
            // }   
        }
    }

    const drawClose = () => {

        if (!showClose) {
            return (<></>);
        }

        return (
            <conditional.true value={(component)}>
                <div className="closebar"><Button style={{ "float": "right" }} onClick={() => setComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} /><br clear="both" /></div>
            </conditional.true>
        )
    }

    const drawBreak = () => {
        return (
            <conditional.true value={(component)}>
                <div className="endbreak" />
            </conditional.true>
        )
    }

    const style = {
        "marginLeft": "-46px", "paddingLeft": "20px", "borderLeft": "26px solid rgb(0,0,0,0.3)"
    }
    const nostyle = {
    }


    const barClose = (e) =>{
        // stop prop
        e.stopPropagation();
        e.preventDefault();
        // window.scrollTo(0, startY);
        setComponent(null);
    }

    return (
        <div key={key}>


            {React.cloneElement(props.children, { drawComponent: drawComponent })}
            {drawBreak()}
            {drawClose()}
            <div onClick={(e) => {
                if (e.nativeEvent.offsetX < 0) {
                   barClose(e)
                }
            }} style={(component) ? style : nostyle}>

                <div id={hash}>{component}</div>
                {(component && <div id={hash} onClick={(e) => barClose(e)} className="shim" style={{ "backgroundColor": "rgb(0,0,0,0.3)", "height": "25px", "margin": "0px -20px", "borderBottom": "1px solid rgb(0,0,0,0.3)" }}>
                    <div style={{ "marginLeft": "-24px" }}>
                        <Button style={{ "float": "left" }} onClick={(e) => barClose(e)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                    </div>
                    <Button style={{ "float": "right" }} onClick={(e) => barClose(e)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                </div>)}
            </div>




        </div>
    );
}