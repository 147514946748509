import { LockOutlined } from '@ant-design/icons';
import { ArrowUpOutlined } from '@ant-design/icons';
import { Alert, BackTop, Breadcrumb, Button, Layout, Modal, Result } from 'antd';
/*

Default Layout - Wraps sections with nav, footer etc

*/

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Nav from "../components/Nav";
import { navigation } from "../data.js";
import { useSession } from "../utils/Session";
import { ucfirst } from "../utils/Utils";

export default function Default(props) {

    const { drawDarkMode = () => { } } = props;

    const loc = useLocation();
    const history = useHistory();
    const [session, setSession] = useSession();


    const checkResolution = () => {

        if (window.innerWidth < 500) {

            Modal.confirm({
                title: "Set scale",
                onOk: () => {
                    let scale = window.innerWidth / 1300;
                    document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=' + 0.5 + ', maximum-scale=1.0, user-scalable=0');
                }
            })
        }

    }
    //useEffect(checkResolution, [])



    const checkPermission = () => {
        let has_permission = true;
        Object.keys(navigation).forEach(menu => {
            let { subnav = [] } = navigation[menu];
            subnav.forEach(item => {
                if (item.hasOwnProperty("permission")) {
                    if (loc.pathname.includes(item.slug)) {

                        if (session.hasOwnProperty(item.permission)) {
                            if (!session[item.permission]) has_permission = false;
                        }

                        if (session.permissions.hasOwnProperty(item.permission)) {
                            if (!session.permissions[item.permission]) has_permission = false;
                        }

                    }
                }
            })
        })
        return has_permission;
    }

    const drawPath = (_p) => {
        if (_p.includes("/filter")) {
            // cull filters from breadcrumbs
            _p = _p.split("/filter")[0];
        }
        let items = _p.split("/");
        let title = "";
        items.forEach((element, index) => {
            if (index === 1) {
                if(element === "my-company-catalogues"){
                    title += title += "My Company's Catalogues > ";
                }else{
                    title += ucfirst(element) + " > ";
                }
            } else {
                if(element === "my-company-catalogues"){
                    title += "My Company's Catalogues";
                }else{
                    title += ucfirst(element) + " ";
                }
                
            }
        });
        document.title = title;
        return items.map((itm, ind) => {
            let breadcrumb_item = itm;
            if(itm === "my-company-catalogues"){
                breadcrumb_item = "My Company's Catalogues"
            }
            if (itm === "") {
                return <Breadcrumb.Item key={ind}></Breadcrumb.Item>
            } else {
                return <Breadcrumb.Item key={ind}>{ucfirst(breadcrumb_item)}</Breadcrumb.Item>
            }
        })
    }


    const drawNoPermission = () => {

        return (
            <>
                <Result

                    icon={<LockOutlined />}
                    title="Sorry, you are not authorized to access this page."
                    extra={
                        <Button onClick={() => history.push("/")} type="primary" key="console">
                            Home
                        </Button>
                    }
                />
            </>
        )
    }






    return (
        <>
         {drawDarkMode()}
            <BackTop style={{"bottom" : "88px"}}>
                <Button type="primary" ghost shape="circle" icon={<ArrowUpOutlined />} size="large" />
            </BackTop>

            <Alert
                className="switchbanner"
                message={<div style={{ "textAlign": "center" }}>
                    <small>Having trouble? <a onClick={() => window.open(window.sitesettings.admin_url)}>Click here</a> to return to the legacy website.</small>
                </div>}
                banner
                style={{ "borderLeft": "0px", "borderRight": "0px", "position": "fixed", "zIndex": "1000", "height": "30px", "width": "100%" }}
                showIcon={false}
                type="success"
            />

            <div className="navWrapper" style={{}}>
                <div style={{ "width": "1240px", "margin": "0 auto" }}>

                    <Nav dark={props.dark} />
                </div>
            </div>
            <div style={{ "height": "85px", "display": "block" }} />
            <Layout>
                <Layout.Content>
                    <div style={{ "height": "calc(100vh - 85px)", "minWidth": "1310px", "maxWidth": "1310px", "margin": "0 auto", "padding": "0 35px" }}>
                        <div className="shim" /><div className="shim" />
                        <Breadcrumb style={{ "fontSize": "12px", "fontWeight": "500", "padding": "5px 15px" }}>
                            {drawPath(loc.pathname)}
                        </Breadcrumb>
                        <div className="shim" /><div className="shim" />
                        <div className={(props.noborder) ? "" : "layoutWrapper"} style={{ "marginBottom": "20px" }}>

                            {(checkPermission()) ? props.children : drawNoPermission()}

                        </div>
                        <div style={{ "fontSize": "12px", "fontWeight": "500", "padding": "5px 15px" }}></div>
                    </div>
                </Layout.Content>
            </Layout>

        </>
    );

}