
import React, { useState } from "react";
import { message, Button, Modal, Input, Typography, Divider, Space } from "antd";
import { apiCall, apiDownload } from "../utils/Api";
import { FileExcelOutlined } from '@ant-design/icons';
import { addCommas, pluralize } from "../utils/Utils";
export default function ExportBrowseExcel(props) {

    const { searchFilters = {}, row_count = 0 } = props;
    const [visible, setVisible] = useState(false);

    
    const getExcel = (_limit) => {

        let _filters = { ...searchFilters }
        if (_filters["t"]) {
            _filters["t"] = _filters["t"].replace("%25", "%");
            _filters["t"] = _filters["t"].replace("%2f", "/");
        }

        _filters["l"] = _limit;

        apiCall("browse/getExcel", _filters, (_status, _result) => {
            if(_status){
                window.open("/excel/" + _result.progress_report_id, "_blank")
            } else {
                message.error(_result.error)
            }
        })
    }

    const close = () => {
        setVisible(false);
    }

    const drawModal = () => {
        return (
            <>
                <Modal destroyOnClose width={320} footer={null} onCancel={() => close()} visible={visible}>
                    <Typography.Title level={5}>Export Sales and Stock to Excel </Typography.Title>
                    <Divider style={{ "marginTop": "15px", "marginBottom": "10px" }} />
                    <div>Number of titles to export:</div>
                    <div className="shim" /><div className="shim" />
                    <Button type="primary" onClick={() => getExcel(500)} block>500</Button>
                    <div className="shim" />
                    <Button disabled={row_count<500} type="primary" onClick={() => getExcel(1000)} block>1000</Button>
                    <div className="shim" />
                    <Button disabled={row_count<1000} type="primary" onClick={() => getExcel(2000)} block>2000</Button>
                    <div className="shim" />
                    <Button disabled={row_count<2000} type="primary" onClick={() => getExcel(4000)} block>4000</Button>
                    <div className="shim" />
                    <Button disabled={row_count<4000} type="primary" onClick={() => getExcel(5000)} block>5000</Button>
                    <div className="shim" />
                    <Button disabled={row_count<5000} type="primary" onClick={() => getExcel(10000)} block>10000</Button>
                    <Divider style={{ "marginTop": "15px", "marginBottom": "10px" }} />
                    <div className="float-flex">
                        <div></div>
                        <div><Button onCancel={() => close()}>Cancel</Button></div>
                    </div>
                </Modal>
            </>
        )
    }

    return (
        <>
            {drawModal()}
            <Button size="small" onClick={() => setVisible(true)} type="primary"><small><FileExcelOutlined />&nbsp;XLS</small></Button>
        </>
    )
}